import { Injectable } from '@angular/core';

const HOURS_IN_SECONDS = 3600;
const SECOND_IN_MILLISECONDS = 1000;
/**
 * Date/Time Service for use with CalendarDatePicker & RadialTimePicker.
 */
@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor() {}

  /**
   * Split a timestamp (in seconds) into a Date value compatible with
   * CalendarDatePicker and a Formatted Time value compatible with
   * RadialTimePicker.
   */
  splitDateAndTime(
    timestampInSeconds: number,
    isUtcDate = true,
    timezone?: number
  ): [Date, string] {
    const timestamp = isUtcDate
      ? timestampInSeconds
      : timestampInSeconds + timezone * HOURS_IN_SECONDS;
    const date = new Date(timestamp * SECOND_IN_MILLISECONDS);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

    return [date, formattedTime];
  }

  /**
   * Convert a date/formattedTime like the ones from splitDateAndTime
   * back into a timestamp (in seconds).
   */
  getTimestampInSecondsFromDateAndTime(date: Date, time: string): number {
    if (!date || !time) {
      return;
    }
    const [hours, minutes, period] = time.split(/:|\s/);
    const splitHours = (parseInt(hours, 10) % 12) + (period === 'PM' ? 12 : 0);
    const splitMinutes = parseInt(minutes, 10);

    date.setUTCHours(splitHours);
    date.setUTCMinutes(splitMinutes);
    date.setUTCSeconds(0);

    return Math.round(date.getTime() / 1000);
  }
}
