import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { QuicksightDashboardComponent } from '../../quicksight/quicksight-dashboard.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-terminal-scorecard-wash',
  templateUrl: './terminal-scorecard-wash.component.html',
  styleUrls: ['../../quicksight/quicksight-dashboard.component.scss'],
})
export class TerminalScorecardWashComponent
  extends QuicksightDashboardComponent
  implements OnInit
{
  constructor(
    private reportsService: ReportsService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.reportsService
      .getDashboardById(environment.reports.reportIds.terminalScorecardWash)
      .then((quicksightDashboardData) => {
        this.loadDashboardContainer(quicksightDashboardData, [
          {
            Name: 'terminalnumberparameter',
            Values: [this.authService.user.currentTerminal.number],
          },
        ]);
      });
  }
}
