// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manual-start-stop-reason-container {
  max-height: 80vh;
}
@media (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
  .manual-start-stop-reason-container {
    width: 40vw;
  }
}
.manual-start-stop-reason-container .popup-title {
  border-bottom: solid 1px #c9c9c9;
  font-size: 1.9rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
}
.manual-start-stop-reason-container form > * {
  margin-top: 16px;
}
.manual-start-stop-reason-container form .comments {
  height: 150px;
}
.manual-start-stop-reason-container form .actions {
  display: flex;
  background-color: black;
  border-radius: 12px;
  padding: 8px;
  justify-content: flex-end;
}
.manual-start-stop-reason-container form .actions app-quala-button {
  width: initial;
}
.manual-start-stop-reason-container form .actions app-quala-button + app-quala-button {
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/schedule/start-stop-confirmation/manual-start-stop-reason/manual-start-stop-reason.component.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AAFF;ACUI;EDTJ;IAII,WAAA;EADF;AACF;AAGE;EACE,gCAAA;EACA,iBAAA;EACA,gBAAA;EACA,qBAAA;EACA,sBAAA;EACA,WAAA;AADJ;AAKI;EACE,gBAAA;AAHN;AAMI;EACE,aAAA;AAJN;AAOI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;AALN;AAOM;EACE,cAAA;AALR;AAOQ;EACE,gBAAA;AALV","sourcesContent":["@import '../../../../../mixins.scss';\n@import '../../../../../variables.scss';\n\n.manual-start-stop-reason-container {\n  max-height: 80vh;\n\n  @include breakpoint(desktop) {\n    width: 40vw;\n  }\n\n  .popup-title {\n    border-bottom: solid 1px #c9c9c9;\n    font-size: 1.9rem;\n    font-weight: 900;\n    margin-bottom: 0.5rem;\n    padding-bottom: 0.5rem;\n    width: 100%;\n  }\n\n  form {\n    > * {\n      margin-top: 16px;\n    }\n\n    .comments {\n      height: 150px;\n    }\n\n    .actions {\n      display: flex;\n      background-color: black;\n      border-radius: 12px;\n      padding: 8px;\n      justify-content: flex-end;\n\n      app-quala-button {\n        width: initial;\n\n        + app-quala-button {\n          margin-left: 8px;\n        }\n      }\n    }\n  }\n}\n","@mixin breakpoint($point) {\n  @if $point == mobile {\n    @media (max-width: 599.98px) and (orientation: portrait),\n      (max-width: 959.98px) and (orientation: landscape) {\n      @content;\n    }\n  } @else if $point == tablet {\n    @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait),\n      (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {\n      @content;\n    }\n  } @else if $point == desktop {\n    @media (min-width: 840px) and (orientation: portrait),\n      (min-width: 1280px) and (orientation: landscape) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
