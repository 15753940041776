import { Component, OnDestroy, OnInit, AfterViewChecked } from '@angular/core';
import { ScheduleService } from './schedule.service';
import { WashCreateComponent } from '../wash-list/wash-create/wash-create.component';
import { WashListService } from '../wash-list/wash-list.service';
import { PanelMode, columnWidth } from '../schedule/schedule.model';
import { LocalStorageService } from 'src/app/core/localstorage.service';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  fromEvent,
  map,
  startWith,
} from 'rxjs';
import { GtmUtilsService } from 'src/app/shared/gtm-utils.service';
import { FunctionRegistryService } from '../../shared/function-registry/function-registry.service';
import { SideMenuToggle } from '../dashboard.model';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit, OnDestroy {
  scheduleService: ScheduleService;
  defaultColumnWidth: number = columnWidth;
  fallbackBayListLength = 6;
  panelMode: PanelMode = undefined;
  togglePanelMode: boolean = false;
  showPanelMode: boolean = false;
  resizeSubscription: Subscription;
  width: number = 0;
  panelModeIsInitialized = false;
  public width$: Observable<number> = fromEvent(window, 'resize').pipe(
    map(() => window.innerWidth),
    startWith(window.innerWidth)
  );

  constructor(
    scheduleService: ScheduleService,
    private washListService: WashListService,
    private gtmUtilsService: GtmUtilsService,
    private localStorageService: LocalStorageService,
    private functionRegistryService: FunctionRegistryService
  ) {
    this.scheduleService = scheduleService;
  }

  getPanelModeClass(panelMode: PanelMode = null) {
    const bayQuantity = this.scheduleService.dataStore.bays.length;
    const is4K = this.width >= 3800;
    const panelModeLimits = [
      {
        mode: PanelMode.SMALL,
        bayColumnWidthAlternative: () => {
          if (panelMode === PanelMode.SMALL || (bayQuantity >= 9 && !is4K)) {
            return 160;
          }
        },
      },
      {
        mode: PanelMode.MEDIUM,
        bayColumnWidthAlternative: () => {
          if (
            panelMode === PanelMode.MEDIUM ||
            (6 <= bayQuantity && bayQuantity <= 8 && !is4K)
          ) {
            return 192;
          }
        },
      },
      {
        mode: PanelMode.LARGE,
        bayColumnWidthAlternative: () => {
          if (panelMode === PanelMode.LARGE || (bayQuantity < 6 && !is4K)) {
            return 236;
          }
        },
      },
      {
        mode: PanelMode.EXTRA_LARGE,
        bayColumnWidthAlternative: () => {
          if (
            panelMode === PanelMode.EXTRA_LARGE ||
            (bayQuantity >= 9 && is4K)
          ) {
            return 270;
          }
        },
      },
      {
        mode: PanelMode.TWO_X_LARGE,
        bayColumnWidthAlternative: () => {
          if (
            panelMode === PanelMode.TWO_X_LARGE ||
            (6 <= bayQuantity && bayQuantity <= 8 && is4K)
          ) {
            return 300;
          }
        },
      },
      {
        mode: PanelMode.THREE_X_LARGE,
        bayColumnWidthAlternative: () => {
          if (
            panelMode === PanelMode.THREE_X_LARGE ||
            (bayQuantity < 6 && is4K)
          ) {
            return 320;
          }
        },
      },
    ];
    let widthForNumberOfBays = null;
    for (const panelModeLimit of panelModeLimits) {
      widthForNumberOfBays = panelModeLimit.bayColumnWidthAlternative();

      if (panelMode === panelModeLimit.mode) {
        return {
          mode: panelModeLimit.mode,
          bayColumnWidthAlternative: widthForNumberOfBays,
        };
      } else if (!panelMode && widthForNumberOfBays) {
        return {
          mode: panelModeLimit.mode,
          bayColumnWidthAlternative: widthForNumberOfBays,
        };
      }
    }
  }

  setPanelMode() {
    this.togglePanelMode = !this.togglePanelMode;
    this.updatePanelModeState();
    this.togglePanelMode
      ? this.localStorageService.setPanelMode(this.panelMode)
      : this.localStorageService.setPanelMode('');
    this.panelModeIsInitialized = true;
    this.toggleSideMenu();
  }

  createNewRequest() {
    this.washListService.openCreateForm(WashCreateComponent);
  }

  updatePanelModeState(panelMode: PanelMode = null) {
    const { mode, bayColumnWidthAlternative } =
      this.getPanelModeClass(panelMode);
    this.panelMode = this.togglePanelMode ? mode : undefined;
    this.defaultColumnWidth =
      this.togglePanelMode && bayColumnWidthAlternative
        ? bayColumnWidthAlternative
        : columnWidth;
  }

  toggleSideMenu() {
    const toggleFunction = this.functionRegistryService.getFunction(
      SideMenuToggle.SIDE_MENU
    );

    const stateOfMenu = this.functionRegistryService.getFunction(
      SideMenuToggle.SIDE_MENU_IS_VISIBLE
    )();

    if (stateOfMenu === this.togglePanelMode) {
      toggleFunction();
    }
  }

  ngOnInit() {
    this.scheduleService.loadInitialData();
    this.resizeSubscription = this.width$.subscribe((newWidth) => {
      this.width = newWidth;
    });
    this.gtmUtilsService.getGtmConfig('showPanelMode').then((result) => {
      if (result) {
        this.showPanelMode = result;

        const localStoragePanelMode = this.localStorageService.getPanelMode();
        if (
          !this.panelModeIsInitialized &&
          localStoragePanelMode &&
          this.showPanelMode
        ) {
          this.togglePanelMode = true;
          this.updatePanelModeState(localStoragePanelMode as PanelMode);
          this.functionRegistryService.getFunction(SideMenuToggle.SIDE_MENU)();
          this.panelModeIsInitialized = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }
}
