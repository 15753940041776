import { Component, Inject, Optional } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-operators-dialog',
  templateUrl: './operators-dialog.component.html',
  styleUrls: ['./operators-dialog.component.scss'],
})
export class OperatorsDialogComponent {
  private minimumOperatorsQuantity = 6;
  showRecentOperatorsView: boolean;
  fullOperatorsList = [];
  unrecentOperators = [];
  recentOperators = [];
  validationMessage: string;
  displayValidationMessage: boolean;

  operatorsForm: UntypedFormGroup;
  recentOperatorId: UntypedFormControl;
  operatorId: UntypedFormControl;

  constructor(
    public dialogRef: MatDialogRef<OperatorsDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fullOperatorsList = this.data.operators;
    this.recentOperators = this.data.recentOperators;
    this.unrecentOperators = this.fullOperatorsList.filter(
      (operator) =>
        !this.recentOperators.find(
          (recentOperator) => recentOperator.userId === operator.userId
        )
    );
    this.createFormControls();
    this.createFormGroup();
    this.showRecentOperatorsView =
      this.recentOperators.length > 0 &&
      this.fullOperatorsList.length > this.minimumOperatorsQuantity;
  }

  createFormControls() {
    this.operatorId =
      this.recentOperators.length === 0
        ? new UntypedFormControl('', Validators.required)
        : new UntypedFormControl();
    this.recentOperatorId = new UntypedFormControl();
  }

  createFormGroup() {
    this.operatorsForm = new UntypedFormGroup({
      operatorId: this.operatorId,
      recentOperatorId: this.recentOperatorId,
    });
  }

  close() {
    this.dialogRef.close();
  }

  onRecentOperatorsChanges(): void {
    this.operatorId.setValue(undefined);
    this.operatorsForm.get('operatorId')?.reset();
    const dropdownElement = <HTMLSelectElement>(
      document.getElementById('unrecentOperator')
    );
    if (dropdownElement) {
      dropdownElement.value = '';
    }
  }

  onUnrecentOperatorsChanges(): void {
    this.recentOperatorId.setValue(undefined);
    this.operatorsForm.get('recentOperatorId')?.reset();
    const radioButtons = document.getElementsByName('recentOperatorId');
    radioButtons.forEach((radio: any) => {
      radio.checked = false;
    });
  }

  getFormValidationErrors() {
    const invalidFields = [];
    Object.keys(this.operatorsForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.operatorsForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          invalidFields.push('error');
        });
      }
    });

    const operatorId = this.operatorsForm.get('operatorId');
    const recentOperatorId = this.operatorsForm.get('recentOperatorId');

    if (!recentOperatorId.value && !operatorId.value) {
      invalidFields.push('error');
    }

    return invalidFields;
  }

  start() {
    this.displayValidationMessage = false;

    const invalidFields = this.getFormValidationErrors();

    if (invalidFields.length > 0) {
      this.validationMessage = 'Please, select an operator';
      this.displayValidationMessage = true;
      return;
    }

    this.dialogRef.close(this.recentOperatorId.value || this.operatorId.value);
  }
}
