import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
})
export class LoadingDialogComponent {
  @Input() text: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.text = data.text;
  }
}
