import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak
      .init({
        config: {
          url: environment.keycloak.issuer,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId,
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html',
        },
        loadUserProfileAtStartUp: false,
        enableBearerInterceptor: false,
      })
      .then(() => {
        keycloak.keycloakEvents$.subscribe({
          async next(event) {
            if (event.type == KeycloakEventType.OnTokenExpired) {
              keycloak.updateToken(-1);
            } else if (
              event.type == KeycloakEventType.OnAuthRefreshError ||
              event.type == KeycloakEventType.OnAuthLogout
            ) {
              window.location.assign('/');
            }
          },
        });
      });
}
