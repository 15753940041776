// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  text-align: center;
}

p {
  margin-top: 19px;
  margin-bottom: 0;
  line-height: 1.1;
}

.spinner {
  margin: auto;
  width: 170px;
  height: 170px;
  border: 5px solid #81be41;
  border-top-color: #198754;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/loading-dialog/loading-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kCAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF","sourcesContent":["* {\n  box-sizing: border-box;\n  text-align: center;\n}\n\np {\n  margin-top: 19px;\n  margin-bottom: 0;\n  line-height: 1.1;\n}\n\n.spinner {\n  margin: auto;\n  width: 170px;\n  height: 170px;\n  border: 5px solid #81be41;\n  border-top-color: #198754;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
