import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FunctionRegistryService {
  private functions: Function[] = [];

  setFunction(key: any, fn: Function) {
    this.functions[key] = fn;
  }

  getFunction(key: any): Function | null {
    return this.functions[key];
  }
}

// The FunctionRegistryService is a service designed to act as a centralized repository for
// storing and retrieving functions. It allows functions to be registered with a unique
// key and accessed later from different parts of the application without creating direct
// dependencies. This makes it easier to manage and invoke functions dynamically based on
// the current context, improving modularity and flexibility in the codebase.

// The SideMenuToggleDirective uses FunctionRegistryService to obtain the functions responsible
// for toggling the side menu and checking its visibility state. By retrieving these functions
// dynamically through the service, the directive avoids direct dependencies on specific
// components or services. This decoupling allows for greater reusability of the directive,
// as the actual implementation details of the functions can be changed or replaced without
// modifying the directive itself.
