// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-field {
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 8px;
  padding-right: 10px;
}

.label {
  font-size: 8px;
}

.value {
  font-size: 14px;
  font-weight: bold;
}

.is-red {
  color: #d60600;
}

.is-orange {
  color: #d65600;
}

:host ::ng-deep .highlighted-error {
  color: orange;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/display-field/display-field.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,6BAAA;EACA,YAAA;EACA,mBAAA;AADF;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,eAAA;EACA,iBAAA;AADF;;AAIA;EACE,cCKI;ADNN;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,aAAA;AADF","sourcesContent":["@import '../../../variables.scss';\n\n.display-field {\n  display: grid;\n  grid-template-rows: auto auto;\n  row-gap: 8px;\n  padding-right: 10px;\n}\n\n.label {\n  font-size: 8px;\n}\n\n.value {\n  font-size: 14px;\n  font-weight: bold;\n}\n\n.is-red {\n  color: $red;\n}\n\n.is-orange {\n  color: $deep-orange;\n}\n\n:host ::ng-deep .highlighted-error {\n  color: orange;\n}\n","// Brand Identity Colors\n$brand: (\n  'blue': #05347a,\n  'dark-grey': #53565a,\n  'black': #000000,\n  'white': #ffffff,\n  'green': #008751,\n  'light-green': #8cc63e,\n  'light-blue': #1b85ea,\n  'light-grey': #7e8283,\n  'light-grey-2': #e6e6e6,\n  'light-grey-3': #d4d4d4,\n  'light-grey-7': #a1a1a1,\n  'light-grey-9': #7d7d7d,\n);\n\n// Application Colors\n$jungle-green: #006937;\n$deep-green: #004725;\n$forest-green: #26a532;\n$light-green: #0047256e;\n$very-light-pink: #cdcdcd;\n$brown-grey: #929292;\n$deep-orange: #d65600;\n$red: #d60600;\n$blue: #3f71d3;\n$charcoal: #464646;\n$magnolia: #f5f4f7;\n$white: #fff;\n\n// Card\n$default-slot-width: 192px;\n$panel-mode-small-column-width: 160px;\n$panel-mode-medium-column-width: 192px;\n$panel-mode-large-column-width: 236px;\n$panel-mode-extra-large-column-width: 270px;\n$panel-mode-2-extra-large-column-width: 300px;\n$panel-mode-3-extra-large-column-width: 320px;\n\n@function brand-color($color) {\n  @return map-get($brand, $color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
