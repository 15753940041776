import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nineninenineplus',
})
export class NineNineNinePlusPipe implements PipeTransform {
  transform(value: any): any {
    return value > 1000 ? '999+' : value;
  }
}
