import { Component, Input, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-calendar-date-picker',
  templateUrl: './calendar-date-picker.component.html',
  styleUrls: ['./calendar-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarDatePickerComponent),
      multi: true,
    },
  ],
})
export class CalendarDatePickerComponent implements ControlValueAccessor {
  @Input() placeholder = 'Choose a date.';
  @Input() showEraser: boolean = false;
  @Input() validators: any[];

  selectedDate: Date;

  private onChangeCallback: (date: Date) => any;

  private onTouchedCallback: () => any;

  disabled: boolean;

  constructor() {
    this.disabled = false;
  }

  get isRequired(): boolean {
    return this.validators && this.validators.includes(Validators.required);
  }

  writeValue(date: Date): void {
    this.selectedDate = date;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSelectDate(date: Date) {
    this.writeValue(date);
    this.onChangeCallback(date);
    this.onTouchedCallback();
  }

  cleanInput(): void {
    this.onSelectDate(null);
  }
}
