import { Injectable } from '@angular/core';
import { Operator } from 'src/app/dashboard/schedule/schedule.model';
import { startWith, switchMap, map } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';

interface ConfinedSpaceEntryComponentDataInterface {
  confinedEntry: FormControl<string>;
  confinedEntryOperatorName: FormControl<string>;
  confinedEntryType: FormControl<string>;
  isDisabled: boolean;
  uploadedFiles: Array<any>;
  lastSelectedOperatorId: string | null;
  data: {
    currentCseData: {
      confinedEntry: boolean | null;
      confinedEntryType: string;
      workPerformedBy: {
        id?: string;
        confinedEntryOperatorName?: string;
      };
      files?: string | Array<any>;
    };
    operators: Array<any>;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfinedSpaceEntryDataUtils {
  constructor() {}

  static filterAndSortOperators(
    operators: Array<Operator>,
    filterValue: string
  ) {
    const filterValueLowerCase = filterValue.toLowerCase();
    return operators
      .filter((operator) =>
        operator.userName.toLowerCase().includes(filterValueLowerCase)
      )
      .sort((operatorA, operatorB) =>
        operatorA.userName.localeCompare(operatorB.userName, undefined, {
          sensitivity: 'base',
        })
      );
  }

  static handleOperatorsInputList(operatorShowerComponent) {
    operatorShowerComponent.operators$ =
      operatorShowerComponent.confinedEntryOperatorName.valueChanges.pipe(
        startWith(''),
        switchMap((value: string) =>
          operatorShowerComponent.data.operators.pipe(
            map((operators: Operator[]) => {
              return ConfinedSpaceEntryDataUtils.filterAndSortOperators(
                operators,
                value
              );
            })
          )
        )
      );
  }

  static handleConfinedEntryExecutedFormFields(
    component: ConfinedSpaceEntryComponentDataInterface
  ) {
    component.confinedEntryOperatorName.enable();
    component.confinedEntryType.enable();
    component.isDisabled = false;
    component.confinedEntryOperatorName.setValidators([Validators.required]);
    component.confinedEntryOperatorName.updateValueAndValidity();
    component.confinedEntryType.setValidators([Validators.required]);
    component.confinedEntryType.updateValueAndValidity();
  }

  static handleConfinedEntryNotExecutedFormFields(
    component: ConfinedSpaceEntryComponentDataInterface
  ) {
    component.confinedEntryOperatorName.disable();
    component.confinedEntryType.disable();
    component.isDisabled = true;
    component.uploadedFiles = [];
    component.confinedEntryOperatorName.setValue('');
    component.confinedEntryType.setValue('');
    component.confinedEntryOperatorName.clearValidators();
    component.confinedEntryOperatorName.updateValueAndValidity();
    component.confinedEntryType.clearValidators();
    component.confinedEntryType.updateValueAndValidity();
  }

  static handleConfinedEntryFormFields(
    component: ConfinedSpaceEntryComponentDataInterface
  ) {
    component.confinedEntry.valueChanges
      .pipe(startWith(''))
      .subscribe((value) => {
        const wasConfinedEntryExecuted = value === 'yes';

        if (wasConfinedEntryExecuted) {
          ConfinedSpaceEntryDataUtils.handleConfinedEntryExecutedFormFields(
            component
          );
        } else {
          ConfinedSpaceEntryDataUtils.handleConfinedEntryNotExecutedFormFields(
            component
          );
        }
      });
  }

  static getOperatorOfForSelectedWorkPerformedById(component) {
    return component.operators.filter(
      (operator) =>
        operator.userId === component.data.currentCseData.workPerformedBy.id
    )[0].userName;
  }

  static handleConfinedEntryInitialWorkPerformedBy(
    component: ConfinedSpaceEntryComponentDataInterface
  ) {
    if (component.data.currentCseData.workPerformedBy.id) {
      component.lastSelectedOperatorId =
        component.data.currentCseData.workPerformedBy.id;
      component.confinedEntryOperatorName.setValue(
        ConfinedSpaceEntryDataUtils.getOperatorOfForSelectedWorkPerformedById(
          component
        )
      );
    } else {
      component.lastSelectedOperatorId = null;
      component.confinedEntryOperatorName.setValue(
        component.data.currentCseData.workPerformedBy.confinedEntryOperatorName
      );
    }
  }

  static handleConfinedEntryInitialFiles(component) {
    if (component.data.currentCseData.files) {
      if (typeof component.data.currentCseData.files === 'string') {
        component.uploadedFiles = JSON.parse(
          component.data.currentCseData.files
        );
      } else {
        component.uploadedFiles = JSON.parse(
          JSON.stringify(component.data.currentCseData.files)
        );
      }
    }
  }

  static handledConfinedEntryInitialData(
    component: ConfinedSpaceEntryComponentDataInterface
  ) {
    if (component.data.currentCseData.confinedEntry) {
      component.confinedEntry.setValue('yes');
      component.confinedEntryType.setValue(
        component.data.currentCseData.confinedEntryType
      );

      ConfinedSpaceEntryDataUtils.handleConfinedEntryInitialWorkPerformedBy(
        component
      );
      ConfinedSpaceEntryDataUtils.handleConfinedEntryInitialFiles(component);
    }

    if (component.data.currentCseData.confinedEntry === false) {
      component.confinedEntry.setValue('no');
    }
  }

  static updateOperatorName(
    component: ConfinedSpaceEntryComponentDataInterface,
    event: MatOptionSelectionChange,
    operatorName: string,
    operatorId: string
  ) {
    if (event.isUserInput) {
      component.confinedEntryOperatorName.setValue(operatorName);
      component.lastSelectedOperatorId = operatorId;
    }
  }

  static createCseFormControls(
    component: ConfinedSpaceEntryComponentDataInterface
  ) {
    component.confinedEntry = new FormControl('', Validators.required);
    component.confinedEntryOperatorName = new FormControl('');
    component.confinedEntryType = new FormControl('');

    return {
      confinedEntry: component.confinedEntry,
      confinedEntryOperatorName: component.confinedEntryOperatorName,
      confinedEntryType: component.confinedEntryType,
    };
  }

  static compileCseDataToSave(
    component: ConfinedSpaceEntryComponentDataInterface
  ): {
    confinedEntry: boolean;
    workPerformedBy: string;
    confinedEntryType: string;
    confinedEntryFiles: Array<any>;
  } {
    const confinedEntry = component.confinedEntry.value === 'yes';
    const confinedEntryOperatorName = component.confinedEntryOperatorName.value;
    const confinedEntryType = component.confinedEntryType.value;
    const confinedEntryFiles = component.uploadedFiles;

    let workPerformedBy = null;

    if (confinedEntry) {
      if (component.lastSelectedOperatorId) {
        workPerformedBy = {
          id: component.lastSelectedOperatorId,
        };
      } else {
        workPerformedBy = { confinedEntryOperatorName };
      }
    }

    return {
      confinedEntry,
      workPerformedBy,
      confinedEntryType,
      confinedEntryFiles,
    };
  }
}
