import { Injectable } from '@angular/core';
import { NcrFormObject } from './ncr-master-form.model';
import { UntypedFormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class NcrMasterFormServices {
  constructor() {}

  getFormObjectAndControls(
    inputObject: NcrFormObject,
    isQualaWorker: boolean,
    isUnauthenticated: boolean,
    isEditing: boolean
  ) {
    const onlyQualaWorker = isQualaWorker;
    const onlyAuthenticated = !isUnauthenticated;

    const equipmentOwnerControl = isQualaWorker
      ? new UntypedFormControl(inputObject.equipmentOwner)
      : new UntypedFormControl(inputObject.equipmentOwner, Validators.required);

    const initiatorEmailControl = isUnauthenticated
      ? new UntypedFormControl(inputObject.initiatorEmail)
      : new UntypedFormControl(inputObject.initiatorEmail, Validators.required);

    return {
      workOrder: {
        control: new UntypedFormControl(inputObject.workOrder),
        isVisible: true,
      },
      shipperName: {
        control: new UntypedFormControl(inputObject.shipperName),
        isVisible: true,
      },
      companyName: {
        control: new UntypedFormControl(
          inputObject.companyName,
          Validators.required
        ),
        isVisible: true,
      },
      phoneNumber: {
        control: new UntypedFormControl(inputObject.phoneNumber),
        isVisible: true,
      },
      customerName: {
        control: new UntypedFormControl(
          inputObject.customerName,
          Validators.required
        ),
        isVisible: true,
      },
      customerEmail: {
        control: new UntypedFormControl(
          inputObject.customerEmail,
          Validators.required
        ),
        isVisible: onlyQualaWorker || onlyAuthenticated,
      },
      dateIssued: {
        control: new UntypedFormControl(
          inputObject.dateIssued,
          Validators.required
        ),
        isVisible: true,
      },
      dateDiscovered: {
        control: new UntypedFormControl(
          inputObject.dateDiscovered,
          Validators.required
        ),
        isVisible: true,
      },
      terminal: {
        control: new UntypedFormControl(
          inputObject.terminal,
          Validators.required
        ),
        isVisible: true,
      },
      containerType: {
        control: new UntypedFormControl(
          inputObject.containerType,
          Validators.required
        ),
        isVisible: true,
      },
      containerNumber: {
        control: new UntypedFormControl(
          inputObject.containerNumber,
          Validators.required
        ),
        isVisible: true,
      },
      issueType: {
        control: new UntypedFormControl(
          inputObject.issueType,
          Validators.required
        ),
        isVisible: true,
      },
      issueDescription: {
        control: new UntypedFormControl(
          inputObject.issueDescription,
          Validators.required
        ),
        isVisible: true,
      },
      isInternalNcr: {
        control: new UntypedFormControl(
          { value: inputObject.isInternalNcr, disabled: isEditing },
          Validators.required
        ),
        isVisible: onlyQualaWorker && onlyAuthenticated,
      },
      typeOfWash: {
        control: new UntypedFormControl(inputObject.typeOfWash),
        isVisible: onlyQualaWorker,
      },
      isFoodGradeClean: {
        control: new UntypedFormControl(
          inputObject.isFoodGradeClean,
          Validators.required
        ),
        isVisible: onlyQualaWorker,
      },
      equipmentOwner: {
        control: equipmentOwnerControl,
        isVisible: true,
      },
      driverName: {
        control: new UntypedFormControl(
          inputObject.driverName,
          Validators.required
        ),
        isVisible: onlyQualaWorker,
      },
      whoDidTheRepair: {
        control: new UntypedFormControl(inputObject.whoDidTheRepair),
        isVisible: onlyQualaWorker,
      },
      receipt: {
        control: new UntypedFormControl(
          inputObject.receipt,
          Validators.required
        ),
        isVisible: onlyQualaWorker,
      },
      wasEquipmentDamaged: {
        control: new UntypedFormControl(
          inputObject.wasEquipmentDamaged,
          Validators.required
        ),
        isVisible: true,
      },
      hasTheOwnerBeenNotified: {
        control: new UntypedFormControl(
          inputObject.hasTheOwnerBeenNotified,
          Validators.required
        ),
        isVisible: onlyQualaWorker,
      },
      whatWasDamaged: {
        control: new UntypedFormControl(
          inputObject.whatWasDamaged,
          Validators.required
        ),
        isVisible: onlyQualaWorker,
      },
      howWasDamaged: {
        control: new UntypedFormControl(
          inputObject.howWasDamaged,
          Validators.required
        ),
        isVisible: onlyQualaWorker,
      },
      tractorNumber: {
        control: new UntypedFormControl(
          inputObject.tractorNumber,
          Validators.required
        ),
        isVisible: onlyQualaWorker,
      },
      trailerNumber: {
        control: new UntypedFormControl(
          inputObject.trailerNumber,
          Validators.required
        ),
        isVisible: onlyQualaWorker,
      },
      repairCost: {
        control: new UntypedFormControl(
          inputObject.repairCost,
          Validators.required
        ),
        isVisible: onlyQualaWorker,
      },
      facilityManager: {
        control: new UntypedFormControl(
          inputObject.facilityManager,
          Validators.required
        ),
        isVisible: true,
      },
      shiftSupervisor: {
        control: new UntypedFormControl(inputObject.shiftSupervisor),
        isVisible: onlyQualaWorker,
      },
      cleaner: {
        control: new UntypedFormControl(inputObject.cleaner),
        isVisible: onlyQualaWorker,
      },
      shiftStartTime: {
        control: new UntypedFormControl(inputObject.shiftStartTime),
        isVisible: onlyQualaWorker,
      },
      technician: {
        control: new UntypedFormControl(inputObject.technician),
        isVisible: onlyQualaWorker,
      },
      namesOfPeopleInvolved: {
        control: new UntypedFormControl(inputObject.namesOfPeopleInvolved),
        isVisible: onlyQualaWorker,
      },
      wasAnyoneInjured: {
        control: new UntypedFormControl(
          inputObject.wasAnyoneInjured,
          Validators.required
        ),
        isVisible: true,
      },
      whoGotHurt: {
        control: new UntypedFormControl(inputObject.whoGotHurt),
        isVisible: onlyQualaWorker,
      },
      peopleNotified: {
        control: new UntypedFormControl(
          inputObject.peopleNotified,
          Validators.required
        ),
        isVisible: true,
      },
      peopleStatement: {
        control: new UntypedFormControl(
          inputObject.peopleStatement,
          Validators.required
        ),
        isVisible: true,
      },
      initiatorEmail: {
        control: initiatorEmailControl,
        isVisible: true,
      },
      orderNumber: {
        control: new UntypedFormControl(inputObject.orderNumber),
        isVisible: true,
      },
    };
  }
}
