import { Component, Input, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-radial-time-picker',
  templateUrl: './radial-time-picker.component.html',
  styleUrls: ['./radial-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadialTimePickerComponent),
      multi: true,
    },
  ],
})
export class RadialTimePickerComponent implements ControlValueAccessor {
  @Input() placeholder =
    'Choose a time. The OK button will be disabled until a time is selected.';

  @Input() validators: any[];
  @Input() disabled: boolean = false;
  @Input() showEraser: boolean = false;

  userInteracted: boolean;

  time: string;

  private onChangeCallback: (time: string) => any;

  private onTouchedCallback: () => any;

  get isRequired(): boolean {
    return this.validators && this.validators.includes(Validators.required);
  }

  writeValue(time: string): void {
    this.time = time;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onTimeSet(time: string): void {
    this.writeValue(time);
    this.onChangeCallback(time);
    this.onTouchedCallback();
  }

  onTimeChanged(time): void {
    this.updateTimeDisplay(time);
    this.userInteracted = true;
  }

  onClosed(): void {
    this.userInteracted = false;
  }

  /**
   * Workaround to manually update the UI display of hours:seconds in the
   * timepicker dialog.
   *
   * TODO: Remove this once https://github.com/Agranom/ngx-material-timepicker/issues/453
   * is fixed.
   */
  private updateTimeDisplay(time): void {
    const [hours, minutes] = time.split(' ')[0].split(':');
    const [hoursDisplayElement, minutesDisplayElement] = Array.from(
      document.getElementsByClassName('timepicker-dial__control')
    ) as HTMLInputElement[];

    hoursDisplayElement.value = ('0' + hours).slice(-2);

    minutesDisplayElement.value = minutes;
  }

  cleanInput(): void {
    this.onTimeSet(null);
  }
}
