import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quala-button',
  templateUrl: './quala-button.html',
  styleUrls: ['./quala-button.scss'],
})
export class QualaButtonComponent implements OnInit {
  @Input() btnText: string;
  @Input() isProcessing: boolean;
  @Input() btnDisabled: boolean;
  @Input() disabledTooltip: string;
  @Input() icon: string;
  @Input() buttonStyle: string;
  @Input() btnId: string;
  @Input() datae2ebutton: string;
  @Output() btnClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  handleClick() {
    this.btnClick.emit();
  }
}
