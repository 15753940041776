import { Injectable } from '@angular/core';
import { User } from './model/user.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setUser(user: User) {
    localStorage.setItem('sso_user_info', JSON.stringify(user));
  }

  getUser(): User | null {
    const userStr = localStorage.getItem('sso_user_info');
    if (userStr) {
      return JSON.parse(userStr);
    }
    return null;
  }

  setUserInformationExpireTime(time: string) {
    localStorage.setItem('user_information_expire_time', time);
  }

  getUserInformationExpireTime() {
    const userStr = localStorage.getItem('user_information_expire_time');
    if (userStr) {
      return JSON.parse(userStr);
    }
    return null;
  }

  setAuthType(authType: string) {
    localStorage.setItem('auth_type', authType);
  }

  setNotifyECTPreferencesByUser(userId: string, notifyECTPreference: boolean) {
    const existingPreferences = JSON.parse(
      localStorage.getItem('ect_preferences_by_user') || '[]'
    );

    const newPreference = {
      userId: userId,
      notifyOnChange: notifyECTPreference,
    };

    const updatedPreferences = existingPreferences.filter(
      (preference: any) => preference.userId !== userId
    );

    updatedPreferences.push(newPreference);

    localStorage.setItem(
      'ect_preferences_by_user',
      JSON.stringify(updatedPreferences)
    );
  }

  getNotifyECTChangesPreferencesByUser(userId: string) {
    const ectPreferences = JSON.parse(
      localStorage.getItem('ect_preferences_by_user') || '[]'
    );
    const ectPreferencesByUser = ectPreferences.filter(
      (preference) => preference.userId === userId
    );
    if (ectPreferencesByUser.length > 0) {
      return ectPreferencesByUser[0].notifyOnChange;
    }
    return undefined;
  }

  getAuthType() {
    const authType = localStorage.getItem('auth_type');
    if (authType) {
      return authType;
    }
    return null;
  }

  get(key: string): string {
    return localStorage.getItem(key);
  }

  emptyLocalStorage() {
    // TODO: After QUAL-11883 is moved from localStorage to Dynamo + Lambdas, we
    // should remove this localStorage get + set.
    const userNewsItemsViews =
      localStorage.getItem('user_news_items_views') || '[]';
    localStorage.clear();
    localStorage.setItem('user_news_items_views', userNewsItemsViews);
  }

  getPanelMode() {
    const panelMode = localStorage.getItem('panel_mode');
    if (panelMode) {
      return panelMode;
    }
    return null;
  }

  setPanelMode(panelMode: string) {
    localStorage.setItem('panel_mode', panelMode);
  }
}
