import { Injectable } from '@angular/core';
import { uniqBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import {
  BusinessPartner,
  DataStore,
  BusinessPartnerNCR,
} from '../../dashboard/dashboard.model';
import { APIService } from '../API.service';
import { AuthService } from '../auth/auth.service';
import { PublicAPIService } from '../PublicAPI.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessPartnerService {
  constructor(
    private authService: AuthService,
    private api: APIService
  ) {}

  private _businessPartners = new BehaviorSubject<BusinessPartner[]>([]);
  readonly businessPartners = this._businessPartners.asObservable();

  private _hasLoadedBPs = new BehaviorSubject<boolean>(false);
  readonly hasLoadedBPs = this._hasLoadedBPs.asObservable();

  private _businessPartnersNCR = new BehaviorSubject<BusinessPartnerNCR[]>([]);
  readonly businessPartnersNCR = this._businessPartnersNCR.asObservable();

  private _hasLoadedNcrBPs = new BehaviorSubject<boolean>(false);
  readonly hasLoadedNcrBPs = this._hasLoadedNcrBPs.asObservable();

  public dataStore: DataStore = {
    businessPartners: [],
    businessPartnersNCR: [],
  };

  async loadBusinessPartners(api: APIService = this.api, nextToken?: string) {
    this._hasLoadedBPs.next(false);
    const data = await api.ListBusinessPartner(nextToken);

    if (!data || !data.items) {
      return;
    }

    this.handleBusinessPartnerData(data.items);

    if (data.nextToken) {
      await this.loadBusinessPartners(api, data.nextToken);
      return;
    }
    this._hasLoadedBPs.next(true);
  }

  private handleBusinessPartnerData(items: any[]) {
    const result: BusinessPartner[] = items.map((item) => {
      if (this.authService.user) {
        return {
          id: item.id,
          name: item.name,
          customerBlocking: item.customerBlocking === 'true',
          contmngPOFormat: item.contmngPOFormat,
          contmngPOFormathelp: item.contmngPOFormathelp,
          contmngCustomernotes: item.contmngCustomernotes,
          contmngCustomernotesParsed: item.contmngCustomernotesParsed,
        };
      } else {
        return {
          id: item.id,
          name: item.name,
        };
      }
    });

    this.dataStore.businessPartners = uniqBy(
      this.dataStore.businessPartners.concat(result),
      'id'
    );
    this._businessPartners.next([...this.dataStore.businessPartners]);
  }

  private handleBusinessPartnerNcrData(items: any[]) {
    const result: BusinessPartnerNCR[] = items.map((item) => {
      return {
        id: item.id,
        name: item.name,
        onlyNCR: item.onlyNCR || false,
      };
    });

    this.dataStore.businessPartnersNCR = uniqBy(
      this.dataStore.businessPartnersNCR.concat(result),
      'id'
    );
    this._businessPartnersNCR.next([...this.dataStore.businessPartnersNCR]);
  }
}
