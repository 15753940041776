import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { ToastrService } from 'ngx-toastr';
import {
  APIService,
  GetBusinessPartnerNotificationPreferencesByIdQuery,
  GetUserNotificationPreferencesByIdQuery,
} from 'src/app/core/API.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MenuOption } from '../menu/menu.model';
import {
  BusinessPartnerNotificationPreferences,
  Dispatcher,
  GenericUser,
  QualaUser,
  ShareUserNotification,
  UserNotificationPreferences,
} from './notification-preferences.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationPreferencesService {
  private defaultHeaders: any;
  option: MenuOption;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService,
    private appSyncService: APIService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-ontrax-identity': `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
    };

    this.option = MenuOption.ShiftLogs;
  }

  sendBusinessPartnerNotificationPreferences(
    businessPartnerNotificationPreferences: BusinessPartnerNotificationPreferences
  ) {
    const path = '/business-partner';
    const httpOptions = {
      headers: this.defaultHeaders,
      body: { ...businessPartnerNotificationPreferences },
    };
    return API.post('NotificationPreferencesAPI', path, httpOptions);
  }

  sendUserPartnerNotificationPreferences(
    userNotificationPreferences: UserNotificationPreferences
  ) {
    const path = '/users';
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        aduserId: userNotificationPreferences.aduserId,
        forwardUsersIds: userNotificationPreferences.forwardUsersIds,
      },
    };
    return API.post('NotificationPreferencesAPI', path, httpOptions);
  }

  async getLoggedBusinessPartnerNotificationPreferences() {
    return await this.getBusinessPartnerNotificationPreferences(
      this.authService.getBusinessPartnerId()
    );
  }

  async getBusinessPartnerNotificationPreferences(
    businessPartnerId: string
  ): Promise<GetBusinessPartnerNotificationPreferencesByIdQuery> {
    return await this.appSyncService.GetBusinessPartnerNotificationPreferencesById(
      businessPartnerId
    );
  }

  async getUserNotificationPreferences(
    adUserId: string
  ): Promise<GetUserNotificationPreferencesByIdQuery> {
    return await this.appSyncService.GetUserNotificationPreferencesById(
      adUserId
    );
  }

  getDispatchers(businessPartnerId: string): Promise<Dispatcher[]> {
    const path = '/get-dispatchers';
    const httpOptions = {
      headers: this.defaultHeaders,
      queryStringParameters: {
        businessPartnerId,
      },
    };
    return API.get('OnTraxAPI', path, httpOptions);
  }

  getQualaUsers(terminalNumber: string): Promise<QualaUser[]> {
    const path = '/quala-users';
    const httpOptions = {
      headers: this.defaultHeaders,
      queryStringParameters: {
        terminalNumber,
      },
    };
    return API.get('OnTraxAPI', path, httpOptions);
  }

  getAllUsersToForwardNotifications(): Promise<GenericUser[]> {
    if (this.authService.hasQualaWorkerRole()) {
      return this.getQualaUsers(this.authService.user.currentTerminal.number);
    } else {
      return this.getDispatchers(this.authService.user.businessPartnerId);
    }
  }

  async getUserNotificationPreferencesForSelect(): Promise<ShareUserNotification> {
    const response = await Promise.all([
      this.getUserNotificationPreferences(this.authService.user.id),
      this.getAllUsersToForwardNotifications(),
    ]);

    const userPreferences = response[0];
    const allUsersToForward = response[1];

    const forwardUsersIds =
      (userPreferences && userPreferences.forwardUsersIds) || [];
    const sharingWith = allUsersToForward.filter((user) =>
      forwardUsersIds.includes(user.id)
    );

    const allUserExceptLoggedUser =
      allUsersToForward.filter(
        (user) => user.id !== this.authService.user.id
      ) || [];

    return {
      sharingWith,
      allUsers: allUserExceptLoggedUser,
    };
  }
}
