import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-error-info',
  templateUrl: './error-info.component.html',
  styleUrls: ['./error-info.component.scss'],
})
export class ErrorInfoComponent implements OnInit {
  genericErrorMessage =
    'Error when trying to sync with OpenBravo, please contact Quala Team for support.';
  @Input() typeIcon: string;
  @Input() iconClass: string;
  @Input() errorMessage: string;
  constructor() {}

  ngOnInit(): void {}

  get getErrorMessage() {
    const messageLength = this.errorMessage.split('').length;
    if (messageLength > 500) {
      return this.genericErrorMessage;
    }

    return this.errorMessage;
  }
}
