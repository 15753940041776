// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-loader {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border-top: 2px solid rgba(66, 66, 66, 0.2);
  border-right: 2px solid rgba(66, 66, 66, 0.2);
  border-bottom: 2px solid rgba(66, 66, 66, 0.2);
  border-left: 2px solid #424242;
  transform: translateZ(0);
  animation: load8 0.8s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/spinner-loader/spinner-loader.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,2CAAA;EACA,6CAAA;EACA,8CAAA;EACA,8BAAA;EACA,wBAAA;EACA,qCAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF","sourcesContent":[".spinner-loader {\n  width: 17px;\n  height: 17px;\n  border-radius: 50%;\n  border-top: 2px solid rgba(66, 66, 66, 0.2);\n  border-right: 2px solid rgba(66, 66, 66, 0.2);\n  border-bottom: 2px solid rgba(66, 66, 66, 0.2);\n  border-left: 2px solid #424242;\n  transform: translateZ(0);\n  animation: load8 0.8s infinite linear;\n}\n\n@keyframes load8 {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
