import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discret-loader',
  templateUrl: './discret-loader.component.html',
  styleUrls: ['./discret-loader.component.css'],
})
export class DiscretLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
