// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-menu-component {
  display: flex;
  flex-direction: column;
}
.mobile-menu-component .sub-menu {
  width: 100%;
}
.mobile-menu-component .sub-menu:first-child {
  border-top: 1px solid #cdcdcd;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/menu/mobile-menu/mobile-menu.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,6BAAA;AAAJ","sourcesContent":[".mobile-menu-component {\n  display: flex;\n  flex-direction: column;\n\n  .sub-menu {\n    width: 100%;\n  }\n\n  .sub-menu:first-child {\n    border-top: 1px solid #cdcdcd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
