import { Component, Input, Inject } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-feedback',
  templateUrl: './form-feedback.component.html',
  styleUrls: ['./form-feedback.component.scss'],
})
export class FormFeedbackComponent {
  @Input() form: FormGroup;
  @Input() controlNamesFieldsEnum: FormGroup;
  @Input() customErrors;

  constructor() {}

  formErrors(): string[] {
    let errorMessages = [];

    for (const key of Object.keys(this.form.controls)) {
      const control = this.form.get(key);
      if (control && control.invalid) {
        errorMessages.push(...this.getFormControlErrorMessages(control, key));
      }
    }

    if (this.customErrors?.length) {
      errorMessages = errorMessages.concat(this.customErrors);
    }

    return errorMessages;
  }

  private getFormControlErrorMessages(
    control: AbstractControl,
    controlKey: string
  ): string[] {
    const controlErrors = [];

    const controlName = this.controlNamesFieldsEnum[controlKey];

    if (control.hasError('required')) {
      controlErrors.push(`"${controlName}" is required`);
    }

    return controlErrors;
  }
}
