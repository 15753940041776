import { Injectable } from '@angular/core';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { KeycloakService } from 'keycloak-angular';
import { API } from '@aws-amplify/api';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import Amplify from '@aws-amplify/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localstorage.service';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private defaultHeaders: any;
  private privateUser: User | null;
  private userPoolAuthFlowType = 'USER_SRP_AUTH';

  static refreshCognitoToken(keyCloakInstance: KeycloakService) {
    return keyCloakInstance.getToken().then((token) => {
      const decoded: any = jwtDecode(token);
      const expiresAt = decoded.exp;
      return new Promise((resolve) => {
        const data = {
          token,
          expires_at: expiresAt,
        };
        resolve(data);
      });
    });
  }

  constructor(
    private localStorageService: LocalStorageService,
    private keycloak: KeycloakService,
    private gtmService: GoogleTagManagerService,
    private location: Location,
    protected router: Router
  ) {
    this.defaultHeaders = { 'Content-Type': 'application/json' };
    this.configureAmplify();
  }

  private configureAmplify() {
    const refreshHandlers = {};
    refreshHandlers[`${environment.keycloak.provider}`] = () =>
      AuthService.refreshCognitoToken(this.keycloak);

    const amplifyConfig = {
      ...environment.amplifyConfig,
    };

    const authConfig = {
      ...environment.amplifyConfig.Auth,
      refreshHandlers,
    };
    amplifyConfig.Auth = authConfig;

    Amplify.configure(amplifyConfig);
  }

  get user(): User {
    if (!this.privateUser) {
      this.privateUser = this.localStorageService.getUser();
    }
    return this.privateUser;
  }

  userHasGivenRole(roleName) {
    return this.user.roles.some((role) =>
      Object.keys(role).some((key) => {
        const isNameKey = key === 'name';
        const value = isNameKey ? role[key].split('.')[1] : key;

        return isNameKey ? value === roleName : value.includes(roleName);
      })
    );
  }

  hasDispatcherRole() {
    return (
      this.user.currentRole === 'Customer Dispatcher' ||
      this.user.currentRole === 'Customer All'
    );
  }

  getBusinessPartnerId() {
    return this.user.businessPartnerId;
  }

  getBusinessParterName() {
    return this.user.businessPartnerName;
  }

  hasQualaWorkerRole() {
    const role = this.user.currentRole || '';
    return (
      role.includes('Depot') ||
      role.includes('CSC') ||
      role.includes('Ticket') ||
      role.includes('-Manager') ||
      role.includes('Cleaner')
    );
  }

  isInternal() {
    const isExternalUser =
      this.user?.roles?.length > 0 ? this.hasDispatcherRole() : true;
    return this.user?.isInternal !== undefined
      ? this.user?.isInternal
      : !isExternalUser;
  }

  getUserType() {
    return this.isInternal() ? 'Internal' : 'External';
  }

  hasUserPlatformActions(action: string) {
    return (
      this.user.userPlatformActions && this.user.userPlatformActions[action]
    );
  }

  hasFullReportAccess() {
    const hasFullReportAccess =
      (this.hasUserPlatformActions('ncr') &&
        this.user.userPlatformActions.ncr.hasFullReportAccess) ||
      this.user.hasFullReportAccess ||
      false;
    return hasFullReportAccess;
  }

  hasAllReportActions() {
    const hasAllReportActions =
      (this.hasUserPlatformActions('ncr') &&
        this.user.userPlatformActions.ncr.hasAllReportActions) ||
      this.user.hasFullReportAccess ||
      false;
    return hasAllReportActions;
  }

  hasTerminalReportAccess() {
    const hasTerminalReportAccess =
      (this.hasUserPlatformActions('ncr') &&
        this.user.userPlatformActions.ncr.hasTerminalReportAccess) ||
      false;
    return hasTerminalReportAccess;
  }

  hasBusinessGroupAccessLevel() {
    const hasBusinessGroupReportAccess =
      (this.hasUserPlatformActions('ncr') &&
        this.user.userPlatformActions.ncr.hasBusinessGroupReportAccess) ||
      false;
    return hasBusinessGroupReportAccess;
  }

  hasRVPDepotRole() {
    return this.userHasGivenRole('RVP Depot');
  }

  hasManagerRole() {
    const role = this.user.currentRole || '';
    return role.includes('-Manager');
  }

  getUser() {
    if (!this.privateUser) {
      this.privateUser = this.localStorageService.getUser();
    }
    return this.privateUser;
  }

  async isAuthorized() {
    try {
      const currentCredentials: any = await Auth.currentCredentials();
      if (
        currentCredentials.authenticated &&
        currentCredentials.expiration > new Date()
      ) {
        const { expiresAt } = await this.getKeycloakToken();
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async unauthorizeUser() {
    this.clearUserData();
    await Auth.signOut();
  }

  async clearUserData() {
    this.localStorageService.emptyLocalStorage();
    this.privateUser = null;
  }

  async refreshCredentials() {
    const isAuthorized = await this.isAuthorized();
    if (!isAuthorized) {
      await this.authorize();
    }
  }

  async refreshUserInformation() {
    let userInformationExpireTime =
      this.localStorageService.getUserInformationExpireTime();

    const isInformationExpired = Date.now() > userInformationExpireTime;

    if (userInformationExpireTime === null || isInformationExpired) {
      const expirationMinutes = 5;
      userInformationExpireTime = new Date().setMinutes(
        new Date().getMinutes() + expirationMinutes
      );
    }

    if (isInformationExpired) {
      this.localStorageService.setUserInformationExpireTime(
        userInformationExpireTime
      );
      await this.getProfile();
    }
  }

  async getKeycloakToken() {
    const token = await this.keycloak.getToken();
    const decoded: any = jwtDecode(token);
    const username = decoded.preferred_username;
    const expiresAt = decoded.exp;

    return { token, username, expiresAt };
  }

  async authorize() {
    const { token, username, expiresAt } = await this.getKeycloakToken();
    await Auth.federatedSignIn(
      environment.keycloak.provider, // Save in environments.ts
      { token, expires_at: expiresAt },
      { name: username }
    );
  }

  async isOnTraxLoggedUserSameAsSSOLoggedUser(): Promise<boolean> {
    const isLoggedId = await this.isLoggedIn();
    if (isLoggedId && this.user) {
      const token = await this.keycloak.getToken();
      const decoded: any = jwtDecode(token);
      const username = decoded.preferred_username;
      return this.user.username === username;
    }
    return false;
  }

  async getCurrentAuthenticatedUser() {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {}
    return user;
  }

  async isUserPoolLogged() {
    const user = await this.getCurrentAuthenticatedUser();
    return (
      user &&
      user.authenticationFlowType === this.userPoolAuthFlowType &&
      user.pool
    );
  }

  async needToCleanUserPoolCache() {
    const isUserPoolLogged =
      this.localStorageService.getAuthType() === 'USER_POOL';
    const isLoggedIn = await this.isUserPoolLogged();
    return !isLoggedIn && isUserPoolLogged;
  }

  async signIn(userInfo: { username: string; password: string }) {
    let user;
    user = await Auth.signIn(userInfo);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return { newPasswordRequired: true, user };
    }
    await this.getProfile();
    return user;
  }

  async completeNewPassword(newPassword: string, user: CognitoUser) {
    try {
      this.localStorageService.emptyLocalStorage();
      await Auth.completeNewPassword(user, newPassword);
      this.localStorageService.setAuthType('USER_POOL');
      await this.getProfile();
      return {
        code: 200,
      };
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        return {
          code: 401,
        };
      }
    }
  }

  async isLoggedIn(): Promise<boolean> {
    const keycloakLoggedIn = await this.keycloak.isLoggedIn();
    const userpoolLoggedIn = await this.isUserPoolLogged();
    return keycloakLoggedIn || userpoolLoggedIn;
  }

  async forceDepotLocalStorageClean() {
    const needToCleanCache = await this.needToCleanUserPoolCache();
    if (needToCleanCache && this.location.path() === '') {
      this.clearUserData();
    }
  }

  async login(
    { redirectUri },
    userInfo?: { username: string; password: string }
  ) {
    if (!redirectUri && userInfo) {
      this.localStorageService.setAuthType('USER_POOL');
      const response = await this.signIn(userInfo);
      return response;
    }
    await this.redirect({ redirectUri });
  }

  async redirect({ redirectUri }) {
    if (this.localStorageService.getAuthType() === 'USER_POOL') {
      window.location.assign('/login-depot');
      return;
    }
    await this.keycloak.login({ redirectUri });
  }

  async logout(authType?: string) {
    if (authType === 'USER_POOL') {
      window.location.assign('/login-depot');
      await Auth.signOut();
      return;
    }
    this.keycloak.logout(window.location.origin);
  }

  async getProfile() {
    try {
      const response = await API.get('AuthAPI', '/fetch-user-profile', {
        headers: this.defaultHeaders,
      });
      const user = new User(response);
      this.localStorageService.setUser(user);
      this.setUserPropertiesOnDataLayer();
      return user;
    } catch (error) {
      this.router.navigate(['/login-error']);
      throw new Error('Error when trying to get users profile');
    }
  }

  async changeRole(toRole: string) {
    const identity = `${this.user.username};${this.user.currentRoleAcronym}`;
    const profile = await this.changeUserRole(toRole, identity);
    const user = new User(profile);
    this.localStorageService.setUser(user);
    this.setUserPropertiesOnDataLayer();
    return user;
  }

  private async changeUserRole(toRole: string, identity: string) {
    return await API.post('AuthAPI', `/change-role`, {
      headers: {
        ...this.defaultHeaders,
        'x-ontrax-identity': identity,
      },
      body: { targetRole: toRole },
    });
  }

  private setUserPropertiesOnDataLayer() {
    const userProperties = {
      event: 'setUserProperties',
      username: this.user.username,
      role: this.user.currentRoleAcronym,
      ...(this.user.currentTerminal && {
        terminal: Number(this.user.currentTerminal.number),
      }),
      ...(this.user.businessPartnerName && {
        businessPartner: this.user.businessPartnerName,
      }),
    };

    this.gtmService.pushTag(userProperties);
  }

  getUserSessionExpirationTime() {
    return this.localStorageService.getUserInformationExpireTime();
  }

  async logUnauthorizedPageAccess(data) {
    const identity = `${this.user.username};${this.user.currentRoleAcronym}`;

    return API.post('AuthAPI', `/log-unauthorized-user`, {
      headers: {
        ...this.defaultHeaders,
        'x-ontrax-identity': identity,
      },
      body: { data },
    });
  }
}
