import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-display-field',
  templateUrl: './display-field.component.html',
  styleUrls: ['./display-field.component.scss'],
})
export class DisplayFieldComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() isRed: boolean;
  @Input() isOrange: boolean;
  @Input() id: string;

  get valueFieldId() {
    return this.id ? `display-field-${this.id}-value` : undefined;
  }

  constructor() {}

  ngOnInit() {}
}
