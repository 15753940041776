import { Component, Input, OnInit } from '@angular/core';
import {
  typeOfHeat,
  temperatureUnits,
} from './steam-heat-data-form.component.model';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { SteamHeatData } from '../../wash-list.model';

@Component({
  selector: 'app-steam-heat-data-form',
  templateUrl: './steam-heat-data-form.component.html',
  styleUrls: ['./steam-heat-data-form.component.scss'],
})
export class SteamHeatDataFormComponent implements OnInit {
  private defaultTemperatureUnit = 'Fahrenheit';

  typeOfHeatOptions = typeOfHeat;
  temperatureUnitOptions = temperatureUnits;
  steamHeatDataFormGroup: UntypedFormGroup;

  @Input() parentForm: UntypedFormGroup;
  @Input() inputSteamData?: SteamHeatData;

  constructor() {}

  ngOnInit(): void {
    this.initializeForm();
  }

  get temperatureSymbol(): string {
    const selectedTemperature =
      this.steamHeatDataFormGroup.get('temperatureUnit').value;
    return temperatureUnits.find((unit) => unit.value === selectedTemperature)
      .symbol;
  }

  private initializeForm() {
    const typeValue = (this.inputSteamData && this.inputSteamData.type) || '';
    const temperatureUnitValue =
      (this.inputSteamData && this.inputSteamData.temperatureUnit) ||
      this.defaultTemperatureUnit;
    const minTemperatureValue =
      (this.inputSteamData && this.inputSteamData.minTemperature) || '';
    const maxTemperatureValue =
      (this.inputSteamData && this.inputSteamData.maxTemperature) || '';

    this.steamHeatDataFormGroup = new UntypedFormGroup({
      type: new UntypedFormControl(typeValue, Validators.required),
      temperatureUnit: new UntypedFormControl(
        temperatureUnitValue,
        Validators.required
      ),
      minTemperature: new UntypedFormControl(
        minTemperatureValue,
        Validators.required
      ),
      maxTemperature: new UntypedFormControl(
        maxTemperatureValue,
        Validators.required
      ),
    });

    this.parentForm.setControl('steamHeatData', this.steamHeatDataFormGroup);
  }
}
