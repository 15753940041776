import { Component, OnInit, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import { NonConformityReport } from '../../non-conformity-report.model';
import { APIService } from 'src/app/core/API.service';

@Component({
  selector: 'app-review-form-section',
  templateUrl: './review-form-section.component.html',
  styleUrls: ['./review-form-section.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ReviewFormSectionComponent implements OnInit {
  reviewForm: UntypedFormGroup;

  incidentResponsibilityOptions: string[] = [];
  correctiveActionCategoryOptions: string[] = [];
  incidentCategoryOptions: string[] = [];

  @Input() ncrInput: NonConformityReport;

  constructor(
    private parent: FormGroupDirective,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.loadInitialData();
    const initialValues = this.getObjectValues();
    this.reviewForm = new UntypedFormGroup({
      incidentCategory: new UntypedFormControl(initialValues.incidentCategory),
      correctiveActionCategory: new UntypedFormControl(
        initialValues.correctiveActionCategory
      ),
      incidentResponsibility: new UntypedFormControl(
        initialValues.incidentResponsibility
      ),
    });

    this.parent.form.addControl('review', this.reviewForm);
  }

  loadInitialData() {
    this.apiService.ListNcrIncidentResponsibility().then((list) => {
      this.incidentResponsibilityOptions = this.sort(list.items);
    });
    this.apiService.ListNcrCorrectiveActionCategory().then((list) => {
      this.correctiveActionCategoryOptions = this.sort(list.items);
    });
    this.apiService.ListNcrIncidentCategory().then((list) => {
      this.incidentCategoryOptions = this.sort(list.items);
    });
  }

  sort(list: any[]) {
    const sorted = list.sort((obj, otherObj) =>
      obj.order > otherObj.order ? 1 : -1
    );
    const output = [];
    for (const obj of sorted) {
      output.push(obj.displayName);
    }
    return output;
  }

  private getSafe(func: () => any) {
    try {
      return func();
    } catch (e) {
      return undefined;
    }
  }

  private getObjectValues() {
    const inputData: NonConformityReport = this.ncrInput || ({} as any);
    return {
      incidentCategory: this.getSafe(() => inputData.incidentCategory) || '',
      correctiveActionCategory:
        this.getSafe(() => inputData.correctiveActionCategory) || '',
      incidentResponsibility:
        this.getSafe(() => inputData.incidentResponsibility) || '',
    };
  }
}
