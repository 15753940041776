import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuOption } from '../menu/menu.model';
import { WashListComponent } from '../wash-list/wash-list.component';

@Component({
  selector: 'app-access-denied',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './access-denied.component.html',
  styleUrl: './access-denied.component.scss',
})
export class AccessDeniedComponent implements OnInit {
  static route = 'access-denied';
  ontraxSubSystem: string;
  username: string;
  date: string;
  email: string;
  path: string;

  subSystems = [
    {
      urls: [WashListComponent.route],
      subsystem: 'OnTrax Request List',
    },
    {
      urls: [MenuOption.Ncr],
      subsystem: 'OnTrax NCR',
    },
    {
      urls: [MenuOption.Schedule],
      subsystem: 'OnTrax Schedule',
    },
    {
      urls: [
        MenuOption.Reports,
        MenuOption.WashMetrics,
        MenuOption.LeanDailyManagementReport,
        MenuOption.TerminalScorecardDepot,
        MenuOption.TerminalScorecardIndustrial,
        MenuOption.TerminalScorecardRail,
        MenuOption.TerminalScorecardWash,
        MenuOption.EhsWasteManagement,
      ],
      subsystem: 'OnTrax Reports',
    },
    {
      urls: [MenuOption.ShiftLogs],
      subsystem: 'OnTrax Shift List',
    },
    {
      urls: [MenuOption.BulkUpload],
      subsystem: 'OnTrax Bulk Upload',
    },
    {
      urls: [MenuOption.Settings],
      subsystem: 'Settings',
    },
    {
      urls: [MenuOption.Support],
      subsystem: 'Support',
    },
    {
      urls: [MenuOption.OtherTools],
      subsystem: 'Other Tools',
    },
  ];

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.username = this.authService.user.username;
    this.email = this.authService.user.email;
    this.date = new Date().toUTCString();
    this.route.queryParams.subscribe((params) => {
      this.ontraxSubSystem = this.getSubsystem(params['from']);
      this.path = params['from'];
    });
  }

  getSubsystem(params: string) {
    for (const subSystem of this.subSystems) {
      for (const url of subSystem.urls) {
        if (params.includes(url)) {
          return subSystem.subsystem + ' system';
        }
      }
    }
    return params;
  }
}
