import { Component, OnInit } from '@angular/core';
import { ExteriorWashOfferService } from './exterior-wash-offer.service';
import {
  Sort,
  ElasticSearch,
} from 'src/app/core/model/search-definition.model';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Dispatcher } from '../notification-preferences/notification-preferences.model';
import { Observable } from 'rxjs';
import { WashListService } from '../wash-list/wash-list.service';
import { DISCLAIMER } from './exterior-wash-offer.enum';
import { ExteriorWash, RequestGroup } from './exterior-wash-offer.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exterior-wash-offer',
  templateUrl: './exterior-wash-offer.component.html',
  styleUrls: ['./exterior-wash-offer.component.scss'],
})
export class ExteriorWashOfferComponent implements OnInit {
  loadingSubmit = false;
  triedSubmit: boolean;

  loadingDispatcherToNotify: boolean;
  canSubmitRequest: boolean;
  actionDisclaimer: DISCLAIMER;

  exteriorWashOfferForm: UntypedFormGroup;
  washRequestId: UntypedFormControl;
  dispatcherToNotify: UntypedFormControl;
  offerExpiresIn: UntypedFormControl;

  // Attachments
  uploadedFiles: Array<any> = [];

  requestGroupOptions$: Observable<RequestGroup[]>;
  dispatchersOptions$: Observable<Dispatcher[]>;
  offerExpiresInOptions: { value: number; label: string }[] = [];

  constructor(
    private exteriorWashOfferService: ExteriorWashOfferService,
    private washListService: WashListService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router
  ) {
    this.loadingDispatcherToNotify = false;
    this.canSubmitRequest = false;
  }

  ngOnInit(): void {
    this.createFormControl();
    this.createFormGroup();
    this.createOfferExpiresInOptions();

    const onlyMine = false;
    this.exteriorWashOfferService
      .loadWashRequests(new ElasticSearch(0, onlyMine, '', this.getSort()))
      .finally(() => {
        this.requestGroupOptions$ = this.exteriorWashOfferService.requestGroup;
      });
  }

  private getSort() {
    const sortOrder = 'desc';
    const sortBy = 'arrivalTime';
    const sort = new Sort(sortBy, sortOrder);
    return sort;
  }

  private createFormControl() {
    this.washRequestId = new UntypedFormControl('', Validators.required);
    this.dispatcherToNotify = new UntypedFormControl('', Validators.required);
    this.offerExpiresIn = new UntypedFormControl(null, Validators.required);

    this.dispatcherToNotify.disable();

    this.washRequestId.valueChanges.subscribe((id) => {
      const request = this.exteriorWashOfferService.dataStore.requests.find(
        (item) => item.washRequest.id === id
      );

      this.actionDisclaimer = undefined;

      this.dispatcherToNotify.disable();
      this.dispatcherToNotify.setValue(null);

      if (!this.validateWashRequest()) {
        this.canSubmitRequest = false;
        return;
      }

      this.loadingDispatcherToNotify = true;
      this.exteriorWashOfferForm.disable({ emitEvent: false });
      this.exteriorWashOfferService
        .loadDispatchersByBusinessPartnerId(request.washRequest.customerId)
        .then(() => {
          this.dispatchersOptions$ = this.exteriorWashOfferService.dispatchers;
          this.dispatcherToNotify.enable();

          this.loadingDispatcherToNotify = false;
          this.canSubmitRequest = true;
          this.exteriorWashOfferForm.enable({ emitEvent: false });
        });
    });
  }

  private createFormGroup() {
    this.exteriorWashOfferForm = new UntypedFormGroup({
      washRequestId: this.washRequestId,
      dispatcherToNotify: this.dispatcherToNotify,
      offerExpiresIn: this.offerExpiresIn,
    });
  }

  private createOfferExpiresInOptions() {
    // Start in 30 min, 1800 seconds
    // Finish in 24 h, 86400 seconds
    this.offerExpiresInOptions.push({
      value: 1800,
      label: '30 min',
    });

    for (let value = 3600; value <= 86400; value += 1800) {
      this.offerExpiresInOptions.push({
        value,
        label: `${value / 3600} hours`,
      });
    }
  }

  private validateWashRequest(): boolean {
    const request = this.exteriorWashOfferService.dataStore.requests.find(
      (item) => item.washRequest.id === this.washRequestId.value
    );

    // Check if washRequest allow external wash
    const allowedContainer =
      this.washListService.dataStore.exteriorWashProducts.find(
        (product) =>
          product.containerTypeId === request.washRequest.containerTypeId
      );

    if (!allowedContainer) {
      this.actionDisclaimer = DISCLAIMER.WASH_REQUEST_NOT_ALLOW_EXTERIOR_WASH;
      return false;
    }

    // The request was created with an exterior wash
    if (request.washRequest.exteriorWash) {
      this.actionDisclaimer =
        DISCLAIMER.WASH_REQUEST_ALREADY_HAS_EXTERIOR_WASH_OFFER;
      return false;
    }

    // The request already has an exterior wash offer
    if (request.washRequest.exteriorWashOffer) {
      switch (request.washRequest.exteriorWashOffer.status) {
        case 'PENDING':
          this.actionDisclaimer = DISCLAIMER.PENDING;
          return false;
        case 'ACCEPTED':
          this.actionDisclaimer = DISCLAIMER.ACCEPTED;
          return false;
        case 'DECLINED':
          this.actionDisclaimer = DISCLAIMER.DECLINED;
          return false;
        default:
          break;
      }
    }

    return true;
  }

  onSubmit() {
    this.loadingSubmit = true;
    this.triedSubmit = true;

    const exteriorWash: ExteriorWash = {
      terminalId: this.authService.user.currentTerminal.id,
      notifiedUserEmail: this.dispatcherToNotify.value.email,
      notifiedUserId: this.dispatcherToNotify.value.id,
      notifiedUserName: this.dispatcherToNotify.value.name,
      expirationTime: this.offerExpiresIn.value,
      washRequestId: this.washRequestId.value,
      picture:
        this.uploadedFiles.length > 0 ? this.uploadedFiles[0] : undefined,
    };

    this.exteriorWashOfferService
      .submit(exteriorWash)
      .then(() => {
        this.toastrService.success('Exterior wash offer was sent successfully');
        this.router.navigate(['/dashboard/wash-list/']);
      })
      .catch(() =>
        this.toastrService.error(
          'Could not create exterior wash offer, please contact the administrator',
          'Error'
        )
      )
      .finally(() => (this.loadingSubmit = false));
  }
}
