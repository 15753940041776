// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-mobile-menu-component {
  text-align: center;
  border-bottom: 1px solid #cdcdcd;
  font-size: 14px;
  padding: 0.5vh 0vh;
  cursor: pointer;
}
.sub-mobile-menu-component .selected {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/menu/mobile-menu/mobile-sub-menu/mobile-sub-menu.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".sub-mobile-menu-component {\n  text-align: center;\n  border-bottom: 1px solid #cdcdcd;\n  font-size: 14px;\n  padding: 0.5vh 0vh;\n  cursor: pointer;\n\n  .selected {\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
