export enum BoassoDispatcherIds {
  BoassoAmerica = '097E37E87F574F0884A9201CFD8FB38E',
  BoassoHoyer = '0FCDD877757F40379AF8B0735C7A3EE0',
  BoassoBulkhaul = '446F865B694E48F6A50E4DAC8651905B',
  BoassoStolt = 'F90A2887020D4FFBB85BDFA1E12F8514',
}

export enum ServiceTypeKeys {
  SteamHeat = 'steamHeat',
  CleanAndPrep = 'cleanAndPrep',
  Clean = 'clean',
  Prep = 'prep',
  ExteriorWashOnly = 'exteriorWash',
}

export const reasonOfChangeList = {
  createdByDispatcher: {
    code: 'CR-SW',
    displayName: 'Customer Requested a Specific Wash',
  },
};
