import { WashRequest } from '../../wash-list/wash-list.model';
import { Card } from '../schedule.model';

export class WashRequestDetail extends WashRequest {
  finishTime?: number;
  customerName: string;
}

export interface WashRequestDetailInput {
  card: Card;
  isBayOccupied: boolean;
}
