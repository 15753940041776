import { Component } from '@angular/core';

@Component({
  selector: 'app-nitrogen-icon',
  templateUrl: './nitrogen-icon.component.html',
  styleUrls: ['./nitrogen-icon.component.scss'],
  standalone: true,
})
export class NitrogenIconComponent {
  constructor() {}
}
