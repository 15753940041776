import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ScheduleService } from '../schedule.service';
import { Subscription } from 'rxjs';
import { PanelMode, columnWidth } from '../schedule.model';

const skeletonBays = [
  { name: 'Loading content...' },
  { name: 'Loading content...' },
  { name: 'Loading content...' },
  { name: 'Loading content...' },
  { name: 'Loading content...' },
];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  bays: any[];
  bays$: Subscription;
  defaultColumnWidth = columnWidth;

  @Input() panelMode: PanelMode;
  @Input() panelModeColumnWidth: number;

  constructor(private scheduleService: ScheduleService) {}

  ngOnInit(): void {
    this.bays = skeletonBays;
    this.bays$ = this.scheduleService.bays.subscribe((data) => {
      this.bays = data;
    });
  }

  ngOnDestroy() {
    this.bays$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.defaultColumnWidth =
      changes.panelMode.currentValue && this.panelModeColumnWidth
        ? this.panelModeColumnWidth
        : columnWidth;
  }
}
