import { Component, Input, OnInit } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { NitrogenIconComponent } from '../../../../shared/nitrogen-icon/nitrogen-icon.component';

@Component({
  selector: 'app-card-info-bar',
  templateUrl: './card-info-bar.component.html',
  styleUrls: ['./card-info-bar.component.scss'],
  standalone: true,
  imports: [SharedModule, NitrogenIconComponent],
})
export class CardInfoBarComponent implements OnInit {
  @Input() flareRequired;
  @Input() containsNitrogen;
  @Input() ectCustomerHasViewed;
  @Input() isInProgressBar;

  constructor() {}

  ngOnInit(): void {}
}
