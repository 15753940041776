import { Component } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuOption } from '../../menu/menu.model';

@Component({
  selector: 'app-datalake',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './datalake.component.html',
  styleUrl: './datalake.component.scss',
})
export class DatalakeComponent {
  static route = `other-tools/${MenuOption.OtherToolsDatalake}`;
}
