import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { AuthService } from '../core/auth/auth.service';

enum Operators {
  All = 'ALL',
  Any = 'ANY',
}

interface Conditions {
  terminal?: number[];
  role?: string[];
  businessPartner?: string[];
  _operator?: Operators.All | Operators.Any;
}

interface RuleConfig {
  _conditions: Conditions[];
  _operator?: Operators.All | Operators.Any;
}

interface GtmConfigs {
  [key: string]: RuleConfig | any;
}

@Injectable({
  providedIn: 'root',
})
export class GtmUtilsService {
  private gtmConfigs: GtmConfigs;

  constructor(
    private authService: AuthService,
    private gtmService: GoogleTagManagerService
  ) {}

  /**
   * Gets a GTM config from the Data Layer, provided in gtmConfigs.
   *
   * Its provided value can be anything, and can also be a "rule" object, with
   * conditions to be evaluated. If not following this object's syntax, the
   * value itself will be returned (boolean, string, number, etc).
   *
   * In order to be a "rule" object, it **must** have a `_conditions` key,
   * which is an array that contains the condition "sets". They're objects that
   * have supported keys as conditions for the config to evaluate to true.
   *
   * Both the "root" object and each of the condition "sets" may have an
   * `_operator` key, whose value should be either `ANY` or `ALL` (case
   * insensitive, optional, always default to `ALL`).
   *
   * Simply put, the "outer" `_operator` controls if `ALL` or `ANY` of the
   * condition sets must evaluate to true, and the inner ones control if `ALL`
   * or `ANY` of the conditions within the condition sets must evaluate to true.
   *
   * @see evaluateSet to view supported keys (keys that may exist in the
   * condition set) and how each of them is evaluated.
   *
   * Rule Syntax example:
   *
   * {
   *   _conditions: [
   *     {
   *       terminal: [109, 222],
   *       role: ['Manager', 'CSC'],
   *       businessPartner: ['Boasso America, Inc', 'Action Resource'],
   *       -- Any other supported properties here --
   *       _operator: 'ALL'
   *     },
   *     {
   *       terminal: [110, 286]
   *     },
   *   ],
   *   _operator: 'ANY'
   * }
   *
   * @param configName The config name, as available in the Data Layer.
   * @returns true|false / The config value (if not a "rule" object).
   */
  public getGtmConfig = async (configName: string): Promise<any> => {
    const gtmConfigs = this.gtmConfigs || (await this.extractGtmConfigs());

    if (!Object.keys(gtmConfigs).length || !gtmConfigs[configName]) {
      return false;
    }

    const config = gtmConfigs[configName];

    if (this.isRuleConfig(config)) {
      const { _operator = Operators.All, _conditions: conditionSets } = config;
      const normalizedOperator = _operator.toUpperCase();

      if (normalizedOperator === Operators.All) {
        return conditionSets.every(this.evaluateSet);
      } else if (normalizedOperator === Operators.Any) {
        return conditionSets.some(this.evaluateSet);
      }

      return false;
    } else {
      return config;
    }
  };

  /**
   * Checks if an object is a "rule" config.
   * @param obj The object to be validated.
   * @returns obj is RuleConfig.
   */
  private isRuleConfig(obj: any): obj is RuleConfig {
    return obj && typeof obj === 'object' && '_conditions' in obj;
  }

  /**
   * Finds the `gtmConfigs` object in the Data Layer.
   * @returns gtmConfigs or an empty object, in case it doesn't exist.
   */
  private extractGtmConfigs = (): Promise<GtmConfigs> => {
    return new Promise((resolve) => {
      let resolved = false;

      // Poll the dataLayer until the gtmConfigs object is found.
      const intervalId = setInterval(() => {
        const dataLayer: any[] = this.gtmService.getDataLayer();

        if (!dataLayer) {
          return;
        }

        for (const obj of dataLayer) {
          if (obj.hasOwnProperty('gtmConfigs')) {
            clearInterval(intervalId);
            this.gtmConfigs = obj.gtmConfigs;
            resolved = true;
            resolve(obj.gtmConfigs);
          }
        }
      }, 100);

      // Resolve with an empty object if it takes too long.
      setTimeout(() => {
        if (!resolved) {
          clearInterval(intervalId);
          this.gtmConfigs = {};
          resolve({});
        }
      }, 5000);
    });
  };

  /**
   * Runs a condition set against evaluations for supported properties
   * (such as user role, terminal, etc), and checks all or any of them
   * depending on the defined _operator.
   * @param conditionSet A set of conditions, as exemplified in getGtmConfig().
   * @returns Whether the set evaluates to true (all or any conditions).
   */
  private evaluateSet = (conditionSet: Record<string, any>): boolean => {
    const { _operator = Operators.All } = conditionSet;
    const normalizedOperator = _operator.toUpperCase();
    const validations: boolean[] = [];

    const username = this.authService.user.username;
    const currentRole = this.authService.user.currentRoleAcronym;
    const currentTerminal = this.authService.user.currentTerminal;
    const userBusinessPartner = this.authService.user.businessPartnerName;

    if (conditionSet.username) {
      validations.push(conditionSet.username.includes(username));
    }

    if (conditionSet.role) {
      validations.push(conditionSet.role.includes(currentRole));
    }

    if (currentTerminal && conditionSet.terminal) {
      const terminalNumber = Number(currentTerminal.number);
      validations.push(conditionSet.terminal.includes(terminalNumber));
    }

    if (userBusinessPartner && conditionSet.businessPartner) {
      validations.push(
        conditionSet.businessPartner.includes(userBusinessPartner)
      );
    }

    if (normalizedOperator === Operators.All) {
      return !!validations.length && validations.every((value) => !!value);
    } else if (normalizedOperator === Operators.Any) {
      return !!validations.length && validations.some((value) => !!value);
    }

    return false;
  };
}
