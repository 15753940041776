import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SystemService } from '../core/system.service';
import { take } from 'rxjs/operators';

@Injectable()
export class SystemMaintenanceGuard {
  constructor(
    private systemService: SystemService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkMaintenance();
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }

  async checkMaintenance(): Promise<boolean> {
    const hasMaintenance = await this.systemService.maintenance
      .pipe(take(1))
      .toPromise();

    if (hasMaintenance) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
