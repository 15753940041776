export enum NCRCreateFieldsEnum {
  companyName = 'Company Name',
  companyId = 'Company Id',
  customerName = 'Customer Contact Name',
  customerEmail = 'Customer Email',
  terminal = ' Terminal Number',
  containerType = 'Container Type',
  containerNumber = 'Container Number',
  issueType = 'Issue Type',
  issueDescription = 'Issue Description',
  facilityManager = 'Facility Manager Name',
  equipmentOwner = 'Who owns the Equipment',
  peopleStatement = 'Who have signed the statement',
  initiatorEmail = 'Identification Email',
  driverName = 'Driver Name',
  whatWasDamaged = 'What equipment was damaged',
  howWasDamaged = 'How was it damaged',
  tractorNumber = 'Tractor Number',
  trailerNumber = 'Trailer Number',
  peopleNotified = 'Who has been notified',
  phoneNumber = 'Customer Phone Number',
  wasAnyoneInjured = 'Was anyone injured',
  whoGotHurt = 'Who got hurt',
}
