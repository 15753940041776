import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private activePopups: any[] = [];

  register(popup: any) {
    this.activePopups.push(popup);
  }

  unregister(popup: any) {
    this.activePopups = this.activePopups.filter(
      (popupInStack) => popupInStack !== popup
    );
  }

  getTopPopup() {
    return this.activePopups[this.activePopups.length - 1];
  }
}
