import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { skipWhile } from 'rxjs/operators';
import { AgendaService } from './agenda.service';
import { ScheduleService } from '../schedule.service';
import { combineLatest, Subscription } from 'rxjs';
import { Agenda, AgendaShift, Slot, AgendaBay } from './agenda.model';
import {
  Card,
  slotHeight,
  cardPaddingTop,
  Shift,
  timeIntervalInMinutes,
  PanelMode,
} from '../schedule.model';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements OnInit, OnDestroy {
  agenda: Agenda = { shifts: [] };

  slotHeight: number;
  firstSlotTime: number;
  cardPaddingTop: number;
  isMoveMode = false;
  config$: Subscription;
  isMoveMode$: Subscription;

  @Input() panelMode: PanelMode;

  constructor(
    private scheduleService: ScheduleService,
    private agendaService: AgendaService
  ) {}

  ngOnInit() {
    this.slotHeight = slotHeight;
    this.cardPaddingTop = cardPaddingTop;

    this.isMoveMode$ = this.scheduleService.isMoveMode.subscribe((data) => {
      this.isMoveMode = data;
    });

    // Load first shift and all bays
    this.config$ = combineLatest([
      this.scheduleService.shifts,
      this.scheduleService.bays,
      this.scheduleService.cards,
    ])
      .pipe(skipWhile((_) => this.isMoveMode))
      .subscribe(([shifts, bays, cards]) => {
        // Initially load the first
        const agenda = this.agendaService.buildAgenda(
          shifts,
          bays,
          cards,
          this.scheduleService.firstSlotTime
        );
        this.agenda = agenda;
        this.firstSlotTime = this.scheduleService.firstSlotTime;
      });
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.isMoveMode$.unsubscribe();
  }

  /**
   * Calculate position of the card.
   * Identify slot from startTime of card. Slots holds slot position.
   * Get which shift the card comes from. So that we can calculate the amount of padding top
   * (where the date is shown) to considerate in our calculation.
   * @param card - Card
   * @param shift - Shift
   */
  getCardPosition(card: Card, shift: AgendaShift) {
    const slotPosition =
      (card.startTime -
        Math.max(shift.startTime, this.scheduleService.firstSlotTime)) /
      (10 * 60);
    return (
      slotPosition * (this.slotHeight + this.cardPaddingTop) +
      this.cardPaddingTop
    );
  }

  moveCard(targetSlot: Slot, targeBay: AgendaBay) {
    const cardsToMove = this.agendaService.getCardsToMove(
      this.scheduleService.selectedCard,
      targetSlot,
      targeBay
    );
    this.scheduleService.moveCards(cardsToMove);
  }

  private isShiftInANonWorkingDay(shift: Shift) {
    const startOfShiftWasChanged =
      shift.originalShiftTime.start !== shift.startTime;
    const endOfShiftWasChanged =
      shift.originalShiftTime.finish !== shift.finishTime;
    const shiftWasChanged = endOfShiftWasChanged && startOfShiftWasChanged;
    const isANonWorkingDay =
      shift.originalShiftTime.start === shift.originalShiftTime.finish;

    return shiftWasChanged && isANonWorkingDay;
  }

  getTimeOutOfShiftSize(
    shift: Shift,
    firstSlotTime: number
  ): {
    timeBeforeShift: number;
    sizeBeforeShift: number;
    timeAfterShift: number;
    sizeAfterShift: number;
    timeMiddleShift: number;
    sizeMiddleShift: number;
  } {
    const displayStartTime = Math.max(shift.startTime, firstSlotTime);
    let timeBeforeShift =
      shift.originalShiftTime.start - displayStartTime > 0
        ? shift.originalShiftTime.start - displayStartTime
        : 0;
    let timeAfterShift =
      shift.finishTime - shift.originalShiftTime.finish > 0
        ? shift.finishTime - shift.originalShiftTime.finish
        : 0;
    let timeMiddleShift = 0;

    if (this.isShiftInANonWorkingDay(shift)) {
      timeBeforeShift = 0;
      timeAfterShift = 0;
      timeMiddleShift = shift.finishTime - shift.startTime;
    }

    const result = {
      timeBeforeShift,
      timeAfterShift,
      timeMiddleShift,
      sizeBeforeShift:
        Math.ceil(timeBeforeShift / 60 / timeIntervalInMinutes) *
        (this.slotHeight + cardPaddingTop),
      sizeAfterShift:
        Math.ceil(timeAfterShift / 60 / timeIntervalInMinutes) *
        (this.slotHeight + cardPaddingTop),
      sizeMiddleShift: timeMiddleShift
        ? Math.ceil(timeMiddleShift / timeIntervalInMinutes) +
          (this.slotHeight + cardPaddingTop)
        : 0,
    };

    return result;
  }

  isBayOccupied(bayId: string) {
    return this.scheduleService.isBayOccupied(bayId);
  }
}
