import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AuthService } from '../core/auth/auth.service';
import { CognitoUser } from '@aws-amplify/auth';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  completeNewPasswordForm: UntypedFormGroup;
  username: UntypedFormControl;
  password: UntypedFormControl;
  newPassword: UntypedFormControl;
  confirmPassword: UntypedFormControl;
  blockButton: boolean;
  showAuthErrorMessage: boolean;
  showCompleteNewPasswordInput: boolean;
  arePasswordsEqual: boolean;
  isMatchingRequirements: boolean;
  cognitoUser: CognitoUser;
  isProcessing: boolean;
  isCompletePasswordSessionExpired: boolean;
  private passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    this.blockButton = false;
    this.showAuthErrorMessage = false;
    this.showCompleteNewPasswordInput = false;
    this.arePasswordsEqual = true;
    this.isMatchingRequirements = true;
    this.isCompletePasswordSessionExpired = false;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Log in');
    this.createFormControls();
    this.createFormGroup();
    this.authService.needToCleanUserPoolCache().then((result) => {
      if (result) {
        this.authService.clearUserData();
      }
    });
  }

  checkPasswordsEquality() {
    if (
      this.completeNewPasswordForm.get('newPassword').value !==
      this.completeNewPasswordForm.get('confirmPassword').value
    ) {
      this.arePasswordsEqual = false;
      return;
    }
    this.arePasswordsEqual = true;
  }

  checkPasswordsRequirements() {
    if (
      !this.passwordRegex.test(
        this.completeNewPasswordForm.get('newPassword').value
      )
    ) {
      this.isMatchingRequirements = false;
      return;
    }
    this.isMatchingRequirements = true;
  }

  async completeNewPassword() {
    this.checkPasswordsEquality();
    this.checkPasswordsRequirements();

    if (this.arePasswordsEqual && this.isMatchingRequirements) {
      this.isProcessing = true;
      const newPassword = this.completeNewPasswordForm.get('newPassword').value;
      const response = await this.authService.completeNewPassword(
        newPassword,
        this.cognitoUser
      );
      if (response.code === 401) {
        this.isCompletePasswordSessionExpired = true;
        return;
      }
      window.location.assign('/dashboard/home');
      this.isProcessing = false;
      this.isCompletePasswordSessionExpired = false;
    }
  }

  async signIn() {
    if (this.isProcessing) return;
    this.isProcessing = true;
    const userInfo = {
      username: this.loginForm.get('username').value.trim(),
      password: this.loginForm.get('password').value,
    };
    try {
      const response = await this.authService.login(
        { redirectUri: undefined },
        userInfo
      );
      if (response && response.newPasswordRequired) {
        this.showCompleteNewPasswordInput = true;
        this.cognitoUser = response.user;
        return;
      }
      if (!response) {
        this.showAuthErrorMessage = true;
        return;
      }
      this.showAuthErrorMessage = false;
      window.location.assign('/dashboard/home');
    } catch (error) {
      this.showAuthErrorMessage = true;
    } finally {
      this.isProcessing = false;
    }
  }

  createFormGroup() {
    this.loginForm = new UntypedFormGroup({
      username: this.username,
      password: this.password,
    });

    this.completeNewPasswordForm = new UntypedFormGroup({
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword,
    });
  }
  createFormControls() {
    this.username = new UntypedFormControl('', Validators.required);
    this.password = new UntypedFormControl('', Validators.required);
    this.newPassword = new UntypedFormControl('', Validators.required);
    this.confirmPassword = new UntypedFormControl('', Validators.required);
  }
}
