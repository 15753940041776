import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BusinessPartnerService } from 'src/app/core/services/business-partner.service';
import {
  BusinessPartner,
  ContainerType,
  Tank,
  UOM,
} from '../../wash-list.model';
import { WashListService } from '../../wash-list.service';
import { BoassoDispatcherIds } from '../wash-create.model';

@Component({
  selector: 'app-create-container',
  templateUrl: './create-container.component.html',
  styleUrls: ['./create-container.component.scss'],
})
export class CreateContainerComponent implements OnInit {
  @Input() customerId: string; // Business Partner that operates the tank
  @Output() submitted = new EventEmitter<
    | {
        id: string;
        active: boolean;
        businessPartnerName: string;
        businessPartnerId: string;
        operatedById: string;
        operatedByName: string;
        searchKey: string;
        containerTypeId: string;
        containerTypeName: string;
        compartments: number;
        displayName: string;
      }
    | undefined
  >();
  @Output() cancelled = new EventEmitter<void>();

  typeOptions$: Observable<ContainerType[]>;
  ownerOptions$: Observable<BusinessPartner[]>;
  uomOptions$: Observable<UOM[]>;

  newTankForm: UntypedFormGroup;
  tankNumberInput: UntypedFormControl;
  tankOwnerInput: UntypedFormControl;
  typeSelect: UntypedFormControl;
  compartmentsSelect: UntypedFormControl;
  barCodeInput: UntypedFormControl;
  sizesArray: UntypedFormArray;

  isProcessing: boolean;
  private foodGradeContainerType = 'Tank - FoodGrade';
  private defaultUOMId = 'CFB02D28D44D4E649726CF54F4F57685';
  private boassoDispatcherIds: string[] = Object.values(
    BoassoDispatcherIds
  ) as string[];

  get isOperatedByBoasso(): boolean {
    return this.boassoDispatcherIds.includes(this.customerId);
  }

  constructor(
    private washListService: WashListService,
    private toastr: ToastrService,
    private businessPartnerService: BusinessPartnerService
  ) {}

  ngOnInit(): void {
    // Set options
    this.typeOptions$ = this.washListService.containerTypes;
    this.ownerOptions$ = this.businessPartnerService.businessPartners;
    this.uomOptions$ = this.washListService.uoms.pipe(
      tap((array) => {
        array.sort((a, b) => (a.name < b.name ? -1 : 1));
      })
    );

    this.initForm();
  }

  submit() {
    this.isProcessing = true;
    const newTank: Tank = {
      compartments: +this.newTankForm.getRawValue().compartments,
      tankNumber: this.newTankForm.value.tankNumber,
      ownerId: this.newTankForm.value.tankOwner.id,
      ownerName: this.newTankForm.value.tankOwner.name,
      barCode: this.newTankForm.value.barCode,
      tankType: {
        id: this.typeSelect.value.id,
        name: this.typeSelect.value.name,
      },
      businessPartner: this.customerId,
      sizes: this.sizesArray.value,
    };

    this.washListService
      .createTank(newTank)
      .then((res: any) => {
        this.toastr.success('', 'Tank created!');
        const createdTank = {
          id: res.id,
          active: res.active,
          businessPartnerName: res.businessPartnerName,
          businessPartnerId: res.businessPartnerId,
          operatedById: res.businessPartnerName,
          operatedByName: res.businessPartnerName,
          searchKey: res.searchKey,
          containerTypeId: res.containerTypeId,
          containerTypeName: res.containerTypeName,
          compartments: res.compartments,
          displayName: `${res.searchKey} | ${res.containerTypeName} | ${res.businessPartnerName}`,
        };
        this.submitted.emit(createdTank); // Return created tank
      })
      .catch(() => {
        this.toastr.error(
          'Check if container number field has no spaces or special characters.',
          'Error creating new tank'
        );
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  cancel() {
    this.cancelled.emit();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  private initForm() {
    this.sizesArray = new UntypedFormArray([]);
    this.addSizeForm(1);
    this.tankNumberInput = new UntypedFormControl('', Validators.required);
    this.tankOwnerInput = new UntypedFormControl('', Validators.required);
    this.typeSelect = new UntypedFormControl('', Validators.required);
    this.barCodeInput = new UntypedFormControl('');
    this.compartmentsSelect = new UntypedFormControl(
      { value: 1, disabled: true },
      Validators.required
    );

    // Create the form
    this.newTankForm = new UntypedFormGroup({
      tankNumber: this.tankNumberInput,
      tankOwner: this.tankOwnerInput,
      type: this.typeSelect,
      compartments: this.compartmentsSelect,
      barCode: this.barCodeInput,
      sizes: this.sizesArray,
    });

    // For boasso, set a default tank type ISO
    const isOperatedByBoasso = this.boassoDispatcherIds.includes(
      this.customerId
    );
    if (isOperatedByBoasso) {
      const isoTankType = {
        id: '7B5F095DD6894E5AB8AA9DCA607F2750',
        name: 'ISO',
      };
      this.newTankForm.get('type').patchValue(isoTankType);
    }

    // Set listeners
    this.compartmentsSelect.valueChanges.subscribe((value) =>
      this.updateSizeForm(+value)
    );
    this.typeSelect.valueChanges.subscribe((value: ContainerType) => {
      if (value.name === this.foodGradeContainerType) {
        this.compartmentsSelect.setValue(1);
        this.compartmentsSelect.disable();

        return;
      }

      this.compartmentsSelect.enable();
    });
  }

  private updateSizeForm(newCompartmentsValue: number) {
    const currentValue = this.sizesArray.length;
    if (currentValue === newCompartmentsValue) {
      return;
    }

    if (newCompartmentsValue > currentValue) {
      const nextCompartment = currentValue + 1;
      for (
        let index = nextCompartment;
        index <= newCompartmentsValue;
        index++
      ) {
        this.addSizeForm(index);
      }
    } else {
      const startPosition = currentValue - 1;
      for (let index = startPosition; index >= newCompartmentsValue; index--) {
        this.removeSizeForm(index);
      }
    }
  }

  private removeSizeForm(index: number) {
    this.sizesArray.removeAt(index);
  }

  private addSizeForm(compartment: number) {
    this.sizesArray.push(
      new UntypedFormGroup({
        compartment: new UntypedFormControl(compartment),
        size: new UntypedFormControl(),
        uOMId: new UntypedFormControl(this.defaultUOMId),
      })
    );
  }
}
