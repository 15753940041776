// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-component {
  padding: 15px;
  text-align: center;
}
.logout-component .link {
  color: blue;
  cursor: pointer;
}
.logout-component > .topic {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/logout/logout.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;AAAE;EACE,WAAA;EACA,eAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".logout-component {\n  padding: 15px;\n  text-align: center;\n  .link {\n    color: blue;\n    cursor: pointer;\n  }\n  > .topic {\n    margin-left: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
