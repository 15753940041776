// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-title {
  margin-top: 25px;
  border-top: solid 1px #c9c9c9;
  padding-top: 24px;
}

.issue-description {
  pointer-events: none;
}

#textarea-issue-description {
  font-weight: bold;
  background: rgba(149, 156, 161, 0.5490196078);
}

.fiveWhy-form {
  display: grid;
  grid-template-rows: auto auto auto auto auto auto;
  row-gap: 16px;
}
.fiveWhy-form .limited-text-area {
  max-height: 300px;
  min-height: 50px;
}

.disclaimer {
  color: red;
  font-style: italic;
  font-size: 11px;
}

.action-group {
  padding: 5px;
  border-top: solid 1px #c9c9c9;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/non-conformity-report/ncr-master-form/five-why-form-section/five-why-form-section.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,6BAAA;EACA,iBAAA;AACF;;AACA;EACE,oBAAA;AAEF;;AAAA;EACE,iBAAA;EACA,6CAAA;AAGF;;AAAA;EACE,aAAA;EACA,iDAAA;EACA,aAAA;AAGF;AADE;EACE,iBAAA;EACA,gBAAA;AAGJ;;AACA;EACE,UAAA;EACA,kBAAA;EACA,eAAA;AAEF;;AACA;EACE,YAAA;EACA,6BAAA;AAEF","sourcesContent":[".section-title {\n  margin-top: 25px;\n  border-top: solid 1px #c9c9c9;\n  padding-top: 24px;\n}\n.issue-description {\n  pointer-events: none;\n}\n#textarea-issue-description {\n  font-weight: bold;\n  background: #959ca18c;\n}\n\n.fiveWhy-form {\n  display: grid;\n  grid-template-rows: auto auto auto auto auto auto;\n  row-gap: 16px;\n\n  .limited-text-area {\n    max-height: 300px;\n    min-height: 50px;\n  }\n}\n\n.disclaimer {\n  color: red;\n  font-style: italic;\n  font-size: 11px;\n}\n\n.action-group {\n  padding: 5px;\n  border-top: solid 1px #c9c9c9;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
