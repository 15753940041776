import { Injectable } from '@angular/core';
import { APIService } from 'src/app/core/API.service';

@Injectable({
  providedIn: 'root',
})
export class WashDetailsOverlayService {
  constructor(private api: APIService) {}

  async getWashRequestByID(id: string) {
    const data = await this.api.GetWashRequestById(id);
    return data;
  }
}
