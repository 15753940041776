import {
  ValidatorFn,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { CompartmentServiceType } from '../wash-list.model';

export const compartmentsValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const isFoodGradeTank = control.get('foodGrade');
  let validationObj;

  // If tank is of type food grade, verify if lastContainedProduct1Id, 2, and 3 is not empty
  if (isFoodGradeTank.value) {
    const lastContainedProduct1Id = control.get('lastContainedProduct1Id');
    const lastContainedProduct2Id = control.get('lastContainedProduct2Id');
    const lastContainedProduct3Id = control.get('lastContainedProduct3Id');

    validationObj = !!lastContainedProduct1Id.value
      ? null
      : {
          lastContainedProduct1Required: 'lastContainedProduct1Id',
        };

    if (lastContainedProduct3Id.value && !lastContainedProduct2Id.value) {
      validationObj = Object.assign({}, validationObj, {
        lastContainedProduct2Required: 'lastContainedProduct2Id',
      });
    }
    return validationObj;
  }

  // If not food grade, apply validation depending of service type

  const numberOfCompartments = control.get('compartmentsQty');

  for (let index = 1; index <= numberOfCompartments.value; index++) {
    const controlName = `lastContainedProductComp${index}Id`;
    const lastContainedId = control.get(controlName);
    const typeOfService = control.get(`serviceTypeComp${index}`);

    // If tank needs to be worked on, the last contained product must be informed
    if (
      typeOfService.value !== CompartmentServiceType.DoNotClean &&
      !lastContainedId.value
    ) {
      validationObj = Object.assign({}, validationObj, {
        [`lastContainedProductComp${index}Required`]: controlName,
      });
    }
  }

  return validationObj;
};
