import {
  Component,
  forwardRef,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { unix } from 'moment';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => DateTimePickerComponent),
  multi: true,
};

@Component({
  selector: 'app-date-time-picker',
  providers: [CUSTOM_VALUE_ACCESSOR],
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent
  implements ControlValueAccessor, OnChanges
{
  private onChange: (_: any) => any;
  private onTouched: () => any;

  @Input() pickerType = 'both';
  @Input() minDateTime: number;
  @Input() maxDateTime: number;
  @Input() initialValue: number;
  @Input() stepMinute: number;
  @Input() isRequired: boolean = true;
  @Output() changedValue = new EventEmitter<any>();

  disabled: boolean;
  value: Date;

  get minDate() {
    if (this.minDateTime) {
      return unix(Number(this.minDateTime)).utc().toDate();
    }
    return null;
  }

  get maxDate() {
    if (this.maxDateTime) {
      return unix(Number(this.maxDateTime)).utc().toDate();
    }
    return null;
  }

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
  }

  ngOnChanges() {
    if (this.initialValue !== undefined) {
      this.writeValue(this.initialValue);
    }
  }

  changed(data) {
    const selectedDateAndTime = Math.round(
      new Date(data.value.valueOf()).setSeconds(0, 0) / 1000
    );
    this.onChange(selectedDateAndTime);
    this.onTouched();
    this.changedValue.emit(selectedDateAndTime);
  }

  writeValue(value: number): void {
    if (value) {
      this.value = unix(value).utc().toDate();
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toMinDate() {
    if (this.minDate) {
      return new Date(Number(this.minDate) * 1000);
    }
    return null;
  }
}
