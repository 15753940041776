export enum DISCLAIMER {
  WASH_REQUEST_NOT_ALLOW_EXTERIOR_WASH = 'Exterior Wash Service not supported for the selected container.',
  WASH_REQUEST_IS_REQUIRED = 'Wash Request is required.',
  DISPATCHER_IS_REQUIRED = 'Dispatcher is required.',
  EXPIRATION_TIME_IS_REQUIRED = 'Expiration times is required.',
  WASH_REQUEST_ALREADY_HAS_EXTERIOR_WASH_OFFER = 'Service already applied, please proceed with the Exterior Wash.',
  PENDING = 'Exterior Wash offer already sent, wait for the customer response.',
  ACCEPTED = 'Service already applied, please proceed with the Exterior Wash.',
  DECLINED = 'Customer already declined exterior wash for this tank.',
}
