// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.images-carousel-component > .image {
  max-width: 80vw;
  max-height: 80vh;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/expanded-image/expanded-image.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".images-carousel-component {\n  > .image {\n    max-width: 80vw;\n    max-height: 80vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
