// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ncr-cancel-component .header {
  border-bottom: solid 1px #c9c9c9;
  padding-bottom: 15px;
}
.ncr-cancel-component .header .title {
  margin: 0;
}
.ncr-cancel-component .question-label {
  margin: 15px 0;
}
.ncr-cancel-component .actions {
  display: flex;
  background-color: black;
  border-radius: 12px;
  padding: 8px;
  justify-content: flex-end;
  margin-top: 15px;
}
.ncr-cancel-component .actions .action-button {
  width: initial;
}
.ncr-cancel-component .actions .action-button:first-child {
  margin-right: 8px;
}
.ncr-cancel-component .actions .disabled-btn {
  pointer-events: none;
}
.ncr-cancel-component .form-container .form-radio-option {
  display: flex;
  align-items: center;
}
.ncr-cancel-component .form-container .form-radio-option .radio-label {
  margin: 0 0 0 8px;
}

@media only screen and (max-width: 600px) {
  .ncr-cancel-component .form-container .form-radio-option {
    margin-bottom: 10px;
  }
  .ncr-cancel-component .actions {
    justify-content: center;
  }
  .ncr-cancel-component .actions .action-button {
    width: 100%;
  }
  .ncr-cancel-component .actions .action-button:first-child {
    margin-right: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/non-conformity-report/ncr-cancel/ncr-cancel.component.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;EACA,oBAAA;AAAJ;AAEI;EACE,SAAA;AAAN;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;AAHJ;AAKI;EACE,cAAA;AAHN;AAMI;EACE,iBAAA;AAJN;AAOI;EACE,oBAAA;AALN;AAUI;EACE,aAAA;EACA,mBAAA;AARN;AAUM;EACE,iBAAA;AARR;;AAcA;EAGM;IACE,mBAAA;EAbN;EAiBE;IACE,uBAAA;EAfJ;EAiBI;IACE,WAAA;EAfN;EAkBI;IACE,iBAAA;EAhBN;AACF","sourcesContent":[".ncr-cancel-component {\n  .header {\n    border-bottom: solid 1px #c9c9c9;\n    padding-bottom: 15px;\n\n    .title {\n      margin: 0;\n    }\n  }\n\n  .question-label {\n    margin: 15px 0;\n  }\n\n  .actions {\n    display: flex;\n    background-color: black;\n    border-radius: 12px;\n    padding: 8px;\n    justify-content: flex-end;\n    margin-top: 15px;\n\n    .action-button {\n      width: initial;\n    }\n\n    .action-button:first-child {\n      margin-right: 8px;\n    }\n\n    .disabled-btn {\n      pointer-events: none;\n    }\n  }\n\n  .form-container {\n    .form-radio-option {\n      display: flex;\n      align-items: center;\n\n      .radio-label {\n        margin: 0 0 0 8px;\n      }\n    }\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .ncr-cancel-component {\n    .form-container {\n      .form-radio-option {\n        margin-bottom: 10px;\n      }\n    }\n\n    .actions {\n      justify-content: center;\n\n      .action-button {\n        width: 100%;\n      }\n\n      .action-button:first-child {\n        margin-right: 8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
