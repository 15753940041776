import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  SearchDefinition,
  Query,
  Filter,
  Sort,
  TankElasticSearch,
  QueryType,
  SearchRange,
  MustNotExistDefinition,
} from './model/search-definition.model';
import { API } from '@aws-amplify/api';

const pageSize = 25;

@Injectable({
  providedIn: 'root',
})
export class ElasticsearchService {
  private elasticsearchIndice: string;
  private elasticsearchIndiceNcr: string;
  private defaultHeaders: any;

  constructor() {
    this.elasticsearchIndiceNcr = environment.elasticsearchIndiceNcr;
    this.elasticsearchIndice = environment.elasticsearchIndice;
    this.defaultHeaders = { 'Content-Type': 'application/json' };
  }

  private parseElasticResponse(response) {
    const responseList = [];
    response.hits.hits.forEach((hits) => {
      responseList.push(hits._source);
    });
    return {
      list: responseList,
      total: response.hits.total.value,
    };
  }

  private buildElasticSearchDisMax(
    queries: Query[],
    from: number = 0,
    filter: Filter,
    sort?: Sort,
    onlyMineFilters?: Filter[]
  ) {
    const elasticQuery = [];
    const filterQuery = this.buildFilterQueryString(filter);
    const onlyMineQueries = [];
    (onlyMineFilters || []).forEach((item: Filter) => {
      const onlyMineQuery = this.buildFilterQueryString(item);
      if (onlyMineQuery) {
        onlyMineQueries.push(onlyMineQuery);
      }
    });
    queries.forEach((query) => {
      const buildedQuery = this.buildQuery(
        query.searchDefinition,
        query.mustNotExistField,
        query.range,
        query.mustNotExistQuery
      );
      if (query.searchShouldDefinition) {
        buildedQuery.bool = {
          ...buildedQuery.bool,
          ...query.searchShouldDefinition,
        };
      }
      // Add filter on every must statement
      if (filterQuery) {
        buildedQuery.bool.must.push(filterQuery);
      }
      // Add only mine query on bool statement
      if (onlyMineQueries && onlyMineQueries.length > 0) {
        buildedQuery.bool.should = onlyMineQueries;
        buildedQuery.bool.minimum_should_match = 1;
      }

      elasticQuery.push(buildedQuery);
    });

    let sortNode;
    if (sort) {
      sortNode = [
        {
          [sort.field]: {
            order: sort.order,
          },
        },
      ];
    }
    const disMax = {
      from,
      size: pageSize,
      query: {
        dis_max: {
          queries: elasticQuery,
        },
      },
      sort: sortNode,
    };
    return disMax;
  }

  private buildElasticSearchQueryForTank(searchData: TankElasticSearch) {
    const {
      filterValue,
      businessParterId,
      sort,
      from,
      queryFromAllBusinessPartners,
    } = searchData;
    let sortNode;
    if (sort) {
      sortNode = [{ [sort.field]: { order: sort.order } }];
    }
    const result = {
      min_score: 1.5,
      from,
      size: 10,
      query: {
        bool: this.buildBoolForTankQuery(
          queryFromAllBusinessPartners,
          filterValue,
          businessParterId
        ),
      },
      sort: sortNode,
    };

    return result;
  }

  private buildBoolForTankQuery(
    queryFromAllBusinessPartners: boolean,
    filterValue,
    businessParterId
  ) {
    if (queryFromAllBusinessPartners) {
      return {
        must: this.buildFilterQueryString(
          new Filter(['displayName'], filterValue, QueryType.bestFields)
        ),
      };
    } else {
      return {
        must: [
          this.buildFilterQueryString(
            new Filter(['displayName'], filterValue, QueryType.bestFields)
          ),
          this.buildFilterQueryString(
            new Filter(
              ['businessPartnerId', 'businessParterId'],
              businessParterId,
              QueryType.phrase
            )
          ),
        ],
      };
    }
  }

  private buildFilterQueryString(filter: Filter): any {
    if (!filter || !filter.text) {
      return null;
    }
    const queryString = {
      [filter.queryName]: {
        fields: filter.fields,
        query: filter.text,
        type: filter.type,
      },
    };
    return queryString;
  }

  private buildQueryString(field, values, operator, type?) {
    if (type === 'prefix') {
      const prefix = {
        prefix: {
          [field]: {
            value: values[0],
          },
        },
      };
      return prefix;
    }

    let query = '';
    values.forEach((value) => {
      query = query === '' ? value : `${query} ${operator} ${value}`;
    });

    const queryString = {
      query_string: {
        fields: field ? [field] : [],
        query,
        type: type || 'phrase',
      },
    };

    return queryString;
  }

  private buildRangeString(searchRange: SearchRange) {
    const rangeValues = searchRange.rangeValues.map((value) => {
      return {
        [value.rangeType]: value.rangeTime,
      };
    });

    const rangeRules = Object.assign({}, ...rangeValues);

    const query = {
      range: {
        [searchRange.rangeField]: rangeRules,
      },
    };

    return query;
  }

  private buildQuery(
    searchList: SearchDefinition[],
    mustNotExistField?: string,
    range?: SearchRange,
    mustNotExistQuery?: MustNotExistDefinition
  ): any {
    const queryStringList = [];
    searchList.forEach((item: SearchDefinition) => {
      queryStringList.push(
        this.buildQueryString(item.field, item.values, item.operator, item.type)
      );
    });

    if (range) {
      queryStringList.push(this.buildRangeString(range));
    }

    let query;

    if (!mustNotExistField && !mustNotExistQuery) {
      query = {
        bool: {
          must: queryStringList,
        },
      };
    } else if (mustNotExistField) {
      query = {
        bool: {
          must: queryStringList,
          must_not: {
            exists: {
              field: mustNotExistField,
            },
          },
        },
      };
    } else if (mustNotExistQuery) {
      query = {
        bool: {
          must: queryStringList,
          must_not: {
            [mustNotExistQuery.type]: {
              [mustNotExistQuery.field]: {
                value: mustNotExistQuery.value,
              },
            },
          },
        },
      };
    }
    return query;
  }

  async searchWashRequest(
    queries: Query[],
    from: number,
    filter: Filter,
    sort?: Sort,
    onlyMine?: Filter[]
  ) {
    const path = `/${this.elasticsearchIndice}/_search`;
    const query = this.buildElasticSearchDisMax(
      queries,
      from,
      filter,
      sort,
      onlyMine
    );
    const httpOptions = {
      headers: this.defaultHeaders,
      body: query,
    };

    const response = await API.post('Elasticsearch', path, httpOptions);
    return this.parseElasticResponse(response);
  }

  async searchNcr(
    queries: Query[],
    from: number,
    filter: Filter,
    sort?: Sort,
    onlyMine?: Filter[]
  ) {
    const path = `/${this.elasticsearchIndiceNcr}/_search`;
    const query = this.buildElasticSearchDisMax(
      queries,
      from,
      filter,
      sort,
      onlyMine
    );
    const httpOptions = {
      headers: this.defaultHeaders,
      body: query,
    };

    const response = await API.post('Elasticsearch', path, httpOptions);
    return this.parseElasticResponse(response);
  }

  async seachTank(searchData: TankElasticSearch) {
    const path = `/tank/_search`;
    const query = this.buildElasticSearchQueryForTank(searchData);
    const httpOptions = {
      headers: this.defaultHeaders,
      body: query,
    };

    const response = await API.post('Elasticsearch', path, httpOptions);
    return this.parseElasticResponse(response);
  }
}

export { pageSize };
