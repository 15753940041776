import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { MasterCustomer } from 'src/app/dashboard/non-conformity-report/non-conformity-report.model';

interface DataStore {
  masterAccounts: MasterCustomer[];
  isLoadingBPs: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MasterAccountService {
  private defaultHeaders: any;

  public dataStore: DataStore = {
    masterAccounts: [],
    isLoadingBPs: false,
  };

  constructor() {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };
  }

  private _masterAccounts = new BehaviorSubject<MasterCustomer[]>(
    this.dataStore.masterAccounts
  );
  public masterAccounts$: Observable<MasterCustomer[]> =
    this._masterAccounts.asObservable();

  private isLoadingBPs = new BehaviorSubject<boolean>(false);
  public isLoadingBPs$ = this.isLoadingBPs.asObservable();

  async getMasterCustomer() {
    const path = `/master-customer`;
    const httpOptions = {
      headers: this.defaultHeaders,
    };

    this.isLoadingBPs.next(true);

    return API.get('NonConformityReportAPI', path, httpOptions)
      .then((masterCustomers) => {
        const customers = (masterCustomers as Array<MasterCustomer>).map(
          (json) => new MasterCustomer(json)
        );

        this._masterAccounts.next(customers);
        this.dataStore.masterAccounts = customers;
      })
      .finally(() => {
        this.isLoadingBPs.next(false);
      });
  }

  async externalGetMasterCustomer() {
    const path = `/master-customer`;
    const httpOptions = {
      headers: this.defaultHeaders,
    };

    this.isLoadingBPs.next(true);

    return API.get('NonConformityReportAPI', path, httpOptions)
      .then((masterCustomers) => {
        const customers = (masterCustomers as Array<MasterCustomer>).map(
          (json) => new MasterCustomer(json)
        );

        this._masterAccounts.next(customers);
        this.dataStore.masterAccounts = customers;
      })
      .finally(() => {
        this.isLoadingBPs.next(false);
      });
  }
}
