import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorageService } from '../core/localstorage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  private authType: string;
  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.authType = this.localStorageService.getAuthType();
    this.localStorageService.emptyLocalStorage();
  }

  async logout() {
    await this.authService.logout(this.authType);
  }
}
