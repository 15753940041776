// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep {
  --mdc-list-list-item-label-text-size: 12px;
  --mdc-checkbox-state-layer-size: 24px;
}
:host ::ng-deep .mat-mdc-list-base .mat-mdc-list-item {
  height: 30px;
}
:host ::ng-deep .mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__start {
  margin-right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/checkbox-list/checkbox-list.component.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,qCAAA;AACF;AAEI;EACE,YAAA;AAAN;AAEM;EACE,kBAAA;AAAR","sourcesContent":[":host ::ng-deep {\n  --mdc-list-list-item-label-text-size: 12px;\n  --mdc-checkbox-state-layer-size: 24px;\n\n  .mat-mdc-list-base {\n    .mat-mdc-list-item {\n      height: 30px;\n\n      .mdc-list-item__start {\n        margin-right: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
