export enum OfferStatus {
  ACCEPT = 'ACCEPTED',
  DECLINE = 'DECLINED',
}

enum Icon {
  checkCircle = 'check-circle',
  timesCircle = 'times-circle',
  exclamationIcon = 'exclamation-circle',
}

enum IconColor {
  green = '#5AA74E',
  brown = '#A7634E',
}

export interface IResponseExteriorWashOffer {
  titleResponse: string;
  iconResponse: string;
  iconColor: string;
  textResponse: string;
}

export const acceptedExteriorWashOffer: Readonly<IResponseExteriorWashOffer> =
  Object.freeze({
    titleResponse: 'Exterior Wash Accepted',
    iconResponse: Icon.checkCircle,
    iconColor: IconColor.green,
    textResponse:
      'The exterior wash for your container was accepted. We will proceed with the service.'
        .split('.')
        .join('.\n '),
  });

export const declinedExteriorWashOffer: Readonly<IResponseExteriorWashOffer> =
  Object.freeze({
    titleResponse: 'Exterior Wash Declined',
    iconResponse: Icon.timesCircle,
    iconColor: IconColor.green,
    textResponse:
      'Exterior wash offer declined. This service will not be executed, no additional charges will be applied.'
        .split('.')
        .join('.\n '),
  });

export const expiredExteriorWashOffer: Readonly<IResponseExteriorWashOffer> =
  Object.freeze({
    titleResponse: 'Exterior Wash Expired',
    iconResponse: Icon.exclamationIcon,
    iconColor: IconColor.brown,
    textResponse:
      'Exterior wash offer expired. Get in touch with Quala’s facility manager if needed.'
        .split('.')
        .join('.\n '),
  });

export const receivedExteriorWashOffer: Readonly<IResponseExteriorWashOffer> =
  Object.freeze({
    titleResponse: 'Offer Response Already Received',
    iconResponse: Icon.exclamationIcon,
    iconColor: IconColor.brown,
    textResponse:
      'Exterior Wash response already received. Get in touch with Quala’s facility manager if needed.'
        .split('.')
        .join('.\n '),
  });

export const invalidExteriorWashOffer: Readonly<IResponseExteriorWashOffer> =
  Object.freeze({
    titleResponse: 'Invalid Operation',
    iconResponse: Icon.exclamationIcon,
    iconColor: IconColor.brown,
    textResponse: 'The requested operation is invalid for this request;'
      .split('.')
      .join('.\n '),
  });
