import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuOption } from '../../menu/menu.model';
import {
  NonConformityReport,
  ReducedNonConformityReport,
} from '../non-conformity-report.model';
import { ActionsControl } from './ncr-item.model';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../core/auth/auth.service';
import { NcrItemService } from './ncr-item.service';
import { NcrService } from '../ncr.service';
import { NcrCancelComponent } from '../ncr-cancel/ncr-cancel.component';
import { AssignOverlayComponent } from '../assign-overlay/assign-overlay.component';
import { User } from 'src/app/core/model/user.model';
@Component({
  selector: 'app-ncr-item',
  templateUrl: './ncr-item.component.html',
  styleUrls: ['./ncr-item.component.scss'],
})
export class NcrItemComponent implements OnInit {
  currentMenuOption: MenuOption;
  actionsControl: ActionsControl;
  nonConformityReport: NonConformityReport;

  hasAllReportActions: boolean;
  hasQualaWorkerRole: boolean;
  hasValidBusinessPartner: boolean;
  hasValidContainer: boolean;
  isLoadingScreen: boolean;
  showDetail: boolean;
  hasAllMandatoryFields: boolean;
  enableBeResolutionToBeDetermined: boolean;
  isProcessing: boolean;
  today: number = Math.round(Date.now() / 1000);
  hasWarningNCR = false;
  warningMessage: string;
  allManagers: Array<User>;

  loadingOption?: string;

  @Input() item: ReducedNonConformityReport;
  @Input() wasLateCompleted: boolean;
  @Input() showResolutionColumn: boolean;
  @Input() wasResolutionToBeDeterminedCompletion: boolean;
  @Input() issueTypes = [];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private ncrItemService: NcrItemService,
    private ncrService: NcrService,
    private toastr: ToastrService,
    public viewContainerRef: ViewContainerRef,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.currentMenuOption = this.route.snapshot.paramMap.get(
      'option'
    ) as MenuOption;
    this.hasQualaWorkerRole = this.authService.hasQualaWorkerRole();
    this.hasAllReportActions = this.authService.hasAllReportActions();
    this.hasWarningNCR =
      (this.item.dataFabricExecution &&
        this.item.dataFabricExecution.warningStatus) ||
      false;

    this.setActionsVisibility();
  }

  private getSafe(func: () => any) {
    try {
      return func();
    } catch (e) {
      return undefined;
    }
  }

  private checkAllMandatoryFields(ncr: NonConformityReport) {
    const allMandatoryField = ![
      'wasFoodGradeClean' in ncr,
      ncr.issueType,
      ncr.issueDescription,
      ncr.investigation,
      ncr.remedialTraining,
      ncr.incidentResponsibility,
      this.getSafe(() => ncr.customer.email),
      this.getSafe(() => ncr.customer.phone),
      this.getSafe(() => ncr.customer.contactName),
      this.getSafe(() => ncr.customer.businessPartnerName),
      this.getSafe(() => ncr.problemReportedDateTime.dateIssued),
      this.getSafe(() => ncr.problemReportedDateTime.dateDiscovered),
      this.getSafe(() => ncr.qualaInformation.terminalName),
      this.getSafe(() => ncr.investigation.correctiveAction.description),
      this.getSafe(() => ncr.investigation.correctiveAction.responsible),
      this.getSafe(() => ncr.investigation.correctiveAction.completionDate),
      this.getSafe(() => ncr.fiveWhys.rootCause.description),
      this.getSafe(() => ncr.fiveWhys.rootCause.responsible),
      this.getSafe(() => ncr.fiveWhys.rootCause.completionDate),
      this.getSafe(() => ncr.investigation.immediateAction.description),
      this.getSafe(() => ncr.investigation.immediateAction.responsible),
      this.getSafe(() => ncr.investigation.immediateAction.completionDate),
      this.getSafe(
        () => ncr.investigation.validationCorrectiveAction.description
      ),
      this.getSafe(
        () => ncr.investigation.validationCorrectiveAction.responsible
      ),
      this.getSafe(
        () => ncr.investigation.validationCorrectiveAction.completionDate
      ),
    ].some((item) => !item);

    let allDamageFields = true;
    if (ncr.damage && ncr.damage.hasBeenDamaged) {
      allDamageFields =
        !!ncr.damage.whatWasDamaged && !!ncr.damage.howItGotDamaged;
    }

    return allDamageFields && allMandatoryField;
  }

  get hasFilesUploaded() {
    return this.item.uploadedFiles.length > 0;
  }

  booleanResolutionToBeDetermined(value: boolean) {
    return value ? 'TBD' : 'Approved';
  }

  toastErrorMessage(errorMessage) {
    this.toastr.error('', errorMessage);
  }

  toggleDetail() {
    if (
      this.currentMenuOption !== MenuOption.NcrOpened &&
      this.currentMenuOption !== MenuOption.NcrCanceled &&
      !this.showDetail
    ) {
      this.isLoadingScreen = true;
      this.ncrService
        .getNCRById(this.item.id)
        .then((nonConformityReport: NonConformityReport) => {
          this.nonConformityReport = new NonConformityReport(
            nonConformityReport
          );
          this.hasAllMandatoryFields = this.checkAllMandatoryFields(
            this.nonConformityReport
          );
          this.enableBeResolutionToBeDetermined =
            this.enableResolutionToBeDeterminedButton();
          this.showDetail = !this.showDetail;
          this.isLoadingScreen = false;
        });
    } else {
      this.showDetail = !this.showDetail;
    }
  }

  fieldsValidator(itemValue: string): boolean {
    if (itemValue === null) {
      return false;
    }
    if (itemValue === undefined) {
      return false;
    }
    if (itemValue.trim() === '') {
      return false;
    }
    return true;
  }

  setActionsVisibility() {
    this.actionsControl = this.ncrItemService.getAllowedActions(
      this.hasQualaWorkerRole,
      this.hasAllReportActions,
      this.currentMenuOption
    );
  }

  cancelNCR() {
    const dialogRef = this.dialog.open(NcrCancelComponent, {});
    dialogRef.afterClosed().subscribe((reason) => {
      if (!reason) {
        return;
      }

      this.ncrService.cancelNCR(this.item.id, reason).subscribe(
        (_) => {
          this.toastr.success('', 'NCR canceled!');
        },
        () => {
          this.toastErrorMessage('Error cancelling the NCR!');
        }
      );
    });
  }

  sendToApproval() {
    this.loadingOption = 'send-to-approval';
    this.isProcessing = true;
    this.ncrService.submitNCR(this.item.id).subscribe(
      (_) => {
        this.toastr.success('', 'NCR sent to approval!');
        this.disableLoadingAction();
      },
      (error) => {
        let errorMessage;
        if (error.response.status === 400 && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.toastErrorMessage(errorMessage || 'Error submitting the NCR!');
        this.disableLoadingAction();
      }
    );
  }

  isObjectEmpty(obj: object): boolean {
    if (!obj) {
      return true;
    }
    return Object.keys(obj).length === 0;
  }

  createWarningMessage(arr: Array<string>) {
    const warnings = {
      'tankInformation.number': `The tank number is invalid. Original: ${this.item.tankInformation.number}`,
      issueType: `The issue type was filled incorrectly. The value "${this.item.issueType}" has not been mapped.`,
      'issueType,tankInformation.number': `The tank number and issue type fields need to be adjusted.
                                            Original Tank Number: ${this.item.tankInformation.number} -
                                            Original Issue Type: ${this.item.issueType} `,
      facilityManager: 'The facility manager must be informed.',
    };
    const key = arr.sort().join();
    return (this.warningMessage = warnings[key]);
  }

  createReassignmentMessage(reassignComments: string) {
    const reason = reassignComments ? `Reason: ${reassignComments}` : '';
    const warnings = ` This NCR was reassigned. ${reason}`;
    return (this.warningMessage = warnings);
  }

  checkNCRWarning() {
    if (
      this.currentMenuOption === MenuOption.NcrInAnalysis &&
      this.item.isReassignment
    ) {
      this.createReassignmentMessage(
        this.item.reassignmentInfo.reassignComments
      );
      return true;
    }

    if (
      this.isObjectEmpty(this.item.dataFabricExecution) ||
      this.item.dataFabricExecution.issueFields.length === 0
    ) {
      return false;
    }
    this.createWarningMessage(this.item.dataFabricExecution.issueFields);
    return true;
  }

  approveNCR(resolutionToBeDetermined: boolean) {
    this.loadingOption = 'waiting-for-approval';
    this.isProcessing = true;
    this.ncrService.approveNCR(this.item, resolutionToBeDetermined).subscribe(
      (_) => {
        this.toastr.success('', 'NCR Completed!');
        this.disableLoadingAction();
      },
      (error) => {
        let errorMessage;
        if (error.response.status === 400 && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.toastErrorMessage(errorMessage || 'Error submitting the NCR!');
        this.disableLoadingAction();
      }
    );
  }

  enableResolutionToBeDeterminedButton() {
    for (const issue of this.issueTypes) {
      if (
        this.item.issueType === issue.rawName &&
        issue.possibleContaminationOrDamage
      ) {
        return issue.possibleContaminationOrDamage;
      }
    }
    return false;
  }

  displayAssignOverlay(isReassignment?: boolean) {
    this.loadingOption = 'assign';
    this.isProcessing = true;
    const terminalNumber = this.item.qualaInformation.terminalNumber;
    const dialogRef = this.dialog.open(AssignOverlayComponent, {
      panelClass: ['no-scroll-dialog-container', 'custom-dialog'],
      data: { isReassignment, terminalNumber },
    });

    dialogRef.afterClosed().subscribe((overlayData) => {
      if (!overlayData) {
        this.disableLoadingAction();
        return;
      }

      if (isReassignment) {
        this.item.reassignmentInfo = {
          reassignComments: overlayData.reassignComments,
        };
        this.item.isReassignment = true;
      } else {
        this.item.lineOfBusiness = overlayData.lineOfBusiness;
      }
      this.item.assignTo = overlayData.assignedUser;
      this.item.assignToId = overlayData.assignedUserId;

      this.ncrService.assignNCR(this.item).subscribe(
        () => {
          this.toastr.success('', 'NCR assigned!');
          this.disableLoadingAction();
        },
        () => {
          this.toastErrorMessage('Error assigning the NCR!');
          this.disableLoadingAction();
        }
      );
    });
  }

  disableLoadingAction() {
    this.loadingOption = undefined;
    this.isProcessing = false;
  }
}
