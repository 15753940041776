import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(
    private injector: Injector,
    private zone: NgZone,
    private authService: AuthService,
    private gtmService: GoogleTagManagerService
  ) {}

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      this.zone.run(() => {
        this.handleHttpError(error);
      });
    } else if (
      error.message &&
      (error.message.includes('403') ||
        error.message.includes('No credentials'))
    ) {
      console.error(error);
      this.authService.login({ redirectUri: window.location.origin });
    } else {
      console.error(error);
      this.pushErrorInGTM(error);
    }
  }

  private pushErrorInGTM(error) {
    const errorData = {
      event: 'globalClientError',
      error:
        error.message ||
        (error && typeof error === 'object' ? JSON.stringify(error) : error),
      route: window.location.pathname,
    };
    this.gtmService.pushTag(errorData);
  }

  private handleHttpError(httpErrorResponse: HttpErrorResponse) {
    // Http Error
    const error = httpErrorResponse.error;

    if (error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.message);
      // return an observable error with a user-facing error message
      console.error('Network error. Please verify your internet connection.');
    } else {
      const defaultErrorMessage =
        "We're sorry. Something went wrong. Please try again later.";
      const httpStatus = httpErrorResponse.status;
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${httpStatus}, body was: ${JSON.stringify(
          error
        )}`
      );

      if (httpStatus === 403) {
        this.authService.login({ redirectUri: window.location.origin });
        return;
      }

      if (error.details) {
        if (error.details.includes('Error authenticating user')) {
          this.toastrService.error('', 'Invalid user or password');
        } else {
          this.toastrService.error(error.details, 'Error');
        }
      }
    }
  }
}
