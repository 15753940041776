export enum RoleName {
  Manager = 'Manager',
  Depot = 'Depot',
  RvpDepot = 'RVP Depot',
  Ticket = 'Ticket',
  CSC = 'CSC',
  Cleaner = 'Cleaner',
  Customer = 'Customer',
  AllRoles = 'AllRoles',
}
