import { SchneiderCompletionStatus } from 'src/app/core/API.service';
import { UploadedFile } from 'src/app/shared/file-upload/file-upload.model';

export interface CountMap {
  total: any;
  new: any;
}

export enum WashRequestStatus {
  Draft = 'DRAFT',
  Created = 'CREATED',
  Submitted = 'SUBMITTED',
  Accepted = 'ACCEPTED',
  ReviewNeeded = 'REVIEW_NEEDED',
  Scheduled = 'SCHEDULED',
  Updated = 'UPDATED',
  Started = 'STARTED',
  Paused = 'PAUSED',
  OnHold = 'ON_HOLD',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Rejected = 'REJECTED',
  CreditHold = 'CREDIT_HOLD',
}

export enum WashRequestFileFlag {
  CsePermit = 'csePermit',
  ExteriorWash = 'exteriorWash',
  Hell = 'heel',
}

export enum CompartmentServiceType {
  SameAsMain = 'Same as the main request',
  DoNotClean = 'Do not clean',
  Clean = 'Clean',
  Prep = 'Prep',
  CleanAndPrep = 'Clean and Prep',
}

export interface AllowedActions {
  canRemoveHold?: boolean;
  canBeRejected?: boolean;
  canRequestHeelApproval?: boolean;
  canProvideMoreInfo?: boolean;
  canBeRejectedDueToHeel?: boolean;
}

export interface ReasonOfChangeOption {
  code: string;
  displayName: string;
}

export interface CustomerProductType {
  containerTypeId: string;
  containerTypeName: string;
  productId: string;
  productName: string;
  productCategoryName: string;
  containerCategoryName: string;
  customerProductType: string;
}

export enum OrderConversionStatuses {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  MANUAL_CONVERSION_SELECTED = 'MANUAL_CONVERSION_SELECTED',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum RequestWarningMessages {
  CREDIT_HOLD = 'COSTUMER CREDIT HOLD.',
  STEAM_SDS_FILE_MISSING = 'MISSING SDS FILE.',
  OUTSIDE_WORKING_HOURS = 'OUTSIDE_WORKING_HOURS.',
}

export const businessPartnerWithExteriorWashByDefault = [
  {
    name: 'Quest Liner Inc',
    id: '44092B54BCCA478F867612AA5C67AD48',
  },
];

export interface BusinessPartner {
  id: string;
  name: string;
  customerBlocking?: boolean;
  contmngPOFormat?: string;
  contmngPOFormathelp?: string;
  contmngCustomernotes?: string;
  contmngCustomernotesParsed?: string;
}

export interface ServiceType {
  id: string;
  name: string;
  obId: string;
  displayName: string;
  key: string;
  enabled: boolean;
}

export interface ContainerType {
  id: string;
  name: string;
  canBeFoodGrade: boolean;
}

export interface TankType {
  id: string;
  name: string;
}

export interface Compartment {
  compartment: number;
  size: number;
  uOMId: string;
}

export interface Tank {
  compartments: number;
  businessPartner: string;
  tankNumber: string;
  ownerId: string;
  ownerName: string;
  tankType: TankType;
  barCode?: string;
  sizes?: Compartment[];
}

export interface WashBillToTerminal {
  id: string;
  name: string;
  businessPartnerId: string;
  locationAddressId: string;
  locationAddressName: string;
}

export interface ProductContent {
  id: string;
  name: string;
}

interface FlareInfo {
  flareCompartments: string;
  flareRequired?: boolean;
}
export class SimplifiedServicePlan {
  planName: string;
  durationMinutes?: number;
  planID?: string;
  status?: string;

  constructor(json: any = {}) {
    this.planName = json.planName;
    this.durationMinutes = json.durationMinutes;
    this.planID = json.planId;
    this.status = json.status;
  }
}

export interface ServicePlanOption {
  planID: string;
  planName: string;
  planPrice: number;
  durationMinutes: number;
  productLines?: Array<ServicePlanProductLine>;
}

export interface ServicePlanProductLine {
  containerCategoryID: string;
  containerCategoryName: string;
  customerProductName: string;
  productCategoryID: string;
  productCategoryName: string;
  productID: string;
  productName: string;
  quantity: number;
}

export class ServicePlan {
  docType?: string;
  flareInfo?: FlareInfo;
  hazardousPlan?: string;
  highCostDisposal?: string;
  msg?: string;
  servicePlanDurationMinutes: number;
  servicePlanID?: string;
  servicePlanName: string;
  servicePlanOptions?: Array<ServicePlanOption>;
  serviceRequestID?: string;
  status?: string;
  productLines?: Array<ServicePlanProductLine>;

  constructor(json: any = {}) {
    this.docType = json.docType;
    this.hazardousPlan = json.hazardousPlan;
    this.highCostDisposal = json.highCostDisposal;
    this.msg = json.msg;
    this.servicePlanDurationMinutes = json.servicePlanDurationMinutes;
    this.servicePlanID = json.servicePlanID;
    this.servicePlanName = json.servicePlanName;
    this.serviceRequestID = json.serviceRequestID;
    this.status = json.status;
    this.productLines = json.productLines;
  }
}

export class SteamHeatData {
  type: string;
  temperatureUnit: string;
  maxTemperature: string;
  minTemperature: string;

  constructor(json: any = {}) {
    this.type = json.type;
    this.temperatureUnit = json.temperatureUnit;
    this.maxTemperature = json.maxTemperature;
    this.minTemperature = json.minTemperature;
  }
}

export class UOM {
  id: string;
  name: string;
  uOMType: string;
}

export enum ExteriorWashOfferStatuses {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export interface ExteriorWashOffer {
  id: string;
  washRequestId: string;
  expirationTime: number;
  destinationUserId: string;
  status: ExteriorWashOfferStatuses;
  notifiedUserName: string;
  notifiedUserEmail: string;
  acceptedInEmail?: boolean;
  acceptedById?: string;
  acceptTime?: number;
}

export class NotificationECTUsers {
  notifyOnChange: boolean;
  userId: string;
}

export class WashRequest {
  id: string;
  terminal?: string;
  terminalId?: string;
  customer?: string;
  createdByRole?: string;
  customerId?: string;
  containerOwner?: string;
  containerOwnerId?: string;
  completeTimeActionTime?: number;
  status?: WashRequestStatus;
  phoneNumber?: string;
  containerNumber?: string;
  containerId?: string;
  arrivalTime?: number;
  arrivalDate?: number;
  trailerArrivalDate?: number;
  trailerArrivalTime?: number;
  needbyDate?: number;
  needByTime: number;
  startTime?: number;
  startTimeActionTime?: number;
  serviceType?: string;
  serviceTypeId?: string;
  serviceTypeOntraxId?: string;
  foodGrade?: boolean;
  temperature?: string;
  kosher?: string;
  isHeelPreApproved?: boolean;
  specialInstructions?: string;
  containerType?: string;
  containerTypeId?: string;
  compartmentsQty?: number;
  lastContainedProductComp1Id?: string;
  lastContainedProductComp2Id?: string;
  lastContainedProductComp3Id?: string;
  lastContainedProductComp4Id?: string;
  lastContainedProductComp5Id?: string;
  lastContainedProductComp1Name?: string;
  lastContainedProductComp2Name?: string;
  lastContainedProductComp3Name?: string;
  lastContainedProductComp4Name?: string;
  lastContainedProductComp5Name?: string;
  containsNitrogenComp1?: boolean;
  containsNitrogenComp2?: boolean;
  containsNitrogenComp3?: boolean;
  containsNitrogenComp4?: boolean;
  containsNitrogenComp5?: boolean;
  serviceTypeComp1?: string;
  serviceTypeComp2?: string;
  serviceTypeComp3?: string;
  serviceTypeComp4?: string;
  serviceTypeComp5?: string;
  specialInstructionsComp1?: string;
  specialInstructionsComp2?: string;
  specialInstructionsComp3?: string;
  specialInstructionsComp4?: string;
  specialInstructionsComp5?: string;
  lastContainedProduct1Id?: string;
  lastContainedProduct2Id?: string;
  lastContainedProduct3Id?: string;
  lastContainedProduct1Name?: string;
  lastContainedProduct2Name?: string;
  lastContainedProduct3Name?: string;
  nitrogen?: boolean;
  tractorNumber?: string;
  hosesQty?: number;
  fittingsQty?: number;
  ladingBill?: string;
  poNumberForPrep?: string;
  poNumber?: string;
  washBillToTerminal?: string;
  washBillToTerminalId?: string;
  completeTime: number;
  containerArrived: boolean;
  specialPrep: boolean;
  containerPickedUp: boolean;
  pendingHeelApproval?: boolean;
  expectedCleaningStart?: number;
  files?: Array<UploadedFile>;
  exceptionType?: string;
  wasteType?: string;
  heelAmount?: string;
  heelCost?: string;
  laborHours?: string;
  laborCost?: string;
  steam?: string;
  steamCost?: string;
  isCod?: boolean;
  needMoreInfo?: boolean;
  createdByBulkUpload?: boolean;
  workOrderCreated?: boolean;
  openBravoSyncError?: boolean;
  openBravoSyncErrorMsg?: string;
  failedScheduling?: boolean;
  rejectionReason?: string;
  rejectionReasonCode?: string;
  expectedCompletionTime?: number;
  originalExpectedCompletionTime?: number;
  orderConversionStatus?: OrderConversionStatuses;
  orderConversionMessage?: string;
  servicePlan?: ServicePlan;
  steamHeatData?: SteamHeatData;
  recommendedServicePlan?: ServicePlan;
  wasServicePlanChanged?: boolean;
  reasonOfChange?: string;
  reasonOfChangeCode?: string;
  visualizedBy?: string;
  lastUpdateTime: number;
  acceptedByName?: string;
  lastUpdatedBy?: string;
  aluminumTankWarning: string;
  isAluminumTank: boolean;
  createTime: number;
  workOrder?: string;
  workOrderId?: string;
  washRequestIdOpenBravo?: string;
  holdRemoved?: boolean;
  customerComments?: string;
  exteriorWash?: boolean;
  exteriorWashOffer?: ExteriorWashOffer;
  flareRequired?: boolean;
  allowedActions?: AllowedActions;
  createdBy?: string;
  pumpQty?: number;
  preventOrderConversion?: boolean;
  arrivalTimestamp: number;
  needByTimestamp: number;
  createdByEtendo?: boolean;
  workPerformedBy: any;
  confinedEntry: boolean;
  confinedEntryType: string;
  confinedEntryOperatorName: string;
  workOrderStatus: string;
  enableFastWoConversion: boolean;
  schneiderCompletionStatus: SchneiderCompletionStatus;
  schneiderCompletionMessage: string;
  ectCustomerHasViewed?: boolean;
  ectCustomerNotificationUsers?: Array<NotificationECTUsers>;
  /**
   * Constructor from JSON data
   */
  constructor(json: any = {}) {
    this.id = json.id;
    this.terminal = json.terminal;
    this.customer = json.operatedBy;
    this.customerId = json.operatedById;
    this.createdByRole = json.createdByRole;
    this.containerOwner = json.tankOwner;
    this.containerOwnerId = json.tankOwnerId;
    this.status = json.status;
    this.phoneNumber = json.phoneNumber;
    this.containerNumber = json.tankNumber;
    this.containerId = json.tankId;
    this.arrivalTime = json.arrivalTime;
    this.arrivalDate = json.arrivalDate;
    this.trailerArrivalDate = json.trailerArrivalDate;
    this.trailerArrivalTime = json.trailerArrivalTime;
    this.needbyDate = json.needByDate;
    this.needByTime = json.needByTime;
    this.serviceType = json.serviceType;
    this.serviceTypeId = json.serviceTypeId;
    this.serviceTypeOntraxId = json.serviceTypeOntraxId;
    this.foodGrade = json.foodGrade;
    this.temperature = json.temperature;
    this.kosher = json.kosher;
    this.isHeelPreApproved = json.isHeelPreApproved;
    this.specialInstructions = json.specialInstructions;
    this.containerType = json.tankType;
    this.containerTypeId = json.tankTypeId;
    this.compartmentsQty = json.compartmentsQty;
    this.lastContainedProductComp1Id = json.lastContainedProductComp1Id;
    this.lastContainedProductComp2Id = json.lastContainedProductComp2Id;
    this.lastContainedProductComp3Id = json.lastContainedProductComp3Id;
    this.lastContainedProductComp4Id = json.lastContainedProductComp4Id;
    this.lastContainedProductComp5Id = json.lastContainedProductComp5Id;
    this.lastContainedProductComp1Name = json.lastContainedProductComp1Name;
    this.lastContainedProductComp2Name = json.lastContainedProductComp2Name;
    this.lastContainedProductComp3Name = json.lastContainedProductComp3Name;
    this.lastContainedProductComp4Name = json.lastContainedProductComp4Name;
    this.lastContainedProductComp5Name = json.lastContainedProductComp5Name;
    this.containsNitrogenComp1 = json.containsNitrogenComp1;
    this.containsNitrogenComp2 = json.containsNitrogenComp2;
    this.containsNitrogenComp3 = json.containsNitrogenComp3;
    this.containsNitrogenComp4 = json.containsNitrogenComp4;
    this.containsNitrogenComp5 = json.containsNitrogenComp5;
    this.serviceTypeComp1 = json.serviceTypeComp1;
    this.serviceTypeComp2 = json.serviceTypeComp2;
    this.serviceTypeComp3 = json.serviceTypeComp3;
    this.serviceTypeComp4 = json.serviceTypeComp4;
    this.serviceTypeComp5 = json.serviceTypeComp5;
    this.specialInstructionsComp1 = json.specialInstructionsComp1;
    this.specialInstructionsComp2 = json.specialInstructionsComp2;
    this.specialInstructionsComp3 = json.specialInstructionsComp3;
    this.specialInstructionsComp4 = json.specialInstructionsComp4;
    this.specialInstructionsComp5 = json.specialInstructionsComp5;
    this.lastContainedProduct1Id = json.lastContainedProduct1Id;
    this.lastContainedProduct2Id = json.lastContainedProduct2Id;
    this.lastContainedProduct3Id = json.lastContainedProduct3Id;
    this.lastContainedProduct1Name = json.lastContainedProduct1Name;
    this.lastContainedProduct2Name = json.lastContainedProduct2Name;
    this.lastContainedProduct3Name = json.lastContainedProduct3Name;
    this.nitrogen = json.nitrogen;
    this.tractorNumber = json.tractorNumber;
    this.hosesQty = json.hosesQty;
    this.fittingsQty = json.fittingsQty;
    this.ladingBill = json.ladingBill;
    this.poNumber = json.poNumber;
    this.poNumberForPrep = json.poNumberForPrep;
    this.washBillToTerminal = json.washBillToTerminal;
    this.washBillToTerminalId = json.washBillToTerminalId;
    this.completeTime = json.completeTime;
    this.completeTimeActionTime = json.completeTimeActionTime;
    this.containerArrived = json.containerArrived;
    this.specialPrep = json.specialPrep;
    this.containerPickedUp = json.containerPickedUp;
    this.pendingHeelApproval = json.pendingHeelApproval;
    this.expectedCleaningStart = json.expectedCleaningStart;
    this.files = json.files || [];
    this.exceptionType = json.exceptionType;
    this.wasteType = json.wasteType;
    this.heelAmount = json.heelAmount;
    this.heelCost = json.heelCost;
    this.laborHours = json.laborHours;
    this.laborCost = json.laborCost;
    this.startTime = json.startTime;
    this.steam = json.steam;
    this.steamCost = json.steamCost;
    this.isCod = json.isCod;
    this.needMoreInfo = json.needMoreInfo;
    this.createdByBulkUpload = json.createdByBulkUpload;
    this.terminalId = json.terminalId;
    this.workOrderCreated = json.workOrderCreated;
    this.openBravoSyncError = json.openBravoSyncError;
    this.openBravoSyncErrorMsg = json.openBravoSyncErrorMsg;
    this.failedScheduling = json.failedScheduling;
    this.rejectionReason = json.rejectionReason;
    this.rejectionReasonCode = json.rejectionReasonCode;
    this.expectedCompletionTime = json.expectedCompletionTime;
    this.originalExpectedCompletionTime = json.originalExpectedCompletionTime;
    this.orderConversionStatus = json.orderConversionStatus;
    this.orderConversionMessage = json.orderConversionMessage;
    this.startTimeActionTime = json.startTimeActionTime;
    this.servicePlan = json.servicePlan && new ServicePlan(json.servicePlan);
    this.steamHeatData =
      json.steamHeatData && new SteamHeatData(json.steamHeatData);
    this.recommendedServicePlan =
      json.recommendedServicePlan &&
      new ServicePlan(json.recommendedServicePlan);
    this.wasServicePlanChanged = json.wasServicePlanChanged;
    this.reasonOfChange = json.reasonOfChange;
    this.reasonOfChangeCode = json.reasonOfChangeCode;
    this.visualizedBy = json.visualizedBy;
    this.lastUpdateTime = json.lastUpdateTime;
    this.acceptedByName = json.acceptedByName;
    this.lastUpdatedBy = json.lastUpdatedBy;
    this.aluminumTankWarning = 'Tank Type Aluminum, do not use caustic.';
    this.isAluminumTank = this.containerType === 'Tank - Aluminum';
    this.createTime = json.createTime;
    this.workOrder = json.workOrder;
    this.washRequestIdOpenBravo = json.washRequestIdOpenBravo;
    this.holdRemoved = json.holdRemoved;
    this.customerComments = json.customerComments;
    this.exteriorWash = json.exteriorWash;
    this.exteriorWashOffer = json.exteriorWashOffer;
    this.flareRequired = json.flareRequired;
    this.allowedActions = json.allowedActions;
    this.createdBy = json.createdBy;
    this.pumpQty = json.pumpQty;
    this.createdByEtendo = json.createdByEtendo;
    this.workPerformedBy = json.workPerformedBy;
    this.confinedEntry = json.confinedEntry;
    this.confinedEntryType = json.confinedEntryType;
    this.workOrderStatus = json.workOrderStatus;
    this.workOrderId = json.workOrderId;
    this.schneiderCompletionStatus = json.schneiderCompletionStatus;
    this.ectCustomerHasViewed = json.ectCustomerHasViewed;
    this.ectCustomerNotificationUsers = json.ectCustomerNotificationUsers;
  }

  get hasServiceRequestData(): boolean {
    const hasProduct = this.foodGrade
      ? this.lastContainedProduct1Id ||
        this.lastContainedProduct2Id ||
        this.lastContainedProduct3Id
      : this.lastContainedProductComp1Id ||
        this.lastContainedProductComp2Id ||
        this.lastContainedProductComp3Id ||
        this.lastContainedProductComp4Id ||
        this.lastContainedProductComp5Id;

    return (
      !!this.terminalId &&
      !!this.customerId &&
      !!this.serviceTypeId &&
      !!this.containerId &&
      !!hasProduct
    );
  }
}
