import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ellipsis-loader',
  templateUrl: './ellipsis-loader.component.html',
  styleUrls: ['./ellipsis-loader.component.scss'],
})
export class EllipsisLoaderComponent implements OnInit {
  @Input() ellipsisStyle: string;

  constructor() {}

  ngOnInit(): void {}
}
