import {
  Directive,
  HostListener,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { PopupService } from './stack-popup.service';

@Directive({
  selector: '[appCloseByEsc]',
})
export class CloseByEscDirective implements OnInit, OnDestroy {
  @Output() escKey = new EventEmitter<void>();

  constructor(
    private el: ElementRef,
    private popupService: PopupService
  ) {}

  ngOnInit() {
    this.popupService.register(this.el.nativeElement);
  }

  ngOnDestroy() {
    this.popupService.unregister(this.el.nativeElement);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.key === 'Escape' &&
      this.popupService.getTopPopup() === this.el.nativeElement
    ) {
      this.escKey.emit();
    }
  }
}
