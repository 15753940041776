// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.convert-to-clean-and-prep-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}

.create-new-prep-wash-request-container {
  margin-left: 20px;
  max-width: 300px;
}

.wash-convert-component > .modal-title-container {
  border-bottom: solid 1px #c9c9c9;
  padding-bottom: 15px;
}
.wash-convert-component > .modal-title-container > .modal-title {
  margin: 0;
}
.wash-convert-component > .modal-body-container {
  max-width: 750px;
  margin: 15px 0;
}
.wash-convert-component > .po-number-container {
  max-width: 300px;
}
.wash-convert-component > .error-message-container {
  margin: 10px 0;
}
.wash-convert-component > .error-message-container > .error-message {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.26px;
  color: #d65600;
  margin: 0;
}
.wash-convert-component > .actions {
  display: flex;
  background-color: black;
  border-radius: 12px;
  padding: 8px;
  justify-content: flex-end;
  margin-top: 15px;
}
.wash-convert-component > .actions .action-button {
  width: initial;
}
.wash-convert-component > .actions .action-button:first-child {
  margin-right: 8px;
}
.wash-convert-component > .actions .action-button:disabled {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/schedule/wash-convert-component/wash-convert.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,qBAAA;OAAA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAGE;EACE,gCAAA;EACA,oBAAA;AAAJ;AACI;EACE,SAAA;AACN;AAEE;EACE,gBAAA;EACA,cAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AAEE;EACE,cAAA;AAAJ;AACI;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;EACA,SAAA;AACN;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAGI;EACE,iBAAA;AADN;AAII;EACE,oBAAA;AAFN","sourcesContent":[".convert-to-clean-and-prep-container {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 2rem;\n}\n\n.create-new-prep-wash-request-container {\n  margin-left: 20px;\n  max-width: 300px;\n}\n\n.wash-convert-component {\n  > .modal-title-container {\n    border-bottom: solid 1px #c9c9c9;\n    padding-bottom: 15px;\n    > .modal-title {\n      margin: 0;\n    }\n  }\n  > .modal-body-container {\n    max-width: 750px;\n    margin: 15px 0;\n  }\n  > .po-number-container {\n    max-width: 300px;\n  }\n  > .error-message-container {\n    margin: 10px 0;\n    > .error-message {\n      font-family: Muli;\n      font-size: 12px;\n      font-weight: bold;\n      font-stretch: normal;\n      font-style: normal;\n      line-height: 1.5;\n      letter-spacing: -0.26px;\n      color: #d65600;\n      margin: 0;\n    }\n  }\n  > .actions {\n    display: flex;\n    background-color: black;\n    border-radius: 12px;\n    padding: 8px;\n    justify-content: flex-end;\n    margin-top: 15px;\n\n    .action-button {\n      width: initial;\n    }\n\n    .action-button:first-child {\n      margin-right: 8px;\n    }\n\n    .action-button:disabled {\n      pointer-events: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
