import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { MenuConfig, OptionConfig, SectionConfig } from '../menu.model';
import { MenuService } from '../menu.service';
import { Router } from '@angular/router';
import { MobileSubMenuComponent } from './mobile-sub-menu/mobile-sub-menu.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-mobile-menu',
  standalone: true,
  imports: [MobileSubMenuComponent, CommonModule],
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss',
})
export class MobileMenuComponent implements OnInit {
  @Input() menuConfig: MenuConfig;
  @Input() isVisible: boolean;
  currentSubSystemSection: SectionConfig | OptionConfig | undefined;

  constructor(
    private menuService: MenuService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentSubSystemSection = this.menuService.getMenuSectionThroughUrl(
      this.menuConfig
    );
    this.router.events.subscribe(() => {
      this.currentSubSystemSection = this.menuService.getMenuSectionThroughUrl(
        this.menuConfig
      );
    });
  }
}
