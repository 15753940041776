export enum QueryType {
  phrase = 'phrase',
  phrasePrefix = 'phrase_prefix',
  boolPrefix = 'bool_prefix',
  bestFields = 'best_fields',
}

export enum QueryName {
  queryString = 'query_string',
  simpleQueryString = 'simple_query_string',
}

export class Query {
  searchDefinition: SearchDefinition[];
  searchShouldDefinition: SearchShouldDefinition;
  queryType: string;
  mustNotExistField: string;
  range: SearchRange;
  mustNotExistQuery: MustNotExistDefinition;

  constructor(
    searchDefinition: SearchDefinition[],
    queryType?: string,
    mustNotExistField?: string,
    range?: SearchRange,
    searchShouldDefinition?: SearchShouldDefinition,
    mustNotExistQuery?: MustNotExistDefinition
  ) {
    this.searchDefinition = searchDefinition;
    this.queryType = queryType;
    this.mustNotExistField = mustNotExistField;
    this.range = range;
    this.searchShouldDefinition = searchShouldDefinition;
    this.mustNotExistQuery = mustNotExistQuery;
  }
}

function specialCharacterHandler(text: string = '') {
  const hasSpecialCharacter = new RegExp(
    /[\+\-\=\>\<\!\(\)\{\}\[\]\^\~\*\?\:\\]/g
  ).test(text);
  if (!hasSpecialCharacter) {
    return text;
  }
  return `\"${text}\"`;
}

export class Filter {
  fields: string[];
  text: string;
  type: QueryType;
  queryName: QueryName;

  constructor(
    fields: string[] = [],
    text: string,
    type?: QueryType,
    queryName: QueryName = QueryName.queryString,
    useWildCard?: boolean
  ) {
    this.fields = fields;
    if (text) {
      this.text = useWildCard
        ? `${specialCharacterHandler(text)}*`
        : specialCharacterHandler(text);
    }
    this.type = type;
    this.queryName = queryName;
  }
}

export class SearchDefinition {
  field: string;
  values: string[];
  operator: string;
  type: string;
  constructor(field, values, operator?, type?) {
    this.field = field;
    this.values = values;
    this.operator = operator;
    this.type = type;
  }
}

export class MustNotExistDefinition {
  field: string;
  value: string;
  type: string;
  constructor(field, value, type) {
    this.field = field;
    this.value = value;
    this.type = type;
  }
}

export class SearchShouldDefinition {
  should: object[];
  // tslint:disable-next-line:variable-name
  minimum_should_match: number;
  constructor(shouldQuery: object[], minimumShouldMatch: number) {
    this.should = shouldQuery;
    this.minimum_should_match = minimumShouldMatch;
  }
}

export class RangeValue {
  rangeType: string;
  rangeTime: number;

  constructor(rangeType: string, rangeTime: number) {
    this.rangeType = rangeType;
    this.rangeTime = rangeTime;
  }
}

export class SearchRange {
  rangeField: string;
  rangeValues: RangeValue[];

  constructor(rangeField: string, rangeValues: RangeValue[]) {
    this.rangeField = rangeField;
    this.rangeValues = rangeValues;
  }
}

export class Sort {
  field: string;
  order: string;
  constructor(field, order) {
    this.field = field;
    this.order = order;
  }
}

export class ElasticSearch {
  from: number;
  onlyMine: boolean;
  filterValue: string;
  sort: Sort;
  constructor(from: number, onlyMine, filterValue: string, sort: Sort) {
    this.from = from;
    this.onlyMine = onlyMine;
    this.filterValue = filterValue;
    this.sort = sort;
  }
}

export class TankElasticSearch {
  from: number;
  filterValue: string;
  businessParterId: string;
  queryFromAllBusinessPartners: boolean;
  sort?: Sort;
  constructor(
    from = 0,
    filterValue,
    businessParterId,
    queryFromAllBusinessPartners,
    sort?
  ) {
    this.from = from;
    this.filterValue = filterValue;
    this.businessParterId = businessParterId;
    this.queryFromAllBusinessPartners = queryFromAllBusinessPartners;
    this.sort = sort;
  }
}
