// tslint:disable: no-string-literal
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import Storage from '@aws-amplify/storage';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UploadedFile } from './file-upload.model';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/core/localstorage.service';
import { v1 } from 'uuid';
import { LoadingDialogService } from '../loading-dialog.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileUploader', { static: false }) fileUploader: ElementRef;

  @Input() label = 'Upload Files';
  @Input() fileList: Array<UploadedFile>;
  @Input() accept: string;
  @Input() maxFileSize: number;
  @Input() maxFileQty: number;
  @Input() downloadOnly: boolean;
  @Input() customFileFlag: string;
  @Input() isDisabled: boolean;

  isUploading = false;
  errorMessage: string;
  filesQty: number;
  isUnauthenticated: boolean;

  constructor(
    private localStorage: LocalStorageService,
    private authService: AuthService,
    private loadingDialogService: LoadingDialogService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.countFiles();
  }

  get shouldShowUploadBtn(): boolean {
    if (!this.maxFileQty) {
      return true;
    }
    return (
      (this.maxFileQty && this.filesQty < this.maxFileQty) || !this.maxFileQty
    );
  }

  get shouldDisplayFileList(): boolean {
    let display = false;
    if (this.fileList.length === 0) {
      return false;
    } else if (this.customFileFlag) {
      this.fileList.forEach((file) => {
        if (file['customFileFlag'] === this.customFileFlag) {
          display = true;
          return;
        }
      });
      return display;
    } else {
      this.fileList.forEach((file) => {
        if (!file['customFileFlag']) {
          display = true;
          return;
        }
      });
      return display;
    }
  }

  private resetFileUploader() {
    this.fileUploader.nativeElement.value = null;
    this.isUploading = false;
  }

  async handleFileInput(event) {
    this.loadingDialogService.openDialog('Uploading file...');

    try {
      if (
        this.maxFileSize &&
        event.target.files.item(0).size > this.maxFileSize
      ) {
        this.errorMessage = 'File size exceeds allowed size.';
        return;
      }
      this.isUploading = true;
      this.errorMessage = '';

      const fileToUpload = event.target.files.item(0);
      const fileIdName = v1();

      const user = this.authService.user
        ? this.authService.user.username
        : 'unauthenticated';

      const file = {
        originalName: fileToUpload.name,
        user,
        uploadTimestamp: Math.round(Date.now() / 1000),
        extension: fileToUpload.name.split('.')[1],
        type: fileToUpload.type,
        id: fileIdName,
      };

      if (this.customFileFlag) {
        file['customFileFlag'] = this.customFileFlag;
      }

      if (this.validContentType(fileToUpload.type)) {
        await Storage.put(fileIdName, fileToUpload, {
          contentType: fileToUpload.type,
        });

        if (user === 'unauthenticated') {
          this.isUnauthenticated = true;
          this.localStorage.emptyLocalStorage(); // clear identity pool id from local storage
        }

        this.fileList.push(file);
      } else {
        this.toastr.error('', 'Invalid file!');
      }

      this.resetFileUploader();
      this.countFiles();
      this.loadingDialogService.closeDialog();
    } catch (err) {
      this.loadingDialogService.closeDialog();
      throw err;
    }
  }

  validContentType(contentType): boolean {
    const types = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'mp4',
      'mov',
      'wmv',
      'pdf',
      'doc',
      'txt',
    ];
    const result = types.some((substring) => {
      return contentType.includes(substring);
    });
    return result;
  }

  deleteItem(index) {
    this.fileList.splice(index, 1);
    this.countFiles();
  }

  countFiles() {
    this.filesQty = 0;
    if (this.customFileFlag && this.fileList.length > 0) {
      this.fileList.forEach((file) => {
        if (file['customFileFlag'] === this.customFileFlag) {
          this.filesQty = ++this.filesQty;
        }
      });
    } else if (!this.customFileFlag && this.fileList.length > 0) {
      this.fileList.forEach((file) => {
        if (!file['customFileFlag']) {
          this.filesQty = ++this.filesQty;
        }
      });
    }
  }
}
