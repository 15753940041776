import { Component, Input, OnInit } from '@angular/core';
import { LastContainedFields } from '../wash-item/wash-item.model';

@Component({
  selector: 'app-wash-revision',
  templateUrl: './wash-revision.component.html',
  styleUrls: ['./wash-revision.component.scss'],
})
export class WashRevisionComponent implements OnInit {
  @Input() washRevisionItems: any;

  lastContainedFields = LastContainedFields;

  constructor() {}

  ngOnInit(): void {}
}
