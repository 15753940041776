import {
  Component,
  OnInit,
  forwardRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => TimePickerComponent),
  multi: true,
};

@Component({
  selector: 'app-time-picker',
  providers: [CUSTOM_VALUE_ACCESSOR],
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  private onChange: (_: any) => any;
  private onTouched: () => any;

  @Input() disabled: boolean;
  @Output() changedValue = new EventEmitter<any>();
  @Input() initialValue: number;
  @Input() disabledTooltip: string;

  value: Date;

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  ngOnInit() {
    if (this.initialValue !== undefined) {
      this.writeValue(this.initialValue);
    }
  }

  changed(data) {
    const selectedTime = new Date(
      Math.round(new Date(data.value.valueOf()).setSeconds(0, 0))
    );
    const timeConverter =
      (selectedTime.getUTCHours() + selectedTime.getUTCMinutes() / 60) * 60;

    this.onChange(timeConverter);
    this.onTouched();
    this.changedValue.emit(timeConverter);
  }

  writeValue(value: number): void {
    const date = new Date();
    date.setUTCHours(Math.floor(value / 60));
    date.setMinutes(value % 60);

    this.value = date;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
