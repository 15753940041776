import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NonConformityReportModule } from '../dashboard/non-conformity-report/non-conformity-report.module';
import { ExternalNcrCreationComponent } from './external-ncr-creation.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ExternalNcrCreationComponent],
  imports: [RouterModule, SharedModule, FormsModule, NonConformityReportModule],
})
export class ExternalNcrCreationModule {}
