import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileItemService } from '../../file-upload/file-item/file-item.service';
import { UploadedFile } from '../../file-upload/file-upload.model';

@Component({
  selector: 'app-file-item-thumb',
  templateUrl: './file-item-thumb.component.html',
  styleUrls: ['./file-item-thumb.component.scss'],
})
export class FileItemThumbComponent implements OnInit {
  filePath: string;
  imageLoading = true;

  @Input() item: UploadedFile;
  @Output() deleted = new EventEmitter();
  @Input() downloadOnly: boolean;
  @Input() isUnauthenticated: boolean;

  constructor(private fileItemService: FileItemService) {}

  ngOnInit() {
    if (this.item) {
      this.downloadFile(this.item.id);
    }
  }

  removeItemFromList() {
    this.deleted.emit(null);
  }

  onImageLoad(evt) {
    if (evt && evt.target) {
      this.imageLoading = false;
    }
  }

  async downloadFile(fileName: string) {
    if (!this.isUnauthenticated) {
      this.filePath = await this.fileItemService.getFileUrl(fileName);
    }
  }
}
