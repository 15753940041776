import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-wash-reject-with-heel',
  templateUrl: './wash-reject-with-heel.component.html',
  styleUrls: ['./wash-reject-with-heel.component.scss'],
})
export class WashRejectWithHeelComponent {
  rejectForm = this.fb.group({
    heelAmount: [null, Validators.required],
  });

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<WashRejectWithHeelComponent>
  ) {}

  get code() {
    return 'HEELFOUND';
  }

  get displayName() {
    return 'Request Rejected - Heel Found';
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  submitDialog() {
    if (!this.rejectForm.valid) {
      return;
    }

    const heelAmount = this.rejectForm.get('heelAmount').value;
    const code = this.code;
    const label = this.displayName;
    this.dialogRef.close({ heelAmount, code, label });
  }
}
