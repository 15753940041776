export interface BusinessPartner {
  id: string;
  name: string;
  customerBlocking?: boolean;
  contmngPOFormat?: string;
  contmngPOFormathelp?: string;
  contmngCustomernotes?: string;
  contmngCustomernotesParsed?: string;
}

export interface BusinessPartnerNCR {
  id: string;
  name: string;
  onlyNCR: boolean;
}

export interface DataStore {
  businessPartners: BusinessPartner[];
  businessPartnersNCR: BusinessPartnerNCR[];
}

export enum SideMenuToggle {
  SIDE_MENU,
  SIDE_MENU_IS_VISIBLE,
}
