import { Shift, Bay, Card } from '../schedule.model';

export enum SlotStatuses {
  BLOCKED = 'blocked',
  EMPTY = 'empty',
  FULL = 'full',
  OUT_SHIFT = 'out-shift',
}
export interface Slot {
  bayId: string;
  hourLabel: string;
  position: number;
  availableTimeBag: number;
  previousPosition: number;
  startTime: number;
  finishTime: number;
  status: SlotStatuses;
}

export interface AgendaBay extends Bay {
  timeBag: number;
  cards: Card[];
  slots: Slot[];
  shift: Shift;
}

export interface AgendaShift extends Shift {
  dateLabel: string;
  bays: AgendaBay[];
}

export class Agenda {
  shifts: AgendaShift[];
}
