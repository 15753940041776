import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loading',
})
export class LoadingPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value === null || value === undefined ? 'LOADING' : value;
  }
}
