// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-ncr-container {
  margin: 16px;
  padding: 16px;
  border-radius: 16px;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/non-conformity-report/ncr-edit/ncr-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".edit-ncr-container {\n  margin: 16px;\n  padding: 16px;\n  border-radius: 16px;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
