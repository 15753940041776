import { Injectable } from '@angular/core';
import { ActionsControl } from './ncr-item.model';

@Injectable({
  providedIn: 'root',
})
export class NcrItemService {
  constructor() {}

  getAllowedActions(
    isQualaWorker: boolean,
    hasAllReportActions: boolean,
    currentMenuOption?: string
  ): ActionsControl {
    let controls;

    if (hasAllReportActions) {
      controls = this.getAllActionsControls(currentMenuOption);
    } else if (isQualaWorker) {
      controls = this.getQualaWorkerControls(currentMenuOption);
    } else {
      controls = this.getDispatcherControls(currentMenuOption);
    }

    const controlKeys = Object.keys(controls || {});
    const showActionBar =
      controlKeys.length > 0 &&
      Object.keys(controls)
        .filter((key) => key !== 'hideActionBar')
        .some((key) => controls[key]);

    controls.hideActionBar = !showActionBar;

    return controls;
  }

  private getAllActionsControls(ncrItemStatus: string): ActionsControl {
    switch (ncrItemStatus) {
      case 'opened': {
        return {
          canBeCanceled: true,
          canBeEdited: true,
          canBeAssigned: true,
        };
      }
      case 'in-analysis': {
        return {
          canBeCanceled: true,
          canBeEdited: true,
          canBeSendToApproval: true,
        };
      }
      case 'waiting-for-approval': {
        return {
          canBeCanceled: true,
          canBeEdited: true,
          canBeApproved: true,
          canBeResolutionToBeDetermined: true,
          canReturnToAnalysis: true,
        };
      }
      case 'completed': {
        return {
          canBeReviewed: true,
        };
      }
      default: {
        return {
          hideActionBar: true,
        };
      }
    }
  }

  private getQualaWorkerControls(ncrItemStatus: string): ActionsControl {
    switch (ncrItemStatus) {
      case 'in-analysis': {
        return {
          canBeCanceled: true,
          canBeEdited: true,
          canBeSendToApproval: true,
        };
      }
      default: {
        return {
          hideActionBar: true,
        };
      }
    }
  }

  private getDispatcherControls(ncrItemStatus: string): ActionsControl {
    switch (ncrItemStatus) {
      case 'opened': {
        return {
          canBeCanceled: true,
          canBeEdited: true,
        };
      }
      default: {
        return {
          hideActionBar: true,
        };
      }
    }
  }
}
