export enum ItemClassByNewsType {
  Default = 'default-news-item',
  Notification = 'notification-news-item',
  ExpiringNotification = 'expiring-notification-news-item',
}

export enum AuthorNameByNewsType {
  Default = 'OnTrax News',
  Notification = 'OnTrax Notifications',
  ExpiringNotification = 'OnTrax Notifications',
}
