import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true,
};

@Component({
  selector: 'app-multi-select',
  providers: [CUSTOM_VALUE_ACCESSOR],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() dropdownList: {};
  @Input() dropdownSettings: [];

  selectedItems = [];

  private onChange: (_: any) => any;
  private onTouched: () => any;

  writeValue(value: any[]): void {
    this.selectedItems = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(_: any): void {}

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  ngOnInit() {}

  onSelect(event) {
    this.onChange(this.selectedItems);
  }

  onSelectAll(items) {
    this.selectedItems = items;
    this.onChange(this.selectedItems);
  }

  onDeSelect(event) {
    this.onChange(this.selectedItems);
  }

  onDeSelectAll(items) {
    this.selectedItems = items;
    this.onChange(this.selectedItems);
  }
}
