export const typeOfHeat = [
  {
    label: 'Low Pressure Steam (<= 30 PSI)',
    value: 'Low Pressure',
  },
  {
    label: 'High Pressure Steam (Maximum Pressure of 50 PSI)',
    value: 'High Pressure',
  },
  {
    label: 'Hot Water',
    value: 'Hot Water',
  },
  {
    label: 'Not Applicable - Null',
    value: null,
  },
];

export const temperatureUnits = [
  {
    value: 'Fahrenheit',
    label: 'Fahrenheit',
    symbol: '°F',
  },
  {
    value: 'Celsius',
    label: 'Celsius',
    symbol: '°C',
  },
];
