import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../core/localstorage.service';

@Component({
  selector: 'app-system-maintenance',
  templateUrl: './system-maintenance.component.html',
  styleUrls: ['./system-maintenance.component.scss'],
})
export class SystemMaintenanceComponent implements OnInit {
  static route = 'system-maintenance';

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.localStorageService.emptyLocalStorage();
  }

  redirect() {
    window.location.assign('/dashboard');
  }
}
