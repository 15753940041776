import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Hotjar from '@hotjar/browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const { hotjar: hotjarConfig } = environment;

if (hotjarConfig) {
  Hotjar.init(hotjarConfig.siteId, hotjarConfig.version);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
