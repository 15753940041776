import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  constructor(private authService: AuthService) {}

  getNowAsUTC() {
    return this.getTimestampInSecondsAsUTC(Date.now() / 1000);
  }

  getTimestampInSecondsAsUTC(timestampInSeconds: number) {
    const timezoneOffsetInSeconds = this.getTimezoneOffsetInSeconds();
    return Math.round(timestampInSeconds) + timezoneOffsetInSeconds;
  }

  getTimestampInSecondsWithRestoredOffset(timestampInSeconds: number): number {
    const timezoneOffsetInSeconds = this.getTimezoneOffsetInSeconds();
    const timestamp = timestampInSeconds - timezoneOffsetInSeconds;
    return timestamp;
  }

  getDateAsUTC(lookupTime: number) {
    const timezoneOffsetInSeconds = this.getTimezoneOffsetInSeconds();
    const today = new Date();
    const date =
      Math.round(today.setDate(today.getDate() - lookupTime) / 1000) +
      timezoneOffsetInSeconds;
    return date;
  }

  getTimezoneOffsetInSeconds() {
    let timezoneOffsetInSeconds: number;
    const user = this.authService.getUser();

    if (user && user.currentTerminal && user.currentTerminal.timezone) {
      timezoneOffsetInSeconds = user.currentTerminal.timezone * 60 * 60;
    } else {
      timezoneOffsetInSeconds = -(new Date().getTimezoneOffset() * 60);
    }

    return timezoneOffsetInSeconds;
  }
}
