// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-item {
  display: grid;
  padding: 8px 15px;
  grid-template-columns: 3fr 1fr 2fr auto;
  -moz-column-gap: 14px;
       column-gap: 14px;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #464646;
}

.remove-item {
  text-align: right;
  color: #006937;
  cursor: pointer;
}

.upload-item-name {
  color: #004725;
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/file-upload/file-item/file-item.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,iBAAA;EACA,uCAAA;EACA,qBAAA;OAAA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cCgBS;ADjBX;;AAGA;EACE,iBAAA;EACA,cCGa;EDFb,eAAA;AAAF;;AAGA;EACE,cCDW;EDEX,0BAAA;EACA,eAAA;AAAF","sourcesContent":["@import '../../../../variables.scss';\n\n.upload-item {\n  display: grid;\n  padding: 8px 15px;\n  grid-template-columns: 3fr 1fr 2fr auto;\n  column-gap: 14px;\n  align-items: center;\n  font-size: 12px;\n  font-weight: bold;\n  color: $charcoal;\n}\n.remove-item {\n  text-align: right;\n  color: $jungle-green;\n  cursor: pointer;\n}\n\n.upload-item-name {\n  color: $deep-green;\n  text-decoration: underline;\n  cursor: pointer;\n}\n","// Brand Identity Colors\n$brand: (\n  'blue': #05347a,\n  'dark-grey': #53565a,\n  'black': #000000,\n  'white': #ffffff,\n  'green': #008751,\n  'light-green': #8cc63e,\n  'light-blue': #1b85ea,\n  'light-grey': #7e8283,\n  'light-grey-2': #e6e6e6,\n  'light-grey-3': #d4d4d4,\n  'light-grey-7': #a1a1a1,\n  'light-grey-9': #7d7d7d,\n);\n\n// Application Colors\n$jungle-green: #006937;\n$deep-green: #004725;\n$forest-green: #26a532;\n$light-green: #0047256e;\n$very-light-pink: #cdcdcd;\n$brown-grey: #929292;\n$deep-orange: #d65600;\n$red: #d60600;\n$blue: #3f71d3;\n$charcoal: #464646;\n$magnolia: #f5f4f7;\n$white: #fff;\n\n// Card\n$default-slot-width: 192px;\n$panel-mode-small-column-width: 160px;\n$panel-mode-medium-column-width: 192px;\n$panel-mode-large-column-width: 236px;\n$panel-mode-extra-large-column-width: 270px;\n$panel-mode-2-extra-large-column-width: 300px;\n$panel-mode-3-extra-large-column-width: 320px;\n\n@function brand-color($color) {\n  @return map-get($brand, $color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
