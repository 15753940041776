import { ChangeDetectorRef } from '@angular/core';
import {
  Component,
  Inject,
  OnInit,
  Optional,
  AfterViewChecked,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TimeService } from 'src/app/core/time.service';
import { DateTimeService } from 'src/app/shared/date-time.service';
import { StartStopConfirmationFieldsEnum } from './start-stop-confirmation-fields.enum';
import { ConfinedSpaceEntryDataUtils } from 'src/app/shared/confined-space-entry/confined-space-entry.data.utils';
import { Operator } from '../schedule.model';
import { MatOptionSelectionChange } from '@angular/material/core';

interface StartStopConfirmationFormFields {
  startDate: FormControl;
  startTime: FormControl;
  completeDate: FormControl;
  completeTime: FormControl;
  confinedEntry?: FormControl;
  confinedEntryOperatorName?: FormControl;
  confinedEntryType?: FormControl;
}

@Component({
  selector: 'app-start-stop-confirmation',
  templateUrl: './start-stop-confirmation.component.html',
  styleUrls: ['./start-stop-confirmation.component.scss'],
})
export class StartStopConfirmationComponent
  implements OnInit, AfterViewChecked
{
  static START_COMPLETE_TIMES_ERROR =
    'Complete Time should be greater than Start Time.';
  static COMPLETE_TIME_FUTURE_ERROR =
    'Complete Time should not be in the future.';

  formGroup: FormGroup;
  startDate: FormControl;
  startTime: FormControl;
  completeDate: FormControl;
  completeTime: FormControl;
  confinedEntry: FormControl;
  confinedEntryOperatorName: FormControl;
  confinedEntryType: FormControl;

  userTypedOperatorName: boolean = false;
  lastSelectedOperatorId: string;
  isDisabled: boolean = false;

  dateTimeValidators: any[] = [Validators.required];
  invalidStartCompleteTimes: boolean;
  completeTimeInTheFuture: boolean;
  isOptionCompleteOrExpired: boolean;

  uploadedFiles: Array<any> = [];
  formFieldsEnum = StartStopConfirmationFieldsEnum;
  operators: Operator[];
  workOrderStatus: string = '';

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<StartStopConfirmationComponent>,
    private timeService: TimeService,
    private dateTimeService: DateTimeService,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.showConfinedEntryForm) {
      this.operators = this.data.operators;
    }
  }

  ngAfterViewChecked() {
    // Try to remove this ngAfterViewChecked() after fix of https://github.com/angular/angular/issues/15634
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.isOptionCompleteOrExpired = this.isCompleteOrExpired(
      this.data.currentOption
    );
    // Reset the dates' seconds to guarantee consistency on hasChangedStartStopTimes.
    this.data.startTime =
      new Date(this.data.startTime * 1000).setSeconds(0) / 1000;
    this.data.stoppedAt =
      new Date(this.data.stoppedAt * 1000).setSeconds(0) / 1000;

    const [startDate, startTime] = this.dateTimeService.splitDateAndTime(
      this.data.startTime,
      true
    );
    const [completeDate, completeTime] = this.dateTimeService.splitDateAndTime(
      this.data.stoppedAt,
      true
    );

    const controls = {
      startDate,
      startTime,
      completeDate,
      completeTime,
    };

    this.invalidStartCompleteTimes = false;
    this.completeTimeInTheFuture = false;

    this.createFormControls(controls);
    this.createFormGroup();

    if (this.data.showConfinedEntryForm) {
      ConfinedSpaceEntryDataUtils.handleOperatorsInputList(this);
      ConfinedSpaceEntryDataUtils.handleConfinedEntryFormFields(this);
      ConfinedSpaceEntryDataUtils.handledConfinedEntryInitialData(this);
    }
  }

  isCompleteOrExpired(currentOption) {
    if (
      !currentOption ||
      (currentOption !== 'completed' && currentOption !== 'expired')
    ) {
      return false;
    }
    return true;
  }

  isCompleted(currentOption) {
    return currentOption === 'completed';
  }

  updateOperatorName(
    event: MatOptionSelectionChange,
    operatorName: string,
    operatorId: string
  ) {
    ConfinedSpaceEntryDataUtils.updateOperatorName(
      this,
      event,
      operatorName,
      operatorId
    );
  }

  save() {
    const startDate = this.startDate.value;
    const startTime = this.startTime.value;
    const completeDate = this.completeDate.value;
    const completeTime = this.completeTime.value;

    const cseData = this.data.showConfinedEntryForm
      ? ConfinedSpaceEntryDataUtils.compileCseDataToSave(this)
      : {};

    let startTimestamp =
      this.dateTimeService.getTimestampInSecondsFromDateAndTime(
        startDate,
        startTime
      );
    let completeTimestamp =
      this.dateTimeService.getTimestampInSecondsFromDateAndTime(
        completeDate,
        completeTime
      );

    const hasChangedStartStopTimes = this.hasChangedStartStopTimes(
      startTimestamp,
      completeTimestamp
    );

    // Restore the timestamps' offset so the date in Dynamo is real UTC.
    startTimestamp =
      this.timeService.getTimestampInSecondsWithRestoredOffset(startTimestamp);
    completeTimestamp =
      this.timeService.getTimestampInSecondsWithRestoredOffset(
        completeTimestamp
      );

    this.dialogRef.close({
      startTime: startTimestamp,
      completeTime: completeTimestamp,
      hasChangedStartStopTimes,
      ...cseData,
    });
  }

  cancel() {
    return this.dialogRef.close();
  }

  customErrors() {
    const errorMessages = [];

    if (this.formGroup.hasError('invalidStartCompleteTimes')) {
      errorMessages.push(
        StartStopConfirmationComponent.START_COMPLETE_TIMES_ERROR
      );
    }

    if (this.formGroup.hasError('completeTimeInTheFuture')) {
      errorMessages.push(
        StartStopConfirmationComponent.COMPLETE_TIME_FUTURE_ERROR
      );
    }

    return errorMessages;
  }

  startCompleteTimesValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      const startDate = this.startDate.value;
      const startTime = this.startTime.value;
      const completeDate = this.completeDate.value;
      const completeTime = this.completeTime.value;

      const startTimestamp =
        this.dateTimeService.getTimestampInSecondsFromDateAndTime(
          startDate,
          startTime
        );
      const completeTimestamp =
        this.dateTimeService.getTimestampInSecondsFromDateAndTime(
          completeDate,
          completeTime
        );

      let errors = {};

      if (
        startTimestamp &&
        completeTimestamp &&
        startTimestamp >= completeTimestamp
      ) {
        this.invalidStartCompleteTimes = true;
        errors = {
          ...errors,
          invalidStartCompleteTimes: this.invalidStartCompleteTimes,
        };
      }

      if (
        completeTimestamp &&
        completeTimestamp > this.timeService.getNowAsUTC()
      ) {
        this.completeTimeInTheFuture = true;
        this.invalidStartCompleteTimes = true;
        errors = {
          ...errors,
          completeTimeInTheFuture: this.completeTimeInTheFuture,
        };
      }

      if (Object.keys(errors).length > 0) {
        return errors;
      }

      this.invalidStartCompleteTimes = false;
      this.completeTimeInTheFuture = false;
      return null;
    };
  }

  private hasChangedStartStopTimes(
    startTime: number,
    completeTime: number
  ): boolean {
    return (
      startTime !== this.data.startTime || completeTime !== this.data.stoppedAt
    );
  }

  private createFormControls(controls) {
    this.startDate = new FormControl(controls.startDate, Validators.required);
    this.startTime = new FormControl(controls.startTime, Validators.required);
    this.completeDate = new FormControl(
      controls.completeDate,
      Validators.required
    );
    this.completeTime = new FormControl(
      controls.completeTime,
      Validators.required
    );
  }

  private createFormGroup() {
    let formGroupFields: StartStopConfirmationFormFields = {
      startDate: this.startDate,
      startTime: this.startTime,
      completeDate: this.completeDate,
      completeTime: this.completeTime,
    };

    if (this.data.showConfinedEntryForm) {
      const cseFormControls =
        ConfinedSpaceEntryDataUtils.createCseFormControls(this);

      formGroupFields = {
        ...formGroupFields,
        ...cseFormControls,
      };
    }

    this.formGroup = this.formBuilder.group(formGroupFields);
    this.formGroup.setValidators([this.startCompleteTimesValidator()]);
  }
}
