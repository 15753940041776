import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { OfferStatus } from './exterior-wash-offer-response.model';

@Injectable({
  providedIn: 'root',
})
export class ExteriorWashOfferResponseService {
  private defaultHeaders: Record<string, string>;
  constructor() {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };
  }

  exteriorWashResponse(token: string, offerResponseStatus: OfferStatus) {
    const path = `/email-response`;
    const httpOption = {
      headers: this.defaultHeaders,
      body: {
        token,
        offerResponseStatus,
      },
    };
    return API.put('ExteriorWashOfferAPI', path, httpOption);
  }
}
