import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-label-input',
  templateUrl: './label-input.component.html',
  styleUrls: ['./label-input.component.scss'],
})
export class LabelInputComponent implements OnInit {
  @Input() label: string;
  @Input() warning: boolean;
  @Input() tip: string;
  @Input() displayResultSize: number;
  @Input() totalResultSize: number;
  @Input() showSearchResultsHelperText: boolean;
  @Input() contentClass: string;
  @Input() labelDetails: string;
  @Input() warningDetails: boolean;

  get helperText() {
    if (!this.showSearchResultsHelperText) {
      return;
    }
    if (this.displayResultSize >= this.totalResultSize) {
      return;
    }

    return `Showing the top ${this.displayResultSize} items that matched this criteria. Add more criteria to refine your search.`;
  }

  constructor() {}

  ngOnInit() {}
}
