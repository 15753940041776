// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-title {
  margin-top: 25px;
  border-top: solid 1px #c9c9c9;
  padding-top: 24px;
}

.investigation-form {
  display: grid;
  grid-template-rows: auto auto auto auto auto auto;
  row-gap: 16px;
}
.investigation-form .limited-text-area {
  max-height: 300px;
  min-height: 50px;
}

.disclaimer {
  color: red;
  font-style: italic;
  font-size: 11px;
}

.action-group {
  padding: 5px;
  margin: 0 5px;
  border-top: solid 1px #c9c9c9;
  grid-column: 1/2;
}
.action-group .block-input {
  pointer-events: none;
}
.action-group .textarea-block-input {
  font-weight: bold;
  background: rgba(149, 156, 161, 0.5490196078);
}
.action-group .input-group {
  display: grid;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/non-conformity-report/ncr-master-form/investigation-form-section/investigation-form-section.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,6BAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,iDAAA;EACA,aAAA;AACF;AACE;EACE,iBAAA;EACA,gBAAA;AACJ;;AAGA;EACE,UAAA;EACA,kBAAA;EACA,eAAA;AAAF;;AAGA;EACE,YAAA;EACA,aAAA;EACA,6BAAA;EACA,gBAAA;AAAF;AAEE;EACE,oBAAA;AAAJ;AAEE;EACE,iBAAA;EACA,6CAAA;AAAJ;AAGE;EACE,aAAA;EACA,eAAA;AADJ","sourcesContent":[".section-title {\n  margin-top: 25px;\n  border-top: solid 1px #c9c9c9;\n  padding-top: 24px;\n}\n\n.investigation-form {\n  display: grid;\n  grid-template-rows: auto auto auto auto auto auto;\n  row-gap: 16px;\n\n  .limited-text-area {\n    max-height: 300px;\n    min-height: 50px;\n  }\n}\n\n.disclaimer {\n  color: red;\n  font-style: italic;\n  font-size: 11px;\n}\n\n.action-group {\n  padding: 5px;\n  margin: 0 5px;\n  border-top: solid 1px #c9c9c9;\n  grid-column: 1 / 2;\n\n  .block-input {\n    pointer-events: none;\n  }\n  .textarea-block-input {\n    font-weight: bold;\n    background: #959ca18c;\n  }\n\n  .input-group {\n    display: grid;\n    margin-top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
