import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UploadedFile } from '../file-upload.model';
import { FileItemService } from './file-item.service';

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss'],
})
export class FileItemComponent implements OnInit {
  @Input() item: UploadedFile;
  @Output() deleted = new EventEmitter();
  @Input() downloadOnly: boolean;
  @Input() isUnauthenticated: boolean;

  constructor(private fileItemService: FileItemService) {}

  ngOnInit() {}

  removeItemFromList() {
    this.deleted.emit(null);
  }

  downloadFile(fileName, originalName) {
    if (!this.isUnauthenticated) {
      this.fileItemService.downloadFileService(fileName, originalName);
    }
  }
}
