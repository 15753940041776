import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingDialogService {
  constructor(private dialog: MatDialog) {}
  dialogRef;

  openDialog(text) {
    this.dialogRef = this.dialog.open(LoadingDialogComponent, {
      data: { text: text },
      disableClose: true,
    });
  }

  closeDialog() {
    if (this.dialog.openDialogs?.length) {
      this.dialogRef.close();
    }
  }
}
