import { Component, Input, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-images-carousel',
  templateUrl: './expanded-image.component.html',
  styleUrls: ['./expanded-image.component.scss'],
})
export class ExpandedImageComponent implements OnInit {
  @Input() images: Array<string>;

  constructor(
    public dialogRef: MatDialogRef<ExpandedImageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
}
