export class BusinessPartnerNotificationPreferences {
  businessPartnerId: string;
  bulkUploadSuccess: boolean;
  bulkUploadNeedAdjustment: boolean;
  bulkUploadFailedParseEmailRecipient: boolean;
  bulkUploadFailedGetWarehouse: boolean;
  bulkUploadCouldNotProcessEmail: boolean;
  requestAcceptedByQuala: boolean;
  requestRejected: boolean;
  requestCompleted: boolean;
  requestUpdated: boolean;
  requestNeedHeelApproval: boolean;
  requestHeelMoreInfoProvided: boolean;
  servicePlanNotAvailable: boolean;
  bulkUploadWrongFormat: boolean;
  outsideWorkingHours: boolean;
  exteriorWashOffer: boolean;
  exteriorWashResponse: boolean;

  constructor(data: any = {}) {
    this.businessPartnerId = data.businessPartnerId;
    this.bulkUploadSuccess = data.bulkUploadSuccess;
    this.bulkUploadNeedAdjustment = data.bulkUploadNeedAdjustment;
    this.bulkUploadFailedParseEmailRecipient =
      data.bulkUploadFailedParseEmailRecipient;
    this.bulkUploadFailedGetWarehouse = data.bulkUploadFailedGetWarehouse;
    this.bulkUploadCouldNotProcessEmail = data.bulkUploadCouldNotProcessEmail;
    this.requestAcceptedByQuala = data.requestAcceptedByQuala;
    this.requestRejected = data.requestRejected;
    this.requestCompleted = data.requestCompleted;
    this.requestUpdated = data.requestUpdated;
    this.requestNeedHeelApproval = data.requestNeedHeelApproval;
    this.requestHeelMoreInfoProvided = data.requestHeelMoreInfoProvided;
    this.servicePlanNotAvailable = data.servicePlanNotAvailable;
    this.bulkUploadWrongFormat = data.bulkUploadWrongFormat;
    this.outsideWorkingHours = data.outsideWorkingHours;
    this.exteriorWashOffer = data.exteriorWashOffer;
    this.exteriorWashResponse = data.exteriorWashResponse;
  }
}

export class UserNotificationPreferences {
  aduserId: string;
  forwardUsersIds: string[];

  constructor(data: any = {}) {
    this.aduserId = data.aduserId;
    this.forwardUsersIds = data.forwardUsersIds;
  }
}

export interface GenericUser {
  id: string;
  name: string;
  email: string;
  emailUpper: string;
  username: string;
  active: string;
  businessPartnerId?: string;
  businessPartnerName?: string;
}

export interface ShareUserNotification {
  sharingWith?: GenericUser[];
  allUsers?: GenericUser[];
}
export class Dispatcher implements GenericUser {
  id: string;
  name: string;
  email: string;
  emailUpper: string;
  username: string;
  active: string;
  businessPartnerId: string;
  businessPartnerName: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.emailUpper = data.emailUpper;
    this.username = data.username;
    this.active = data.active;
    this.businessPartnerId = data.businessPartnerId;
    this.businessPartnerName = data.businessPartnerName;
  }
}

export class QualaUser implements GenericUser {
  id: string;
  name: string;
  email: string;
  emailUpper: string;
  username: string;
  active: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.emailUpper = data.emailUpper;
    this.username = data.username;
    this.active = data.active;
  }
}
