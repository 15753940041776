export const popUpDisclaimers = {
  accept:
    'Be aware that the request will be automatically converted to order on OpenBravo after being accepted.',
  submit:
    'After being accepted by the dispatcher the Request will be automatically converted to order in OpenBravo.',
  servicePlan:
    'There is no Selected Service Plan for this request.\n' +
    'Please edit it and review the following information: Container, Product Name, and Service Plan.',
  needByTimeBeforeArrivalTime:
    'Please make sure that the Need By Time is greater than the Arrival Time.',
  missingSDS:
    'Missing SDS File.\n' +
    'Request will not be able to be processed until a SDS file has been sent.',
};

export const popUpHeaders = {
  accept: 'Accepting Request',
  submit: 'Submit for Customer Approval',
  edit: 'Editing Request',
};

export const popUpLabels = {
  accept: 'Accept with automated WO Conversion',
  acceptWithoutOrderConversion: 'Accept without WO creation',
  submit: 'Submit',
  edit: 'Save',
};
