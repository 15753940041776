import { Injectable } from '@angular/core';
import { unix } from 'moment';
import { cloneDeep } from 'lodash';
import { Shift, Bay, Card, timeIntervalInMinutes } from '../schedule.model';
import {
  Agenda,
  AgendaShift,
  AgendaBay,
  Slot,
  SlotStatuses,
} from './agenda.model';
import { ScheduleService } from '../schedule.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AgendaService {
  private allCardTypes = ['default', 'progress', 'walkup', 'downtime'];

  constructor(private _scheduleService: ScheduleService) {}

  /**
   * Calculate the total available time for each shift considering sequential shifts
   * So if there are two sequential shifts, the total available time for the first shift will be the sum of the
   * available time for the first shift and the available time for the second shift
   * @param shifts shifts to calculate the total available time for
   * @returns a lits of shifts with the total available time property populated
   */
  private getAggregatedShiftsTimeBag(shifts: Shift[]): Shift[] {
    // Sum the available time of each shift
    const totalAvailableTime = shifts.reduce(
      (acc, item) => acc + item.availableTimesPerBay,
      0
    );
    const shiftsWithTimeBag: {
      totalAvailableTime: number;
      shifts: Shift[];
    } = {
      totalAvailableTime,
      shifts: [],
    };

    // Distribute the available time to each sequential shift
    const result = shifts
      .sort(this.sortBy('startTime'))
      .reduce((acc, shift) => {
        acc.shifts.push({
          ...shift,
          availableTimesPerBay: acc.totalAvailableTime,
        });
        acc.totalAvailableTime -= shift.availableTimesPerBay;

        return acc;
      }, shiftsWithTimeBag);

    return result.shifts;
  }

  /**
   * Calculate the available time per bay in a shift sequence
   * @param shifts Sequential shift group
   * @param bays bays to calculate the available time for
   * @param cards cards in the shifts
   * @returns Shifts with a list of bays with the respective time bag per bay
   */
  private calculateAvailableTimePerBayInShift(
    shifts: Shift[],
    bays: Bay[],
    cards: Card[],
    firstSlotTime: number = 0
  ): Array<Shift & { bays: AgendaBay[] }> {
    const shiftBaysWithTimeBag = shifts
      .sort(this.sortBy('startTime'))
      .reduce((accShift, shift, _index, shiftsArray) => {
        const baysWithTimeBag: Partial<AgendaBay>[] = bays.map((bay) => {
          // get cards from the actual shift to the last shift of the group
          const bayCards = cards.filter((card) => card.bayId === bay.id);

          // get total time occupied by cards till the last shift of the group
          const timeOccupiedInBayTilLastShift = this.getBayOccupiedMinutes(
            shiftsArray.slice(_index),
            bay,
            bayCards,
            firstSlotTime
          );

          // set the bay time bag to the real available time
          return {
            ...bay,
            timeBag: shift.availableTimesPerBay - timeOccupiedInBayTilLastShift,
          };
        });

        accShift.push({
          ...shift,
          bays: baysWithTimeBag,
        });

        return accShift;
      }, []);

    return shiftBaysWithTimeBag as Array<Shift & { bays: AgendaBay[] }>;
  }

  /**
   * Calculates the total time per shift and the available time per bay in the shift
   * @param shifts shifts to calculate the total time for
   * @param bays bays to calculate the available time for
   * @param cards cards in the shifts
   * @param firstSlotTime first slot time
   * @returns Shifts with a list of bays with the respective time bag per bay
   */
  getShiftsWithBaysAndTimeBag(
    shifts: Shift[],
    bays: Bay[],
    cards: Card[],
    firstSlotTime: number = 0
  ) {
    // Set the available time per bay in the shift based on shift start time and finish time
    // If the fist slot time is greater than the shift start time, the first slot time will be considered as the shift start time
    const shiftWithShiftTime: Shift[] = shifts.map((shift) => ({
      ...shift,
      availableTimesPerBay: this.getMinutesDiff(
        shift.originalShiftTime.finish,
        firstSlotTime > shift.originalShiftTime.start
          ? firstSlotTime
          : shift.originalShiftTime.start
      ),
    }));

    // Group sequential shifts and calculate the effective available time per bay
    const shiftsGroupWithAvailableTime = ScheduleService.getSequentialShifts(
      shiftWithShiftTime
    )
      .map((shift) => this.getAggregatedShiftsTimeBag(shift))
      .map((shift) =>
        this.calculateAvailableTimePerBayInShift(
          shift,
          bays,
          cards,
          firstSlotTime
        )
      );

    const shiftsWithAvailableTime: Array<Shift & { bays: AgendaBay[] }> =
      [].concat.apply([], shiftsGroupWithAvailableTime);
    return shiftsWithAvailableTime;
  }

  buildAgenda(
    shifts: Shift[],
    bays: Bay[],
    cards: Card[],
    firstSlotTime: number
  ): Agenda {
    const slots = shifts.reduce((acc, item) => {
      const startingPosition = acc.length;
      const startTimeMoment = unix(item.startTime).utc();
      const isToday = startTimeMoment.isSame(Date.now(), 'day');
      // 24 hours, 60 minutes, 60 seconds, 1000 seconds = milliseconds in 1 day
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const isYesterday = startTimeMoment.isSame(
        Date.now() - oneDayInMilliseconds,
        'day'
      );
      const firstSlot = unix(firstSlotTime).utc();
      const isFirstSlotToday = firstSlot.isSame(Date.now(), 'day');
      const isFirstSlotYesterday = firstSlot.isSame(
        Date.now() - oneDayInMilliseconds,
        'day'
      );
      const startingTime =
        (isYesterday && isFirstSlotYesterday) || (isToday && isFirstSlotToday)
          ? firstSlotTime
          : item.startTime;

      const buildedSlots = this.buildSlots(
        startingTime,
        item.finishTime,
        startingPosition
      );
      acc = acc.concat(buildedSlots);
      return acc;
    }, []);

    const shiftWithTimeBag = this.getShiftsWithBaysAndTimeBag(
      shifts,
      bays,
      cards,
      firstSlotTime
    );

    const agenda: Agenda = {
      shifts: shiftWithTimeBag
        .sort(this.sortBy('startTime'))
        .map((item, _index) => {
          return this.buildAgendaShift(
            item,
            item.bays,
            cards,
            slots,
            firstSlotTime
          );
        }),
    };
    return agenda;
  }

  buildAgendaShift(
    shift: Shift,
    bays: AgendaBay[],
    cards: Card[],
    slots: Slot[],
    firstSlotTime: number
  ): AgendaShift {
    const { finishTime, startTime } = shift;
    // Get whichever is greater. First slot time or shift start time.
    const agendaStartTime = Math.max(startTime, firstSlotTime);

    // Generate date 'label' MM/DD
    const startTimeMoment = unix(startTime).utc();
    const shiftDate = startTimeMoment.format('MM/DD');

    // Filter slots for current shift
    const shiftSlots = slots
      .filter(
        (item) =>
          item.startTime >= agendaStartTime && item.startTime < finishTime
      )
      .sort(this.sortBy('startTime'));

    // Filter cards for current shift
    const shiftCards = cards.sort(this.sortBy('startTime'));

    // Build bays with generated cards and slots
    const agendaBays = bays.map((bay) =>
      // Cloning only slots because they are the only thing reusable and are being updated
      this.buildAgendaBay(
        shift,
        bay,
        shiftCards,
        cloneDeep(shiftSlots),
        bay.timeBag,
        firstSlotTime
      )
    );

    return { ...shift, dateLabel: shiftDate, bays: agendaBays };
  }

  buildAgendaBay(
    shift: Shift,
    bay: Bay,
    cards: Card[],
    slots: Slot[],
    availableTimeInBay: number,
    firstSlotTime: number
  ): AgendaBay {
    // Calculate sum of minutes of all cards occupying slots
    const agendaStartTime = Math.max(
      shift.originalShiftTime.start,
      firstSlotTime
    );
    const agendaFinishTime = this.roundMinutes(shift.originalShiftTime.finish);

    // Set first slot with total time per bay (in minutes)
    let filteredCards = [];
    let baySlots = [];

    if (slots && slots.length > 0) {
      let firstSlotInShiftIndex = slots.findIndex(
        (item) => item.startTime === agendaStartTime
      );
      const lastSlotInShiftIndex = slots.findIndex(
        (item) => item.finishTime === agendaFinishTime
      );

      if (firstSlotInShiftIndex >= 0) {
        slots[firstSlotInShiftIndex].availableTimeBag = availableTimeInBay;
      } else {
        firstSlotInShiftIndex = 0;
      }

      let firstAvailableSlot = true;
      // Update slots (available time and status) by crossing with cards
      const updatedSlots = slots.map((item, index) => {
        const fillingCard = cards
          .filter((card) => card.bayId === bay.id)
          .find(
            (card) =>
              item.startTime >= card.startTime &&
              item.finishTime <= card.finishTime
          );

        const isOutOfShift = this.isSlotOutOfShift(item.startTime, shift);

        // If there is no card linked to slot and the card is within the shift, return it.
        if (!fillingCard && !isOutOfShift) {
          // Subtract 10 minutes for each empty slot.
          // Caveat is that we need to ignore the first empty slot.
          if (index > firstSlotInShiftIndex) {
            if (firstAvailableSlot) {
              item.availableTimeBag = slots[index - 1].availableTimeBag;
            } else {
              item.availableTimeBag =
                slots[index - 1].availableTimeBag - timeIntervalInMinutes;
            }
          }

          firstAvailableSlot = false;
          return item;
        }

        if (index > firstSlotInShiftIndex && index <= lastSlotInShiftIndex) {
          // Just copy previous available time bag (if exists).
          item.availableTimeBag = slots[index - 1].availableTimeBag;
        }

        if (isOutOfShift) {
          item.status = SlotStatuses.OUT_SHIFT;
          item.availableTimeBag = 0;
          return item;
        }

        // If there is a card using the slot, verify card type
        if (fillingCard && fillingCard.cardType === 'progress') {
          item.status = SlotStatuses.BLOCKED;
        } else if (fillingCard) {
          item.status = SlotStatuses.FULL;
        }

        return item;
      });

      filteredCards = cards
        .filter(
          (item) =>
            item.startTime >= shift.startTime &&
            item.startTime < shift.finishTime
        )
        .filter((item) => item.bayId === bay.id)
        .filter((item) => this.allCardTypes.includes(item.cardType))
        .filter(
          (item) =>
            item.cardType !== 'progress' || item.startTime >= firstSlotTime
        );

      // Update slots
      baySlots = updatedSlots.map((item) => ({
        ...item,
        bayId: bay.id,
      }));
    }

    return {
      ...bay,
      shift,
      cards: filteredCards,
      slots: baySlots,
      timeBag: availableTimeInBay,
    };
  }

  buildSlots(
    startTime: number,
    finishTime: number,
    startingPosition: number
  ): Slot[] {
    const slots: Slot[] = [];
    if (finishTime < startTime) {
      return slots;
    }

    let position = startingPosition;
    let computedTime = startTime;

    while (computedTime < finishTime) {
      const computedTimeMoment = unix(computedTime).utc();
      const hour = `${computedTimeMoment.hour()}`.padStart(2, '0');
      const minutes = `${
        Math.floor(computedTimeMoment.minute() / timeIntervalInMinutes) *
        timeIntervalInMinutes
      }`.padStart(2, '0');

      const slotStartTime = computedTime;
      const slotFinishTime = computedTimeMoment
        .add(timeIntervalInMinutes, 'm')
        .unix();

      slots.push({
        position,
        previousPosition: position - 1,
        startTime: slotStartTime,
        finishTime: slotFinishTime,
        hourLabel: `${hour}:${minutes}`,
        status: SlotStatuses.EMPTY,
        bayId: '',
        availableTimeBag: 0,
      });

      computedTime = slotFinishTime;
      position += 1;
    }

    return slots;
  }

  getCardsToMove(movingCard: Card, targetSlot: Slot, targetBay: AgendaBay) {
    const blockReasonResult =
      this._scheduleService.blockReasonToCardMovementToSlot(
        targetBay,
        targetSlot,
        movingCard
      );
    // Safety verification, check again for available room to move card
    if (!!blockReasonResult.blockingReasonMessage) {
      return [];
    }

    const updatedCard: Card = {
      ...movingCard,
      bayId: targetBay.id,
      bayName: targetBay.name,
      startTime: targetSlot.startTime,
      finishTime: targetSlot.startTime + movingCard.duration * 60,
      isATwoDaysSchedule: !this.itIsSameDay(
        targetSlot.startTime,
        targetSlot.startTime + movingCard.duration * 60
      ),
    };

    updatedCard.isATwoDaysSchedule = this.isATwoDaysSchedule(updatedCard);
    const sequentialShifts = ScheduleService.getSequentialShifts(
      this._scheduleService.dataStore.shifts
    );
    const shiftGroup = sequentialShifts.find((group) =>
      group.some((shift) => shift.startTime === targetBay.shift.startTime)
    );

    const inShiftCards = this._scheduleService
      .getCardsInSequentialShifts(shiftGroup, targetBay.id)
      // Remove moving card from list. Avoid infinite loop.
      // Sort cards by startTime.
      // This way we guarantee that find operation will always return the next card.
      .filter((item) => item.id !== movingCard.id)
      .sort(this.sortBy('startTime'));

    // Find the next card that needs to be shifted
    const nextCard = inShiftCards.find(
      (item) =>
        (item.startTime >= updatedCard.startTime &&
          item.startTime < updatedCard.finishTime) ||
        (item.startTime <= updatedCard.startTime &&
          item.finishTime > updatedCard.startTime)
    );

    let shiftedCards = [];
    if (nextCard) {
      // Calculate the minutes to shift next card
      const minutesToShift =
        this.getMinutesDiff(updatedCard.finishTime, nextCard.finishTime) +
        nextCard.duration;
      shiftedCards = shiftedCards.concat(
        this.shiftCards(nextCard, inShiftCards, minutesToShift)
      );
    }

    shiftedCards.push(updatedCard);
    return shiftedCards;
  }

  private getBayOccupiedMinutes(
    shift: Shift[],
    bay: Bay,
    cards: Card[],
    firstSlotTime: number
  ): number {
    const sortedShift = shift.sort(this.sortBy('startTime'));
    const earliestShift = sortedShift[0];
    const lastShift = sortedShift[sortedShift.length - 1];

    const { start: originalStartTime } = earliestShift.originalShiftTime;
    const { finish: originalFinishTime } = lastShift.originalShiftTime;

    const agendaStartTime = Math.max(earliestShift.startTime, firstSlotTime);

    const visibleCards = cards.filter(
      (item) =>
        item.bayId === bay.id &&
        item.duration > 0 &&
        item.startTime >= agendaStartTime
    );

    const shiftTimeOccupiedByCardsOnShift = visibleCards
      .filter(
        (item) =>
          item.startTime >= originalStartTime &&
          item.finishTime <= originalFinishTime
      )
      .reduce((acc, item) => (acc += item.duration), 0);

    const cardsInAndOutOfShift = visibleCards.filter(
      (item) =>
        (item.startTime < originalStartTime &&
          item.finishTime > originalStartTime) ||
        (item.startTime < originalFinishTime &&
          item.finishTime > originalFinishTime)
    );

    const shiftTimeOccupiedByCardsOutOfShift = cardsInAndOutOfShift.reduce(
      (acc, item) => {
        const startTime = Math.max(item.startTime, originalStartTime);
        const finishTime = Math.min(item.finishTime, originalFinishTime);

        return (acc += (finishTime - startTime) / 60);
      },
      0
    );

    const shiftTimeOccupiedByCardsFromPreviousShift = cards
      .filter(
        (item) =>
          item.startTime < originalStartTime &&
          item.finishTime > originalStartTime
      )
      .reduce(
        (acc, item) => acc + (item.finishTime - originalStartTime) / 60,
        0
      );

    return (
      shiftTimeOccupiedByCardsOnShift +
      shiftTimeOccupiedByCardsOutOfShift +
      shiftTimeOccupiedByCardsFromPreviousShift
    );
  }

  private shiftCards(card: Card, cards: Card[], minutesToShift: number): any[] {
    const projectedStartTime = unix(card.startTime)
      .add(minutesToShift, 'm')
      .unix();

    const projectedFinishTime = unix(card.finishTime)
      .add(minutesToShift, 'm')
      .unix();

    // Find the next card that needs to be shifted
    const nextCard = cards.find(
      (item) =>
        item.startTime >= card.finishTime &&
        item.startTime <= projectedFinishTime
    );

    let shiftedCards = [];
    // Search for cards that might be occupying the slots ahead
    if (nextCard) {
      // Calculate the minutes to shift next card
      minutesToShift =
        this.getMinutesDiff(projectedFinishTime, nextCard.finishTime) +
        nextCard.duration;
      shiftedCards = shiftedCards.concat(
        this.shiftCards(nextCard, cards, minutesToShift)
      );
    }

    shiftedCards.push({
      ...card,
      startTime: projectedStartTime,
      finishTime: projectedFinishTime,
      isATwoDaySchedule: !this.itIsSameDay(
        projectedFinishTime,
        projectedStartTime
      ),
    });
    return shiftedCards;
  }

  private getMinutesDiff(timeInSeconds1, timeInSeconds2): number {
    return (
      Math.ceil(
        (timeInSeconds1 - timeInSeconds2) / 60 / timeIntervalInMinutes
      ) * timeIntervalInMinutes
    );
  }

  private itIsSameDay(date1: number, date2: number) {
    return moment(date1).isSame(moment(date2), 'day');
  }

  private sortBy(property: string) {
    return (a, b) => {
      if (a[property] > b[property]) {
        return 1;
      }
      if (a[property] < b[property]) {
        return -1;
      }
      return 0;
    };
  }

  private isSlotOutOfShift(slotTime: number, shift: Shift) {
    return (
      slotTime < shift.originalShiftTime.start ||
      slotTime >= shift.originalShiftTime.finish
    );
  }

  private isATwoDaysSchedule(card: Card) {
    const startDay = unix(card.startTime).utc();
    const finishDay = unix(card.finishTime).utc();
    const finishDayHours = finishDay.format('h:mm:ss');

    const firstHourOfTheDay = '12:00:00';

    const daysDifference = Math.ceil(finishDay.diff(startDay, 'days', true));

    if (startDay.isSame(finishDay, 'day')) {
      return false;
    }

    if (daysDifference === 1 && finishDayHours === firstHourOfTheDay) {
      return false;
    }

    return true;
  }

  private roundMinutes(date: number) {
    const dateObject = new Date(date * 1000);
    const tenMinutesCoefficient = 1000 * 60 * 10;
    const rounded = new Date(
      Math.round(dateObject.getTime() / tenMinutesCoefficient) *
        tenMinutesCoefficient
    );

    return rounded.getTime() / 1000;
  }
}
