import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatServiceType',
})
export class FormatServiceTypePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value.replace('and', '&');
  }
}
