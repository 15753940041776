import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { WashRequest } from '../wash-list.model';
import { WashListService } from '../wash-list.service';
import { HeelFieldsEnum } from './wash-heel-fields.enum';
import { Heel, ExceptionType, WasteType } from './wash-heel.model';
import { MenuOption } from '../../menu/menu.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wash-heel',
  templateUrl: './wash-heel.component.html',
  styleUrls: ['./wash-heel.component.scss'],
})
export class WashHeelComponent implements OnInit {
  currentMenuOption: MenuOption;
  showHeelForm: boolean;
  heelForm: UntypedFormGroup;
  exceptionType: UntypedFormControl;
  wasteType: UntypedFormControl;
  heelAmount: UntypedFormControl;
  heelCost: UntypedFormControl;
  laborHours: UntypedFormControl;
  laborCost: UntypedFormControl;
  steam: UntypedFormControl;
  steamCost: UntypedFormControl;
  isVisible: boolean;
  isProcessing: boolean;

  // Select options
  invalidFields: Array<string> = [];
  validationMessage: string;
  displayValidationMessage = false;

  exceptionTypeOptions = [
    ExceptionType.heel,
    ExceptionType.handLabor,
    ExceptionType.heelAndHandLabor,
  ];

  wasteTypeOptions = [
    WasteType.reactiveWaste,
    WasteType.hazardous,
    WasteType.nonHazardous,
  ];

  @Input() item: WashRequest;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  constructor(
    private washListService: WashListService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.isProcessing = false;
    this.showHeelForm = true;
    this.createFormControls();
    this.createFormGroup();
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isVisible = flag)
    );
  }

  createFormControls() {
    this.exceptionType = new UntypedFormControl('', Validators.required);
    this.wasteType = new UntypedFormControl('', Validators.required);
    this.heelAmount = new UntypedFormControl();
    this.heelCost = new UntypedFormControl();
    this.laborHours = new UntypedFormControl();
    this.laborCost = new UntypedFormControl();
    this.steam = new UntypedFormControl();
    this.steamCost = new UntypedFormControl();
  }

  createFormGroup() {
    this.heelForm = new UntypedFormGroup({
      exceptionType: this.exceptionType,
      wasteType: this.wasteType,
      heelAmount: this.heelAmount,
      heelCost: this.heelCost,
      laborHours: this.laborHours,
      laborCost: this.laborCost,
      steam: this.steam,
      steamCost: this.steamCost,
    });
  }

  getValidationMessage(invalidFields) {
    if (invalidFields.length === 1) {
      return `Please enter a ${invalidFields[0]}`;
    }
    const firsts = invalidFields.slice(0, invalidFields.length - 1);
    const last = invalidFields[invalidFields.length - 1];
    return `Please enter a ${firsts.join(', ')} and ${last}`;
  }

  getFormValidationErrors() {
    const invalidFields = [];
    Object.keys(this.heelForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.heelForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          invalidFields.push(HeelFieldsEnum[key]);
        });
      }
    });

    return invalidFields;
  }

  submit() {
    this.isProcessing = true;
    this.displayValidationMessage = false;
    const invalidFields = this.getFormValidationErrors();
    if (invalidFields.length > 0) {
      this.validationMessage = this.getValidationMessage(invalidFields);
      this.displayValidationMessage = true;
      this.isProcessing = false;
      return;
    }

    const heelRequest: Heel = this.heelForm.getRawValue();
    if (this.item.files) {
      heelRequest.files = this.item.files;
    }
    const washRequestId = this.item.id;
    this.washListService.requestHeelApproval(washRequestId, heelRequest).then(
      (_) => {
        this.cancel();
        const option = this.washListService.getOption();
        this.washListService.loadByOption(option).catch((error) => {
          throw error;
        });
        this.toastr.success('', 'Heel and hand labor requested for approval!');
      },
      (error) => {
        this.validationMessage = 'Error requesting heel approval.';
        this.displayValidationMessage = true;
        this.toastr.error('', 'Error requesting approval for exception!');
        this.isProcessing = false;
        throw error;
      }
    );
  }

  cancel() {
    this.closed.emit();
  }
}
