// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.etendo-icon {
  height: 1em;
  vertical-align: -0.125em;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/open-bravo-icon/etendo-icon.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,wBAAA;AACF","sourcesContent":[".etendo-icon {\n  height: 1em;\n  vertical-align: -0.125em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
