// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quala-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quala-button.action-button.square-button {
  border-radius: 5px;
  font-weight: 500;
  height: 32px;
  white-space: nowrap;
}
.quala-button.action-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

:host ::ng-deep app-ellipsis-loader .lds-ellipsis div {
  top: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/quala-button/quala-button.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAEI;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;AAAN;AAGI;EACE,mBAAA;EACA,YAAA;AADN;;AASI;EACE,oBAAA;AANN","sourcesContent":[".quala-button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &.action-button {\n    &.square-button {\n      border-radius: 5px;\n      font-weight: 500;\n      height: 32px;\n      white-space: nowrap;\n    }\n\n    &:disabled {\n      cursor: not-allowed;\n      opacity: 0.7;\n    }\n  }\n}\n\n// Loading animation\n:host ::ng-deep {\n  app-ellipsis-loader {\n    .lds-ellipsis div {\n      top: 16px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
