import { Directive, HostListener, Input } from '@angular/core';
import { FunctionRegistryService } from '../function-registry/function-registry.service';
import { SideMenuToggle } from 'src/app/dashboard/dashboard.model';

@Directive({
  selector: '[appSideMenuToggle]',
})
export class SideMenuToggleDirective {
  constructor(private functionRegistryService: FunctionRegistryService) {}
  @Input() menuState = true;

  @HostListener('click')
  onClick() {
    const toggleFunction = this.functionRegistryService.getFunction(
      SideMenuToggle.SIDE_MENU
    );
    const stateOfMenuFunction = this.functionRegistryService.getFunction(
      SideMenuToggle.SIDE_MENU_IS_VISIBLE
    );
    const stateOfSideMenu = stateOfMenuFunction();
    if (toggleFunction) {
      if (stateOfSideMenu === this.menuState) {
        toggleFunction();
      }
      this.menuState = !this.menuState;
    }
  }
}
