export class Heel {
  id: string;
  heelForm: string;
  exceptionType: string;
  wasteType: string;
  heelAmount: string;
  heelCost: string;
  laborHours: string;
  laborCost: string;
  steam: string;
  steamCost: string;
  files: Array<any>;

  constructor(json: any = {}) {
    this.heelForm = json.heelForm;
    this.exceptionType = json.exceptionType;
    this.wasteType = json.wasteType;
    this.heelAmount = json.heelAmount;
    this.heelCost = json.heelCost;
    this.laborHours = json.laborHours;
    this.laborCost = json.laborCost;
    this.steam = json.steam;
    this.steamCost = json.steamCost;
  }
}

export enum ExceptionType {
  heel = 'Heel',
  handLabor = 'Hand labor',
  heelAndHandLabor = 'Heel and hand labor',
}

export enum WasteType {
  reactiveWaste = 'Reactive waste',
  hazardous = 'Hazardous',
  nonHazardous = 'Non hazardous',
}
