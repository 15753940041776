import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { MissedNeedByTimeReportComponent } from './missed-need-by-time-report.component';

@Injectable({
  providedIn: 'root',
})
export class MissedNeedByTimeReportService {
  private dialogBehaviourSubject = new BehaviorSubject(false);
  dialogResult = this.dialogBehaviourSubject.asObservable();

  constructor(private dialog: MatDialog) {}

  openDialog(data) {
    const dialogRef = this.dialog.open(MissedNeedByTimeReportComponent, {
      width: '500px',
      panelClass: 'custom-dialog',
      ...data,
    });

    return dialogRef
      .afterClosed()
      .toPromise()
      .then((r) => {
        return r;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
