import { Component, OnInit, Input, forwardRef } from '@angular/core';
import {
  UntypedFormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  debounce,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => FilterInputComponent),
  multi: true,
};

@Component({
  selector: 'app-filter-input',
  providers: [CUSTOM_VALUE_ACCESSOR],
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
})
export class FilterInputComponent implements OnInit, ControlValueAccessor {
  @Input() id: string;

  private onChange: (_: any) => any;
  private onTouched: () => any;
  control: UntypedFormControl;

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  ngOnInit(): void {
    this.control = new UntypedFormControl(null);
    this.control.valueChanges
      .pipe(
        debounceTime(500), // 500ms of debouncing time
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.onChange(value);
      });
  }

  writeValue(value: string): void {
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.control.enable({ onlySelf: true, emitEvent: false });
    }
  }
}
