// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steam-form {
  display: grid;
  grid-auto-rows: auto;
  -moz-column-gap: 16px;
       column-gap: 16px;
  row-gap: 16px;
  grid-column: 1/3;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape) {
  .steam-form {
    display: unset;
  }
}

.row-input-group {
  display: grid;
  -moz-column-gap: 16px;
       column-gap: 16px;
  row-gap: 16px;
  grid-column: 1/3;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
}
@media (max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape) {
  .row-input-group {
    display: unset;
  }
}

.suffix-input-container {
  display: flex;
  align-items: center;
}
.suffix-input-container span {
  margin: 0px 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/wash-list/wash-create/steam-heat-data-form/steam-heat-data-form.component.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,oBAAA;EACA,qBAAA;OAAA,gBAAA;EACA,aAAA;EAEA,gBAAA;EACA,qCAAA;AAFF;ACLI;EDAJ;IAUI,cAAA;EADF;AACF;;AAIA;EACE,aAAA;EACA,qBAAA;OAAA,gBAAA;EACA,aAAA;EAEA,gBAAA;EACA,qCAAA;EACA,wBAAA;AAFF;ACnBI;EDcJ;IAUI,cAAA;EADF;AACF;;AAIA;EACE,aAAA;EACA,mBAAA;AADF;AAGE;EACE,eAAA;AADJ","sourcesContent":["@import '../../../../../mixins.scss';\n\n.steam-form {\n  display: grid;\n  grid-auto-rows: auto;\n  column-gap: 16px;\n  row-gap: 16px;\n\n  grid-column: 1/3;\n  grid-template-columns: repeat(2, 1fr);\n\n  @include breakpoint(mobile) {\n    display: unset;\n  }\n}\n\n.row-input-group {\n  display: grid;\n  column-gap: 16px;\n  row-gap: 16px;\n\n  grid-column: 1/3;\n  grid-template-columns: repeat(4, 1fr);\n  grid-template-rows: auto;\n\n  @include breakpoint(mobile) {\n    display: unset;\n  }\n}\n\n.suffix-input-container {\n  display: flex;\n  align-items: center;\n\n  span {\n    margin: 0px 5px;\n  }\n}\n","@mixin breakpoint($point) {\n  @if $point == mobile {\n    @media (max-width: 599.98px) and (orientation: portrait),\n      (max-width: 959.98px) and (orientation: landscape) {\n      @content;\n    }\n  } @else if $point == tablet {\n    @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait),\n      (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {\n      @content;\n    }\n  } @else if $point == desktop {\n    @media (min-width: 840px) and (orientation: portrait),\n      (min-width: 1280px) and (orientation: landscape) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
