import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfinedSpaceEntryComponent } from './confined-space-entry.component';
import { WashListService } from 'src/app/dashboard/wash-list/wash-list.service';
import { LoadingDialogService } from '../loading-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ConfinedSpaceEntryDialogService {
  constructor(
    private dialog: MatDialog,
    private washListService: WashListService,
    private loadingDialogService: LoadingDialogService
  ) {}

  async openDialog(currentCseData, workOrderStatus) {
    const dialogRef = this.dialog.open(ConfinedSpaceEntryComponent, {
      data: {
        operators: this.washListService.dataStore.operators,
        currentCseData,
        workOrderStatus,
      },
      position: {
        top: '25vh',
      },
      panelClass: 'confined-space-entry-dialog',
    });

    return dialogRef
      .afterClosed()
      .toPromise()
      .then((r) => {
        return r;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  openCSEDialog(washRequestId, currentCseData, workOrderStatus) {
    return this.openDialog(currentCseData, workOrderStatus).then((cseData) => {
      if (!cseData) {
        return;
      }

      this.loadingDialogService.openDialog('Saving...');

      const {
        confinedEntry,
        workPerformedBy,
        confinedEntryType,
        confinedEntryFiles,
      } = cseData;

      return this.washListService
        .cse({
          confinedEntry,
          workPerformedBy,
          confinedEntryType,
          washRequestId,
          confinedEntryFiles,
        })
        .then(() => {
          this.loadingDialogService.closeDialog();
          return cseData;
        })
        .catch((err) => {
          this.loadingDialogService.closeDialog();
          throw err;
        });
    });
  }
}
