import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ManualStartStopReasonComponent } from './manual-start-stop-reason.component';

@Injectable({
  providedIn: 'root',
})
export class ManualStartStopReasonService {
  private dialogBehaviourSubject = new BehaviorSubject(false);
  dialogResult = this.dialogBehaviourSubject.asObservable();

  constructor(private dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(ManualStartStopReasonComponent);

    return dialogRef
      .afterClosed()
      .toPromise()
      .then((r) => {
        return r;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
