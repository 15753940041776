import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => ToggleComponent),
  multi: true,
};

@Component({
  selector: 'app-toggle',
  providers: [CUSTOM_VALUE_ACCESSOR],
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit, ControlValueAccessor {
  private onChange: (_: any) => any;
  private onTouched: () => any;

  disabled: boolean;
  value?: boolean;
  warningStatus: boolean;

  @Input() initial?: boolean;
  @Input() id: string;
  @Input() leftLabel: string;
  @Input() rightLabel: string;
  @Input() className: string;
  @Input() warning: boolean;
  @Input() disabledTooltip: string;
  @Input() clickableLabels?: boolean;
  @Input() warningLabel?: string;

  @Output() toggleChanged = new EventEmitter<boolean>();

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
  }

  ngOnInit() {
    if (this.initial != null) {
      this.value = this.initial;
    }
  }

  toggle(e?: MouseEvent & { layerX: number; layerY: number }) {
    if (this.disabled) {
      return;
    }

    if (this.value == null && e) {
      if (e.layerX > (e.target as HTMLButtonElement).clientWidth / 2) {
        this.writeValue(false);
      }

      if (e.layerX < (e.target as HTMLButtonElement).clientWidth / 2) {
        this.writeValue(true);
      }
    } else {
      this.writeValue(!this.value);

      if (this.warning) {
        this.warningStatus = this.value;
      }
    }

    this.onChange(this.value);
    this.onTouched();
  }

  writeValue(value: boolean): void {
    this.value = value;
    this.toggleChanged.emit(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  leftLabelClick() {
    this.writeValue(true);
  }

  rightLabelClick() {
    this.writeValue(false);
  }
}
