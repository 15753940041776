// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-title {
  margin-top: 25px;
  border-top: solid 1px #c9c9c9;
  padding-top: 24px;
}

.review-form {
  display: grid;
  row-gap: 16px;
}
.review-form .limited-text-area {
  max-height: 300px;
  min-height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/non-conformity-report/ncr-master-form/review-form-section/review-form-section.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,6BAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;AACF;AACE;EACE,iBAAA;EACA,gBAAA;AACJ","sourcesContent":[".section-title {\n  margin-top: 25px;\n  border-top: solid 1px #c9c9c9;\n  padding-top: 24px;\n}\n\n.review-form {\n  display: grid;\n  row-gap: 16px;\n\n  .limited-text-area {\n    max-height: 300px;\n    min-height: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
