import { Component, OnInit, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import {
  NonConformityReport,
  NonConformityReportStatus,
} from '../../non-conformity-report.model';

@Component({
  selector: 'app-five-why-form-section',
  templateUrl: './five-why-form-section.component.html',
  styleUrls: ['./five-why-form-section.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class FiveWhyFormSectionComponent implements OnInit {
  fiveWhyForm: UntypedFormGroup;

  @Input() ncrInput: NonConformityReport;

  constructor(private parent: FormGroupDirective) {}

  ngOnInit(): void {
    const initialValues = this.getObjectValues();
    this.fiveWhyForm = new UntypedFormGroup({
      // Actions
      firstWhy: new UntypedFormControl(initialValues.firstWhy),
      secondWhy: new UntypedFormControl(initialValues.secondWhy),
      thirdWhy: new UntypedFormControl(initialValues.thirdWhy),
      fourthWhy: new UntypedFormControl(initialValues.fourthWhy),
      fifthWhy: new UntypedFormControl(initialValues.fifthWhy),
      rootCause: new UntypedFormControl(initialValues.rootCause),

      // Responsible
      rootCauseResponsible: new UntypedFormControl(
        initialValues.rootCauseResponsible
      ),

      // Date
      rootCauseCompletionDate: new UntypedFormControl(
        initialValues.rootCauseCompletionDate
      ),

      // General
      sameResponsible: new UntypedFormControl(initialValues.sameResponsible),
    });

    this.parent.form.addControl('fiveWhys', this.fiveWhyForm);

    const investigation = this.parent.form.controls
      .investigation as UntypedFormGroup;

    investigation.get('rootCause').valueChanges.subscribe((newValue) => {
      this.fiveWhyForm.patchValue({
        rootCause: newValue,
      });
    });

    investigation
      .get('rootCauseResponsible')
      .valueChanges.subscribe((newValue) => {
        this.fiveWhyForm.patchValue({
          rootCauseResponsible: newValue,
        });
      });

    investigation
      .get('rootCauseCompletionDate')
      .valueChanges.subscribe((newValue) => {
        this.fiveWhyForm.patchValue({
          rootCauseCompletionDate: newValue,
        });
      });

    this.disableFieldsFromEdit();
  }

  get issueDescription(): string {
    return this.parent.form.controls.issueDescription.value;
  }

  get isSameResponsible(): boolean {
    return !!this.parent.form.controls.investigation.value.sameResponsible;
  }

  private getSafe(func: () => any) {
    try {
      return func();
    } catch (e) {
      return undefined;
    }
  }

  private disableFieldsFromEdit() {
    if (this.ncrInput.status === NonConformityReportStatus.Completed) {
      Object.keys(this.fiveWhyForm.controls).forEach((key) => {
        this.fiveWhyForm.get(key).disable();
      });
    }
  }

  private getObjectValues() {
    const inputData: NonConformityReport = this.ncrInput || ({} as any);
    return {
      firstWhy: this.getSafe(() => inputData.fiveWhys.firstWhy) || '',
      secondWhy: this.getSafe(() => inputData.fiveWhys.secondWhy) || '',
      thirdWhy: this.getSafe(() => inputData.fiveWhys.thirdWhy) || '',
      fourthWhy: this.getSafe(() => inputData.fiveWhys.fourthWhy) || '',
      fifthWhy: this.getSafe(() => inputData.fiveWhys.fifthWhy) || '',

      rootCause:
        this.getSafe(() => inputData.fiveWhys.rootCause.description) || '',
      rootCauseResponsible:
        this.getSafe(() => inputData.fiveWhys.rootCause.responsible) || '',

      rootCauseCompletionDate:
        this.getSafe(() => inputData.fiveWhys.rootCause.completionDate) || '',

      sameResponsible:
        this.getSafe(
          () => inputData.investigation.responsible.sameForAllActions
        ) || true,
    };
  }
}
