import {
  filter,
  mergeMap,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
} from 'rxjs';
import { FormControl, UntypedFormControl } from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  AfterContentChecked,
} from '@angular/core';
import { WashListService } from 'src/app/dashboard/wash-list/wash-list.service';
import {
  CompartmentServiceType,
  ProductContent,
  WashRequest,
} from 'src/app/dashboard/wash-list/wash-list.model';
import { WashItemService } from 'src/app/dashboard/wash-list/wash-item/wash-item.service';

@Component({
  selector: 'app-last-contained-group',
  templateUrl: './last-contained-group.component.html',
  styleUrl: './last-contained-group.component.scss',
})
export class LastContainedGroupComponent
  implements OnInit, AfterContentChecked
{
  fieldsQty: Array<number>;
  washRevisionItems: any;
  productContent$: Observable<ProductContent[]>;
  invalidContainsNitrogenMessage: string =
    'Please inform if this compartment contains nitrogen.';
  compartmentServiceTypeOptions = [
    CompartmentServiceType.SameAsMain,
    CompartmentServiceType.DoNotClean,
    CompartmentServiceType.Clean,
    CompartmentServiceType.CleanAndPrep,
    CompartmentServiceType.Prep,
  ];

  compartmentQty$: Subscription;
  isFoodGrade$: Subscription;

  // Loading fields PC
  hasLoadedPC: Subscription;
  hasLoadedPCs$: Observable<boolean>;
  destroyPCSub$ = new Subject<boolean>();
  productsLoader: { [key: string]: unknown } = {};

  @Input() isEdit: boolean;
  @Input() item: WashRequest;
  @Input() triedSubmit: boolean;
  @Input() control: FormControl;
  @Input() isFoodGrade: UntypedFormControl;
  @Input() compartmentsQty: UntypedFormControl;

  constructor(
    private washListService: WashListService,
    private washItemService: WashItemService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.handleFields();
    const arrayFieldsQty = this.isFoodGrade.value
      ? Array.from({ length: 3 }, (_, i) => i + 1)
      : Array.from({ length: this.compartmentsQty.value }, (_, i) => i + 1);

    this.hasLoadedPCs$ = this.washListService.hasLoadedPCs;
    this.hasLoadedPC = this.hasLoadedPCs$
      .pipe(
        takeUntil(this.destroyPCSub$),
        filter(() => this.isEdit === true),
        switchMap(() => {
          if (
            Object.keys(this.productsLoader).length === arrayFieldsQty.length
          ) {
            this.destroyPCSub$.next(true);
          }

          return this.handleLoadingFields(arrayFieldsQty);
        })
      )
      .subscribe();

    this.destroyPCSub$.subscribe(() => {
      arrayFieldsQty.forEach((i: number) => {
        if (!this.productsLoader.hasOwnProperty(i)) {
          if (!this.isFoodGrade.value)
            this.control['compartment' + i].control.setValue(null);
          else this.control['foodGrade' + i].control.setValue(null);
        }
      });
      this.hasLoadedPC.unsubscribe();
    });

    this.compartmentQty$ = this.compartmentsQty.valueChanges.subscribe(() => {
      this.handleFields();
    });

    this.isFoodGrade$ = this.isFoodGrade?.valueChanges.subscribe(() => {
      this.handleFields();
    });

    this.productContent$ = this.washListService.productContents;
    if (this.isEdit) this.getWashRevisionItems();
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  fieldShouldBeRevised(field): boolean {
    if (!this.washRevisionItems || this.item[field]) {
      return false;
    }
    const revisionItem = this.findRevisionItem(field);
    return revisionItem.length > 0;
  }

  getOriginalFieldValue(field): string {
    const revisionItem = this.findRevisionItem(field);
    return revisionItem[0].original;
  }

  getWashRevisionItems() {
    this.washItemService
      .getWashRequestRevision(this.item.id)
      .then((data: any) => {
        this.washRevisionItems = data;
      })
      .catch((error) => {});
  }

  hasWarning(index: number): boolean {
    return !this.isFoodGrade.value
      ? this.control['compartment' + index].containsNitrogen?.control?.value
      : this.control['foodGrade'].containsNitrogen?.control?.value;
  }

  handleValidation(index: number) {
    return !this.isFoodGrade.value
      ? this.control['compartment' + index].containsNitrogen?.control
          ?.invalid && this.triedSubmit
        ? this.invalidContainsNitrogenMessage
        : ''
      : this.control['foodGrade'].containsNitrogen?.control?.invalid &&
          this.triedSubmit
        ? this.invalidContainsNitrogenMessage
        : '';
  }

  private handleLoadingFields(fieldQty) {
    return of(...fieldQty).pipe(
      mergeMap(async (index: number) => {
        const fieldId = this.isFoodGrade.value
          ? this.control['foodGrade' + index].control.value
          : this.control['compartment' + index].control.value;

        if (Object.keys(this.productsLoader).includes(index.toString())) return;
        const field = this.washListService.dataStore.productContents.find(
          (item) => item.id === fieldId
        );
        if (field) {
          this.productsLoader[index] = field;
        }
      })
    );
  }

  private findRevisionItem(field) {
    return this.washRevisionItems.revisionItems.filter(
      (item) => `${item.field}Id` === field
    );
  }

  private handleFields() {
    return (this.fieldsQty = this.isFoodGrade.value
      ? Array(3)
      : Array(this.compartmentsQty.value));
  }
}
