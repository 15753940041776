import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.html',
  styleUrls: ['./terms-conditions.scss'],
})
export class TermsConditionsComponent {
  static route = 'support/terms-and-conditions';
}
