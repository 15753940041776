// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-info-bar-component {
  display: flex;
  border-radius: 8px;
  font-size: 16px;
  height: 25px;
  width: 95%;
  text-align: center;
  border: 1px solid white;
  flex-direction: row;
  align-items: center;
}
.card-info-bar-component .icon:first-child {
  padding-left: 4px;
}
.card-info-bar-component .icon {
  padding-right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/schedule/card/card-info-bar/card-info-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ","sourcesContent":[".card-info-bar-component {\n  display: flex;\n  border-radius: 8px;\n  font-size: 16px;\n  height: 25px;\n  width: 95%;\n  text-align: center;\n  border: 1px solid white;\n  flex-direction: row;\n  align-items: center;\n\n  .icon:first-child {\n    padding-left: 4px;\n  }\n\n  .icon {\n    padding-right: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
