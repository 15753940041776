export const slotHeight = 40;
export const columnWidth = 192;
export const cardPaddingTop = 30;
export const timeIntervalInMinutes = 10;
export const defaultTimeSize = 3;
export const heightBetweenShifts = 30;

export enum PanelMode {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large',
  TWO_X_LARGE = 'two-extra-large',
  THREE_X_LARGE = 'three-extra-large',
}

interface IncompatibleBay {
  bayId?: string;
  failedValidations?: string[];
}

export function tankContainsNitrogen(data: any) {
  return (
    (data.nitrogen && data.foodGrade) ||
    (!data.foodGrade &&
      (data.containsNitrogenComp1 ||
        data.containsNitrogenComp2 ||
        data.containsNitrogenComp3 ||
        data.containsNitrogenComp4 ||
        data.containsNitrogenComp5))
  );
}

export function getFormattedServicePlanName(
  servicePlanName: any
): string[] | undefined {
  let formattedName;
  if (servicePlanName) {
    formattedName = servicePlanName.split(';');
  }
  return formattedName;
}

export class LinkedCard {
  id: string;
  bayId: string;
  bayName: string;
  compatibleBays?: string[];
  incompatibleBays?: IncompatibleBay[];
  containerNumber?: string;
  lastContained?: string;
  cardType: string;
  serviceType: string;
  status: string;
  duration: number;
  reason?: string;
  startTime: number;
  finishTime: number;
  needByTime: number;
  arrivalTime: number;
  trailerArrivalTime?: number;
  flagAlmostOnNeedByTime?: boolean;
  flagMissingVelocitySLA?: boolean;
  flagMissingNeedByTime?: boolean;
  flagBeforeArriving?: boolean;
  availableActionsMove?: boolean;
  availableActionsStart?: boolean;
  availableActionsStop?: boolean;
  availableActionsHold?: boolean;
  availableActionsPause?: boolean;
  workOrderCreated?: boolean;
  specialInstructions?: string;
  linkedCardId: string;
  linkType: string;
  linkedCardType: string;
  exteriorWash?: boolean;
  isHeelPreApproved: boolean;
  operatedById?: string;
  tankOwnerId?: string;
  workOrderId?: string;
  ectCustomerHasViewed?: boolean;
  containsNitrogen?: boolean;
  servicePlanName?: string[];

  constructor(json: any = {}) {
    this.id = json.id;
    this.bayId = json.bayId;
    this.bayName = json.bayName;
    this.compatibleBays = json.compatibleBays;
    this.incompatibleBays = json.incompatibleBays;
    this.containerNumber = json.containerNumber;
    this.lastContained = json.lastContained;
    this.cardType = json.cardType;
    this.serviceType = json.serviceType;
    this.status = json.status;
    this.duration = json.duration;
    this.reason = json.reason;
    this.startTime = json.startTime;
    this.finishTime = json.finishTime;
    this.needByTime = json.needByTime;
    this.arrivalTime = json.arrivalTime;
    this.trailerArrivalTime = json.trailerArrivalTime;
    this.flagAlmostOnNeedByTime = json.flagAlmostOnNeedByTime;
    this.flagMissingVelocitySLA = json.flagMissingVelocitySLA;
    this.flagMissingNeedByTime = json.flagMissingNeedByTime;
    this.flagBeforeArriving = json.flagBeforeArriving;
    this.availableActionsMove = json.availableActionsMove;
    this.availableActionsStart = json.availableActionsStart;
    this.availableActionsStop = json.availableActionsStop;
    this.availableActionsHold = json.availableActionsHold;
    this.availableActionsPause = json.availableActionsPause;
    this.workOrderCreated = json.workOrderCreated;
    this.specialInstructions = json.specialInstructions;
    this.linkedCardId = json.linkedCardId;
    this.linkType = json.linkType;
    this.linkedCardType = json.linkedCardType;
    this.exteriorWash = json.exteriorWash;
    this.isHeelPreApproved = json.isHeelPreApproved;
    this.operatedById = json.operatedById;
    this.tankOwnerId = json.tankOwnerId;
    this.workOrderId = json.workOrderId;
    this.ectCustomerHasViewed = json.ectCustomerHasViewed;
    this.containsNitrogen = tankContainsNitrogen(json);
    this.servicePlanName = getFormattedServicePlanName(json.servicePlanName);
  }
}

export class Card {
  id: string;
  bayId: string;
  bayName: string;
  compatibleBays?: string[];
  incompatibleBays?: IncompatibleBay[];
  containerNumber?: string;
  lastContained?: string;
  cardType: string;
  serviceType: string;
  status: string;
  duration: number;
  reason?: string;
  startTime: number;
  finishTime: number;
  needByTime: number;
  arrivalTime: number;
  trailerArrivalTime?: number;
  canStopAfter?: number;
  flagAlmostOnNeedByTime?: boolean;
  flagMissingVelocitySLA?: boolean;
  flagMissingNeedByTime?: boolean;
  flagBeforeArriving?: boolean;
  availableActionsMove?: boolean;
  availableActionsStart?: boolean;
  availableActionsStop?: boolean;
  availableActionsHold?: boolean;
  availableActionsPause?: boolean;
  workOrderCreated?: boolean;
  specialInstructions?: string;
  linkedCardId?: string;
  linkType?: string;
  linkedCardType?: string;
  linked?: Card;
  flareRequired?: boolean;
  exteriorWash?: boolean;
  isOutOfShift?: boolean;
  isATwoDaysSchedule?: boolean;
  isHeelPreApproved?: boolean;
  operatedById?: string;
  tankOwnerId?: string;
  stoppedAt?: number;
  createdByEtendo?: boolean;
  workOrderId?: string;
  ectCustomerHasViewed?: boolean;
  containsNitrogen?: boolean;
  servicePlanName?: string[];

  constructor(json: any = {}) {
    this.id = json.id;
    this.bayId = json.bayId;
    this.bayName = json.bayName;
    this.compatibleBays = json.compatibleBays;
    this.incompatibleBays = json.incompatibleBays;
    this.containerNumber = json.containerNumber;
    this.lastContained = json.lastContained;
    this.cardType = json.cardType;
    this.serviceType = json.serviceType;
    this.status = json.status;
    this.duration = json.duration;
    this.reason = json.reason;
    this.startTime = json.startTime;
    this.finishTime = json.finishTime;
    this.needByTime = json.needByTime;
    this.arrivalTime = json.arrivalTime;
    this.trailerArrivalTime = json.trailerArrivalTime;
    this.canStopAfter = json.canStopAfter;
    this.flagAlmostOnNeedByTime = json.flagAlmostOnNeedByTime;
    this.flagMissingVelocitySLA = json.flagMissingVelocitySLA;
    this.flagMissingNeedByTime = json.flagMissingNeedByTime;
    this.flagBeforeArriving = json.flagBeforeArriving;
    this.availableActionsMove = json.availableActionsMove;
    this.availableActionsStart = json.availableActionsStart;
    this.availableActionsStop = json.availableActionsStop;
    this.availableActionsHold = json.availableActionsHold;
    this.availableActionsPause = json.availableActionsPause;
    this.workOrderCreated = json.workOrderCreated;
    this.specialInstructions = json.specialInstructions;
    this.linkedCardId = json.linkedCardId;
    this.linkType = json.linkType;
    this.linkedCardType = json.linkedCardType;
    this.isOutOfShift = json.isOutOfShift;
    this.isATwoDaysSchedule = json.isATwoDaysSchedule;
    this.exteriorWash = json.exteriorWash;
    this.isHeelPreApproved = json.isHeelPreApproved;
    this.operatedById = json.operatedById;
    this.tankOwnerId = json.tankOwnerId;
    this.stoppedAt = json.stoppAt;
    this.createdByEtendo = json.createdByEtendo;
    this.workOrderId = json.workOrderId;
    this.ectCustomerHasViewed = json.ectCustomerHasViewed;
    this.containsNitrogen = tankContainsNitrogen(json);
    this.servicePlanName = getFormattedServicePlanName(json.servicePlanName);

    if (json.linked) {
      this.linked = new LinkedCard(json.linked);
    }
    this.flareRequired = json.flareRequired;
  }
}

export interface Bay {
  id: string;
  name: string;
  isOccupied?: boolean;
  dualType?: string;
  cardInProgress: Card;
  prioritizedBp?: string;
}

export interface Shift {
  startTime: number;
  finishTime: number;
  originalShiftTime: {
    start: number;
    finish: number;
  };
  availableTimesPerBay?: any; // { [bayId]: availableTime (timestamp in minutes) }
}

export interface Operator {
  roleId: string;
  roleName: string;
  userId: string;
  userName: string;
}

export interface ManualSchedulePayload {
  terminalId: string;
  requestId: string;
  startTime: number;
  bayId: string;
}

export enum BlockingMessagesEnum {
  OUT_OF_SHIFT = 'This card can not be moved to an out of shift slot. Use the exception scheduling instead.',
  INCOMPATIBLE = 'This bay is not compatible with the service plan for this request for the following reasons:',
  UNSUITABLE = 'There is not enough time to fit this request in this shift',
  OVERLAPPING = 'There is a request in progress in the way, you can’t schedule another card here',
  GENERIC = 'This card cannot be moved to this slot',
  EXCLUSIVE_BAY = 'This bay is not compatible with the business partner for this request',
}

export interface MissedNeedByTimeReport {
  stoppedAt: number;
  reasonCode: string;
  additionalComments?: string;
}

export interface ManualStartStopData {
  startTime: number;
  completeTime: number;
  reason?: {
    additionalComments: string;
    code: string;
  };
}
