import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    private keycloak: KeycloakService,
    private authService: AuthService,
    protected router: Router
  ) {
    super(router, keycloak);
  }

  static canDoAction(actionParam, router) {
    if (actionParam) {
      return true;
    }
    router.navigateByUrl('/');
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    await this.authService.forceDepotLocalStorageClean();
    // Force the user to log in if currently unauthenticated.
    const isAuthenticated = await this.authService.isLoggedIn();
    if (!isAuthenticated) {
      this.authService.clearUserData();
      await this.authService.login({
        redirectUri: window.location.origin + state.url,
      });
      return false;
    }

    const isUserPoolLogged = await this.authService.isUserPoolLogged();
    if (!isUserPoolLogged) {
      const isSameLoggedUser =
        await this.authService.isOnTraxLoggedUserSameAsSSOLoggedUser();
      if (!isSameLoggedUser) {
        await this.authService.unauthorizeUser();
      }
      await this.authService.refreshCredentials();
    }

    await this.authService.refreshUserInformation();

    if (!this.authService.user) {
      await this.authService.getProfile();
    }

    return true;
  }
}
