// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wash-heel-component {
  padding: 16px;
  background-color: white;
  padding: 20px 20px;
  border-radius: 12px;
}

.wash-heel-component .wash-heel-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -moz-column-gap: 24px;
       column-gap: 24px;
  grid-auto-rows: auto;
  row-gap: 20px;
}

.wash-heel-component .wash-heel-form-mobile-view {
  -moz-column-gap: 24px;
       column-gap: 24px;
  row-gap: 20px;
}

.full-line {
  grid-column: 1/3;
}

.actions {
  padding: 8px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-auto-columns: auto;
  -moz-column-gap: 8px;
       column-gap: 8px;
  background-color: black;
  border-radius: 12px;
}

.actions-mobile-view {
  padding: 10px;
  -moz-column-gap: 8px;
       column-gap: 8px;
  background-color: black;
  border-radius: 12px;
  margin: 15px -20px -18px;
}

.action-button.black-button.mobile-view {
  margin-bottom: 10px;
}

.validation-message {
  border-top: solid 1px #c9c9c9;
  padding-top: 24px;
  height: 24px;
  padding-left: 8px;
  padding-bottom: 8px;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.26px;
  color: #d65600;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/wash-list/wash-heel/wash-heel.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,qCAAA;EACA,qBAAA;OAAA,gBAAA;EACA,oBAAA;EACA,aAAA;AACF;;AAEA;EACE,qBAAA;OAAA,gBAAA;EACA,aAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,0BAAA;EACA,uBAAA;EACA,oBAAA;OAAA,eAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,oBAAA;OAAA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,wBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,6BAAA;EACA,iBAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;AACF","sourcesContent":[".wash-heel-component {\n  padding: 16px;\n  background-color: white;\n  padding: 20px 20px;\n  border-radius: 12px;\n}\n\n.wash-heel-component .wash-heel-form {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 24px;\n  grid-auto-rows: auto;\n  row-gap: 20px;\n}\n\n.wash-heel-component .wash-heel-form-mobile-view {\n  column-gap: 24px;\n  row-gap: 20px;\n}\n\n.full-line {\n  grid-column: 1 / 3;\n}\n\n.actions {\n  padding: 8px;\n  display: grid;\n  grid-auto-flow: column;\n  grid-template-columns: 1fr;\n  grid-auto-columns: auto;\n  column-gap: 8px;\n  background-color: black;\n  border-radius: 12px;\n}\n\n.actions-mobile-view {\n  padding: 10px;\n  column-gap: 8px;\n  background-color: black;\n  border-radius: 12px;\n  margin: 15px -20px -18px;\n}\n\n.action-button.black-button.mobile-view {\n  margin-bottom: 10px;\n}\n\n.validation-message {\n  border-top: solid 1px #c9c9c9;\n  padding-top: 24px;\n  height: 24px;\n  padding-left: 8px;\n  padding-bottom: 8px;\n  font-family: Muli;\n  font-size: 12px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: -0.26px;\n  color: #d65600;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
