import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlighterrors',
})
export class HighlightErrorsPipe implements PipeTransform {
  transform(value: any): any {
    if (!value || !value.includes('|')) {
      return value || '';
    }

    const splitString = value.split('|');
    let formattedString = splitString[0] + '<span class="highlighted-error">';
    for (let index = 1; index < splitString.length; index++) {
      formattedString = formattedString + ' | ' + splitString[index];
    }
    formattedString = formattedString + '</span>';
    return formattedString;
  }
}
