import { API } from '@aws-amplify/api';
import Amplify from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { environment } from 'src/environments/environment';

export class PublicAPIService {
  constructor() {
    this.configureAmplify();
  }

  private configureAmplify() {
    const amplifyConfig = {
      aws_appsync_graphqlEndpoint: environment.publicAppSyncAPI,
      aws_appsync_region: environment.region,
      aws_appsync_authenticationType: 'AWS_IAM',
      aws_appsync_apiKey: 'null',
      region: environment.region,
    };
    Amplify.configure(amplifyConfig);
  }

  async ListNcrIssueType() {
    const statement = `
      query ListNcrIssueType($nextToken: String) {
        listNcrIssueType(nextToken: $nextToken) {
          items {
            code
            displayName
            order
            possibleContaminationOrDamage
            rawName
          }
        }
      }
    `;

    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return response.data.listNcrIssueType;
  }

  async ListNcrShiftStartTimes() {
    const statement = `
      query ListNcrShiftStartTimes($nextToken: String) {
        listNcrShiftStartTimes(nextToken: $nextToken) {
          items {
            order
            displayName
          }
        }
      }
    `;

    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return response.data.listNcrShiftStartTimes;
  }

  async ListNcrSignedStatement() {
    const statement = `
      query ListNcrSignedStatement($nextToken: String) {
        listNcrSignedStatement(nextToken: $nextToken) {
          items {
            order
            displayName
          }
        }
      }
    `;

    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return response.data.listNcrSignedStatement;
  }

  async ListNcrWhoHaveBeenNotified() {
    const statement = `
      query ListNcrWhoHaveBeenNotified($nextToken: String) {
        listNcrWhoHaveBeenNotified(nextToken: $nextToken) {
          items {
            order
            displayName
          }
        }
      }
    `;

    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return response.data.listNcrWhoHaveBeenNotified;
  }

  async ListBusinessPartnerNcr(nextToken?: string) {
    const statement = `
      query ListBusinessPartnerNcrPublic($nextToken: String) {
        listBusinessPartnerNcrPublic(nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
          }
          nextToken
        }
      }
    `;

    const response = (await API.graphql(
      graphqlOperation(statement, { nextToken })
    )) as any;
    return response.data.listBusinessPartnerNcrPublic;
  }

  async GetAllWarehouses(nextToken?: string) {
    const statement = `
      query GetAllWarehousesPublic($nextToken: String) {
        getAllWarehousesPublic(nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            active
            searchKey
            terminalName
            terminalNameDisplay
            terminalNumber
            terminalNumberDisplay
            terminalLaunch
            operationalRegion
            linesOfBusiness
          }
          nextToken
        }
      }
    `;

    const response = (await API.graphql(
      graphqlOperation(statement, { nextToken })
    )) as any;
    return response.data.getAllWarehousesPublic;
  }
}
