import { Injectable } from '@angular/core';
import { APIService } from 'src/app/core/API.service';
import { Operator } from 'src/app/dashboard/schedule/schedule.model';

@Injectable({
  providedIn: 'root',
})
export class OperatorsService {
  public dataStore = {
    operators: [] as Operator[],
    recentOperatorsIds: [] as string[],
  };

  constructor(private api: APIService) {}

  async loadRecentOperators(terminal: string): Promise<string[]> {
    const data = await this.api.GetRecentOperators(terminal);
    if (!data) {
      return [];
    }

    const result = data.users || [];
    this.dataStore.recentOperatorsIds = result;
    return this.dataStore.recentOperatorsIds;
  }

  async loadOperators(
    terminal: string,
    nextToken?: string
  ): Promise<Operator[]> {
    const data = await this.api.GetOperators(terminal, nextToken);
    if (!data) {
      return;
    }

    const result = (data.items || []).map((item) => ({
      roleId: item.roleId,
      roleName: item.roleName,
      userId: item.userContactId,
      userName: item.userContactName,
    }));

    this.dataStore.operators = (
      !nextToken || !this.dataStore.operators ? [] : this.dataStore.operators
    ).concat(result);

    if (data.nextToken) {
      await this.loadOperators(terminal, data.nextToken);
    } else {
      this.dataStore.operators = this.dataStore.operators.sort(
        this.compareOperators
      );
      return this.dataStore.operators;
    }
  }

  private compareOperators(operator1, operator2): number {
    const userName1 = operator1.userName.toLowerCase();
    const userName2 = operator2.userName.toLowerCase();

    if (userName1 > userName2) {
      return 1;
    }
    if (userName2 > userName1) {
      return -1;
    }
    return 0;
  }
}
