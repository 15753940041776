export enum ServicePlanRelatedFields {
  nitrogen,
  foodGrade,
  serviceTypeId,
  warehouseId,
  serviceTypeOntraxId,
  lastContainedProductComp1Id,
  lastContainedProductComp2Id,
  lastContainedProductComp3Id,
  lastContainedProductComp4Id,
  lastContainedProductComp5Id,
  lastContainedProduct1Id,
  lastContainedProduct2Id,
  lastContainedProduct3Id,
  customerId,
  container,
  exteriorWash,
  hosesQty,
  pumpQty,
  fittingsQty,
}
