export enum HeelFieldsEnum {
  exceptionType = 'Exception Type',
  wasteType = 'Waste Type',
  heelAmount = 'Heel Amount',
  heelCost = 'Cost of Heel',
  laborHours = 'Labor Hours',
  laborCost = 'Cost of Labor',
  steam = 'Steam',
  steamCost = 'Cost of Steam',
}
