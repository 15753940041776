// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-wrapper {
  justify-items: center;
}

svg {
  text-align: center;
  width: 35px;
  height: 35px;
}

#icon-loader2 {
  animation: loader2 cubic-bezier(0.31, 0.77, 0.32, 0.76) 1s infinite;
  transform-origin: 50% 50%;
  transform: scale(0.1, 0.1);
}

@keyframes loader2 {
  0% {
    fill: #28a745;
    transform: scale(0.09, 0.09) translate(-100px, -60px);
  }

  50% {
    transform: scale(0.1, 0.1) translate(100px, -60px);
    fill: #006937;
  }

  100% {
    fill: #28a745;
    transform: scale(0.1, 0.1) translate(-100px, -60px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/discret-loader/discret-loader.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mEAAmE;EACnE,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE;IACE,aAAa;IACb,qDAAqD;EACvD;;EAEA;IACE,kDAAkD;IAClD,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mDAAmD;EACrD;AACF","sourcesContent":[".icon-wrapper {\n  justify-items: center;\n}\n\nsvg {\n  text-align: center;\n  width: 35px;\n  height: 35px;\n}\n\n#icon-loader2 {\n  animation: loader2 cubic-bezier(0.31, 0.77, 0.32, 0.76) 1s infinite;\n  transform-origin: 50% 50%;\n  transform: scale(0.1, 0.1);\n}\n\n@keyframes loader2 {\n  0% {\n    fill: #28a745;\n    transform: scale(0.09, 0.09) translate(-100px, -60px);\n  }\n\n  50% {\n    transform: scale(0.1, 0.1) translate(100px, -60px);\n    fill: #006937;\n  }\n\n  100% {\n    fill: #28a745;\n    transform: scale(0.1, 0.1) translate(-100px, -60px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
