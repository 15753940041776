import { Injectable, NgZone } from '@angular/core';
import { API } from '@aws-amplify/api';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  NonConformityReport,
  ReducedNonConformityReport,
} from './non-conformity-report.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BusinessPartner } from '../wash-list/wash-list.model';
import { ElasticsearchService } from 'src/app/core/elasticsearch.service';
import { User } from '../../core/model/user.model';
import {
  Filter,
  Query,
  SearchDefinition,
  Sort,
} from 'src/app/core/model/search-definition.model';
import { Warehouse } from 'src/app/core/model/warehouse.model';
interface DataStore {
  businessPartners: BusinessPartner[];
  nonConformityReports: ReducedNonConformityReport[];
  allManagers: User[];
}
@Injectable({
  providedIn: 'root',
})
export class NcrService {
  private defaultHeaders: any;

  public dataStore: DataStore = {
    businessPartners: [],
    nonConformityReports: [],
    allManagers: [],
  };
  constructor(
    private authService: AuthService,
    private zone: NgZone,
    private elasticsearchService: ElasticsearchService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };

    if (this.authService.user) {
      this.defaultHeaders['x-ontrax-identity'] =
        `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`;
    }
  }
  private _managers = new BehaviorSubject<User[]>(this.dataStore.allManagers);
  public managers$: Observable<User[]> = this._managers.asObservable();

  private _nonConformityReports = new BehaviorSubject<
    ReducedNonConformityReport[]
  >([]);
  readonly nonConformityReports = this._nonConformityReports.asObservable();

  removeNonConformityReportsFromDataStore(ncr: ReducedNonConformityReport) {
    const index = this.dataStore.nonConformityReports.findIndex(
      (item) => item.id === ncr.id
    );
    if (index >= 0) {
      this.dataStore.nonConformityReports.splice(index, 1);
      this._nonConformityReports.next(
        Object.assign({}, this.dataStore).nonConformityReports
      );
    }
  }

  async getNcrList(
    ncrStatus: string,
    from: number = 0,
    filter?: Filter,
    sort?: Sort
  ) {
    const businessPartnerId = this.authService.user.businessPartnerId;
    const customerMasterId = this.authService.user.customerMasterId;
    const query = [];

    if (
      this.authService.hasFullReportAccess() ||
      this.authService.hasRVPDepotRole()
    ) {
      query.push(
        new Query(
          [new SearchDefinition('status', [ncrStatus])],
          null,
          null,
          null,
          null
        )
      );
    } else if (this.authService.hasDispatcherRole() && customerMasterId) {
      query.push(
        new Query(
          [
            new SearchDefinition('status', [ncrStatus]),
            new SearchDefinition('customerMasterId', [customerMasterId]),
          ],
          null
        )
      );
    } else if (this.authService.hasDispatcherRole() && !customerMasterId) {
      query.push(
        new Query(
          [
            new SearchDefinition('status', [ncrStatus]),
            new SearchDefinition('businessPartnerId', [businessPartnerId]),
          ],
          null
        )
      );
    } else if (this.authService.hasQualaWorkerRole()) {
      const userId = this.authService.user.id;

      query.push(
        new Query(
          [
            new SearchDefinition('status', [ncrStatus]),
            new SearchDefinition('assignToId', [userId]),
          ],
          null
        )
      );
    }
    this.zone.run(async () => {
      const data = await this.elasticsearchService.searchNcr(
        query,
        from,
        filter,
        sort
      );

      if (from === 0) {
        this.dataStore.nonConformityReports = [];
      }
      this.dataStore.nonConformityReports =
        this.dataStore.nonConformityReports.concat(
          data.list.map((item) => new ReducedNonConformityReport(item))
        );
      this._nonConformityReports.next(
        Object.assign({}, this.dataStore).nonConformityReports
      );
    });
  }

  getNCRById(ncrId: string) {
    const path = `/${ncrId}`;
    const httpOptions = {
      headers: this.defaultHeaders,
    };
    return API.get('NonConformityReportAPI', path, httpOptions);
  }

  approveNCR(
    ncr: ReducedNonConformityReport,
    resolutionToBeDetermined: boolean
  ) {
    const path = `/approve`;
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        ncr,
        username: this.authService.user.username,
        resolutionToBeDetermined,
      },
    };
    return from(API.put('NonConformityReportAPI', path, httpOptions)).pipe(
      tap({
        next: (data: any) => {
          this.removeNonConformityReportsFromDataStore(
            data as ReducedNonConformityReport
          );
        },
      })
    );
  }

  cancelNCR(ncrId: string, cancelReason: string) {
    const path = `/cancel`;
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        ncrId,
        cancelReason,
        username: this.authService.user.username,
      },
    };
    return from(API.put('NonConformityReportAPI', path, httpOptions)).pipe(
      tap({
        next: (data: any) => {
          this.removeNonConformityReportsFromDataStore(
            data as ReducedNonConformityReport
          );
        },
      })
    );
  }

  updateNcr(ncr: any, username: string): Promise<any> {
    const path = `/update`;
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        ncr,
        username,
      },
    };
    return API.put('NonConformityReportAPI', path, httpOptions);
  }

  createNCR(ncr: NonConformityReport): Promise<NonConformityReport> {
    const path = `/creation`;

    const httpOptions = {
      headers: this.defaultHeaders,
      body: ncr,
    };
    return API.post('NonConformityReportAPI', path, httpOptions);
  }

  async createExternalNCR(nonConformityReport: NonConformityReport) {
    const path = '/creation';
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      body: nonConformityReport,
    };
    return API.post('NonConformityReportAPI', path, httpOptions);
  }

  getInformationFromWorkOrder(workOrder: string) {
    const path = `/work-order/${workOrder}`;
    const httpOptions = {
      headers: this.defaultHeaders,
    };
    return API.get('OnTraxAPI', path, httpOptions);
  }

  async getUsersFromWarehouse(terminalNumber: string) {
    const path = '/quala-managers';
    const httpOptions = {
      headers: this.defaultHeaders,
      queryStringParameters: {
        terminalNumber,
      },
    };

    const usersData = (await API.get(
      'OnTraxAPI',
      path,
      httpOptions
    )) as Array<any>;
    return usersData.map((json) => new User(json));
  }

  assignNCR(ncr: ReducedNonConformityReport) {
    const path = `/assign`;
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        ncr,
      },
    };
    return from(API.put('NonConformityReportAPI', path, httpOptions)).pipe(
      tap({
        next: (data: any) => {
          this.removeNonConformityReportsFromDataStore(
            data as ReducedNonConformityReport
          );
        },
      })
    );
  }

  async fetchAllManagers() {
    const path = '/quala-all-managers';
    const httpOptions = {
      headers: this.defaultHeaders,
    };

    const usersData = (await API.get(
      'OnTraxAPI',
      path,
      httpOptions
    )) as Array<User>;
    const users = usersData.map((json) => new User(json));
    this._managers.next(users);
    this.dataStore.allManagers = users;
  }

  isWashLineOfBusiness(array: string[]): boolean {
    if (!array) {
      return false;
    }
    return array.some((item) => item === 'WASH');
  }

  buildWarehouseData(warehouses: Warehouse[]) {
    const separator = ' - ';
    const formattedWarehouses: string[] = [];
    const warehousesMap:
      | { id: string; terminalName: string; terminalNumber: string }
      | {} = {};

    warehouses.forEach((item) => {
      if (
        item.active === 'false' &&
        this.isWashLineOfBusiness(item.linesOfBusiness)
      ) {
        return;
      }

      const terminalName = item.terminalName.replace(/^[^a-zA-Z]+/, '').trim();

      const formattedNameParts = [
        item.terminalNumberDisplay,
        item.terminalNameDisplay,
      ];
      const formattedNames: string[] = item.linesOfBusiness?.length
        ? item.linesOfBusiness.map((lineOfBusiness) =>
            [...formattedNameParts, lineOfBusiness].join(separator)
          )
        : [formattedNameParts.join(separator)];

      formattedNames.forEach((formattedName) => {
        formattedWarehouses.push(formattedName);
        warehousesMap[formattedName] = {
          id: item.id,
          terminalName,
          terminalNumber: item.terminalNumber,
          linesOfBusiness: item.linesOfBusiness,
          terminalNumberDisplay: item.terminalNumberDisplay,
          terminalNameDisplay: item.terminalNameDisplay,
        };
      });
    });

    formattedWarehouses.sort();

    return {
      formattedWarehouses,
      warehousesMap,
    };
  }

  submitNCR(ncrId: string) {
    const path = `/submit`;
    const httpOptions = {
      headers: this.defaultHeaders,
      body: {
        ncrId,
        userName: this.authService.user.username,
      },
    };
    return from(API.put('NonConformityReportAPI', path, httpOptions)).pipe(
      tap({
        next: (data: any) => {
          this.removeNonConformityReportsFromDataStore(
            data as ReducedNonConformityReport
          );
        },
      })
    );
  }
}
