import {
  acceptedExteriorWashOffer,
  declinedExteriorWashOffer,
  expiredExteriorWashOffer,
  receivedExteriorWashOffer,
  invalidExteriorWashOffer,
  IResponseExteriorWashOffer,
} from './exterior-wash-offer-response.model';
import { ExteriorWashOfferResponseService } from './exterior-wash-offer-response.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { AuthService } from '../core/auth/auth.service';

@Component({
  selector: 'app-exterior-wash-offer-response',
  templateUrl: './exterior-wash-offer-response.component.html',
  styleUrls: ['./exterior-wash-offer-response.component.scss'],
})
export class ExteriorWashOfferResponseComponent implements OnInit {
  responseConfig: IResponseExteriorWashOffer;

  constructor(
    private route: ActivatedRoute,
    private exteriorWashOfferService: ExteriorWashOfferResponseService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.clearUserData();
    combineLatest([this.route.data, this.route.queryParams]).subscribe(
      ([dataParameters, queryParams]) => {
        if (!dataParameters || !queryParams) {
          return;
        }

        const { offerResponseStatus } = dataParameters;
        const { offerToken } = queryParams;

        this.exteriorWashOfferService
          .exteriorWashResponse(offerToken, offerResponseStatus)
          .then((_) => {
            offerResponseStatus === 'ACCEPTED'
              ? this.setElementToPage(acceptedExteriorWashOffer)
              : this.setElementToPage(declinedExteriorWashOffer);
          })
          .catch((error) => {
            const response = error.response;

            if (response.status === 498) {
              this.setElementToPage(expiredExteriorWashOffer);
              return;
            }
            if (response.status === 406) {
              this.setElementToPage(receivedExteriorWashOffer);
              return;
            }

            this.setElementToPage(invalidExteriorWashOffer);
          });
      }
    );
  }

  setElementToPage(response: IResponseExteriorWashOffer) {
    this.responseConfig = response;
  }
}
