import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataStore } from './dashboard.model';

@Injectable()
export class DashboardService {
  private _breakpointFlag = new BehaviorSubject(true);
  currentBreakpoint = this._breakpointFlag.asObservable();
  shouldDisplaySchneiderCompletionOnMenu: boolean = false;

  constructor() {}

  public dataStore: DataStore = {
    businessPartners: [],
    businessPartnersNCR: [],
  };

  changeBreakpointFlag(flag: boolean) {
    this._breakpointFlag.next(flag);
  }
}
