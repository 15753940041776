// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.revision-items-component {
  border-bottom: solid 1px #c9c9c9;
  padding-bottom: 20px;
  position: relative;
}
.revision-items-component > .title {
  font-size: 8px;
  margin-bottom: 10px;
}
.revision-items-component > .items-container > .item {
  display: inline-block;
  min-width: 30%;
  margin-top: 15px;
  font-size: 14px;
}
.revision-items-component > .items-container > .item .value {
  font-weight: bold;
}
.revision-items-component p {
  margin: 0;
}

@media screen and (max-width: 600px) {
  .revision-items-component > .items-container > .item {
    display: inline-block;
    min-width: 50%;
  }
}
.revision-items-component {
  background-color: rgba(255, 255, 0, 0.4588235294);
  padding: 10px;
  border-radius: 16px;
  width: 35%;
  display: inline-block;
}

.subtitle {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/wash-list/wash-revision/wash-revision.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,oBAAA;EACA,kBAAA;AACF;AACE;EACE,cAAA;EACA,mBAAA;AACJ;AAGI;EACE,qBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AADN;AAGM;EACE,iBAAA;AADR;AAME;EACE,SAAA;AAJJ;;AAQA;EAGM;IACE,qBAAA;IACA,cAAA;EAPN;AACF;AAYA;EACE,iDAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,qBAAA;AAVF;;AAaA;EACE,eAAA;AAVF","sourcesContent":[".revision-items-component {\n  border-bottom: solid 1px #c9c9c9;\n  padding-bottom: 20px;\n  position: relative;\n\n  > .title {\n    font-size: 8px;\n    margin-bottom: 10px;\n  }\n\n  > .items-container {\n    > .item {\n      display: inline-block;\n      min-width: 30%;\n      margin-top: 15px;\n      font-size: 14px;\n\n      .value {\n        font-weight: bold;\n      }\n    }\n  }\n\n  p {\n    margin: 0;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .revision-items-component {\n    > .items-container {\n      > .item {\n        display: inline-block;\n        min-width: 50%;\n      }\n    }\n  }\n}\n\n.revision-items-component {\n  background-color: #ffff0075;\n  padding: 10px;\n  border-radius: 16px;\n  width: 35%;\n  display: inline-block;\n}\n\n.subtitle {\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
