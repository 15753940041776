import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { InViewportAction } from 'ng-in-viewport';

import { AuthService } from 'src/app/core/auth/auth.service';
import { NewsItem, NewsItemView } from './news-item.model';
import { AuthorNameByNewsType, ItemClassByNewsType } from './news-item.enum';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss'],
})
export class NewsItemComponent implements OnInit {
  @Input() newsItem!: NewsItem;

  expanded: boolean = false;
  wasViewCounted = false;

  itemTypeClass = '';
  itemAuthor = '';

  public readonly threshold: ReadonlyArray<number> = Array.from(
    { length: 11 },
    (_, i) => (i + 45) / 100
  );

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.newsItem.newsType = this.newsItem.newsType || 'Default';
    this.itemTypeClass = ItemClassByNewsType[this.newsItem.newsType];
    this.itemAuthor = AuthorNameByNewsType[this.newsItem.newsType];
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  public onIntersection({ visible }: InViewportAction): void {
    const shouldInsertView =
      visible && this.newsItem.maxViewsPerUser && !this.wasViewCounted;

    if (shouldInsertView) {
      const userNewsItemsViews =
        localStorage.getItem('user_news_items_views') || '[]';
      const parsedUserNewsItemsViews: Array<NewsItemView> =
        JSON.parse(userNewsItemsViews);

      parsedUserNewsItemsViews.push({
        newsItemId: this.newsItem.id,
        username: this.authService.getUser().username!,
        timeOfView: Date.now(),
      });

      localStorage.setItem(
        'user_news_items_views',
        JSON.stringify(parsedUserNewsItemsViews)
      );

      this.wasViewCounted = true;
    }
  }
}
