import { Component, Input, OnInit } from '@angular/core';
import { MenuOption, OptionConfig, SectionConfig } from '../../menu.model';
import { CommonModule } from '@angular/common';
import { MenuService } from '../../menu.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { WashListComponent } from 'src/app/dashboard/wash-list/wash-list.component';

@Component({
  selector: 'app-mobile-sub-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mobile-sub-menu.component.html',
  styleUrl: './mobile-sub-menu.component.scss',
})
export class MobileSubMenuComponent implements OnInit {
  @Input() menuConfig: SectionConfig | OptionConfig;
  @Input() parentMenuPath: string;
  isSelected: boolean;

  constructor(
    private menuService: MenuService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isSelected = this.menuConfig.value.includes(
      this.menuService.getSelectedMenu()
    );
    this.router.events.subscribe(() => {
      this.isSelected = this.menuConfig.value.includes(
        this.menuService.getSelectedMenu()
      );
    });
  }

  select() {
    if (!this.parentMenuPath || !this.menuConfig) {
      return;
    }
    const dashboardPrefix = 'dashboard';
    switch (this.parentMenuPath) {
      case MenuOption.Ncr:
        this.router.navigate([
          `/dashboard/${this.parentMenuPath}/list`,
          this.menuConfig.value,
        ]);
        break;

      case WashListComponent.route:
      case MenuOption.OtherTools:
      case MenuOption.BulkUpload:
        this.router.navigate([
          `/${dashboardPrefix}/${this.parentMenuPath}`,
          this.menuConfig.value,
        ]);
        break;

      case MenuOption.Home:
      case MenuOption.Schedule:
        this.router.navigate([`/${dashboardPrefix}/${this.parentMenuPath}`]);
        break;

      case MenuOption.Settings:
      case 'reports':
      case MenuOption.ShiftLogs:
        this.router.navigate([`/${dashboardPrefix}/${this.menuConfig.value}`]);
        break;

      case MenuOption.Support:
        if (this.menuConfig.value === MenuOption.Wiki) {
          window.open(environment.wikiUrl);
        }
        if (this.menuConfig.value === MenuOption.Video) {
          window.open(environment.trainingVideoUrl);
        } else if (this.menuConfig.value === MenuOption.Terms) {
          this.router.navigate([`/${dashboardPrefix}/${MenuOption.Terms}`]);
        }
        break;
    }
  }
}
