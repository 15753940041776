export interface ActionsControl {
  canBeAccepted?: boolean;
  canBeCanceled?: boolean;
  canRemoveHold?: boolean;
  canBeRejected?: boolean;
  canBeSubmitted?: boolean;
  canBeEdited?: boolean;
  canBeReviewed?: boolean;
  canBeInPersonApproval?: boolean;
  canMarkAsApproved?: boolean;
  canProvideMoreInfo?: boolean;
  canRequestHeelApproval?: boolean;
  canSendToWashList?: boolean;
  canApproveHeel?: boolean;
  canDenyHeel?: boolean;
  canRequestInfo?: boolean;
  canRetrySubmit?: boolean;
  actionMessage?: string;
  showContainerArrived?: boolean;
  showCompletedTime?: boolean;
  showSpecialPrep?: boolean;
  hideActionBar?: boolean;
  canBeRejectedDueToHeel?: boolean;
}

export enum LastContainedFields {
  lastContainedProductComp1 = 'Last Contained - Compartment 1',
  lastContainedProductComp2 = 'Last Contained - Compartment 2',
  lastContainedProductComp3 = 'Last Contained - Compartment 3',
  lastContainedProductComp4 = 'Last Contained - Compartment 4',
  lastContainedProductComp5 = 'Last Contained - Compartment 5',
  lastContainedProduct1 = 'Last Contained Product 1',
  lastContainedProduct2 = 'Last Contained Product 2',
  lastContainedProduct3 = 'Last Contained Product 3',
}

export enum StandardServicePlanNames {
  createdByDispatcher = 'Quala will input this information later',
  createdByQuala = 'Standard 90 Min Service Plan',
  servicePlanNotFound = 'Unable to get a Service Plan',
  draftCreatedByDispatcher = 'Unable to generate a Sales Order, try again later.',
}

export enum StandardServicePlanDuration {
  default = 90,
}

export enum PopUpActionType {
  justAccept = 'Accept',
  accept = 'Accept with automated WO Conversion',
  acceptWithoutOrderConversion = 'Accept without WO creation',
  submit = 'Submit',
  save = 'Save',
}

export enum ErrorMessages {
  work_order_closed_or_cancelled = "Work Order is closed or cancelled and can't be edited.",
}
