import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import * as JsonToggles from './toggles.json';

@Directive({
  selector: '[appFeatureToggle]',
})
export class FeatureToggleDirective implements OnInit {
  toggles: any;

  @Input('appFeatureToggle') featureFlag: string;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {
    // tslint:disable-next-line: no-string-literal
    this.toggles = JsonToggles['default'];
  }

  ngOnInit() {
    if (this.toggles[this.featureFlag]) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
