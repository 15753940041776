import { Component, OnInit, Inject } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { CONTAINER_DATA } from './tokens';
import { WashDetailsOverlayService } from './wash-details-overlay.service';
import { ScheduleService } from '../schedule.service';
import {
  WashRequestDetail,
  WashRequestDetailInput,
} from './wash-details-overlay.model';
import { DialogConfirmationService } from 'src/app/shared/dialog-confirmation/dialog-confirmation.service';
import { WashItemService } from '../../wash-list/wash-item/wash-item.service';
import { MatDialog } from '@angular/material/dialog';
import { WashConvertComponent } from '../wash-convert-component/wash-convert.component';
import { WashListService } from '../../wash-list/wash-list.service';
import { WashRequest } from '../../wash-list/wash-list.model';
import { AuthService } from '../../../core/auth/auth.service';
import { ManualScheduleOverlayComponent } from '../manual-schedule-overlay/manual-schedule-overlay.component';
import { Bay, Card, Shift } from '../schedule.model';
import { DialogType } from 'src/app/shared/dialog-confirmation/dialog-confirmation.model';

@Component({
  selector: 'app-wash-details-overlay',
  templateUrl: './wash-details-overlay.component.html',
  styleUrls: ['./wash-details-overlay.component.scss'],
})
export class WashDetailsOverlayComponent implements OnInit {
  item = new WashRequestDetail();
  washRequest: any;
  heelDetails: any;
  card: any;
  isBayOccupied: boolean;

  getAttributeValue(value: string) {
    if (value) {
      return value;
    } else if (value === '') {
      return '';
    }
    return 'LOADING';
  }

  constructor(
    @Inject(CONTAINER_DATA) public data: WashRequestDetailInput,
    private washDetailsOverlayService: WashDetailsOverlayService,
    public overlayRef: OverlayRef,
    private scheduleService: ScheduleService,
    private washItemService: WashItemService,
    private dialogConfirmationService: DialogConfirmationService,
    private dialog: MatDialog,
    private washListService: WashListService,
    private authService: AuthService
  ) {}

  getCustomerName(customer: string, isCod: boolean): string {
    if (!customer) {
      return '';
    }

    let customerName = customer;

    if (isCod) {
      customerName = `COD - ${customerName}`;
    }

    return customerName.length > 30
      ? customerName.slice(0, 30) + '...'
      : customerName;
  }

  close() {
    this.overlayRef.detach(); // Close overlay from the component itself
  }

  showCompartment(compartmentNumber) {
    return compartmentNumber <= this.item.compartmentsQty;
  }

  async loadData() {
    this.card = this.data.card;
    this.isBayOccupied = this.data.isBayOccupied;
    // Attributes from the Scheduled Requests
    this.item.id = this.card.id;
    this.item.containerNumber = this.card.containerNumber;
    this.item.serviceType = this.card.serviceType;
    this.item.finishTime = this.card.finishType;
    this.item.workOrderId = this.card.workOrderId;

    // Attributes from Wash Request
    this.washRequest = new WashRequest(
      await this.washDetailsOverlayService.getWashRequestByID(this.card.id)
    );

    this.item.createdBy = this.washRequest.createdBy;
    this.item.needByTime = this.washRequest.needByTime || '';
    this.item.containerType = this.washRequest.containerType || '';
    this.item.foodGrade = this.washRequest.foodGrade;
    this.item.customerId = this.washRequest.customerId;
    this.item.customerName = this.getCustomerName(
      this.washRequest.customer,
      this.washRequest.isCod
    );
    this.item.kosher = this.washRequest.kosher;
    this.item.specialInstructions = this.washRequest.specialInstructions || '-';
    this.item.compartmentsQty = this.washRequest.compartmentsQty || '';
    this.item.lastContainedProduct1Name =
      this.washRequest.lastContainedProduct1Name || '-';
    this.item.lastContainedProduct2Name =
      this.washRequest.lastContainedProduct2Name || '-';
    this.item.lastContainedProduct3Name =
      this.washRequest.lastContainedProduct3Name || '-';
    this.item.nitrogen = this.washRequest.nitrogen;
    this.item.lastContainedProductComp1Name =
      this.washRequest.lastContainedProductComp1Name || '-';
    this.item.containsNitrogenComp1 = this.washRequest.containsNitrogenComp1;
    this.item.serviceTypeComp1 = this.washRequest.serviceTypeComp1 || '';
    this.item.specialInstructionsComp1 =
      this.washRequest.specialInstructionsComp1 || '-';
    this.item.lastContainedProductComp2Name =
      this.washRequest.lastContainedProductComp2Name || '-';
    this.item.containsNitrogenComp2 = this.washRequest.containsNitrogenComp2;
    this.item.serviceTypeComp2 = this.washRequest.serviceTypeComp2 || '';
    this.item.specialInstructionsComp2 =
      this.washRequest.specialInstructionsComp2 || '-';
    this.item.lastContainedProductComp3Name =
      this.washRequest.lastContainedProductComp3Name || '-';
    this.item.containsNitrogenComp3 = this.washRequest.containsNitrogenComp3;
    this.item.serviceTypeComp3 = this.washRequest.serviceTypeComp3 || '';
    this.item.specialInstructionsComp3 =
      this.washRequest.specialInstructionsComp3 || '-';
    this.item.lastContainedProductComp4Name =
      this.washRequest.lastContainedProductComp4Name || '-';
    this.item.containsNitrogenComp4 = this.washRequest.containsNitrogenComp4;
    this.item.serviceTypeComp4 = this.washRequest.serviceTypeComp4 || '';
    this.item.specialInstructionsComp4 =
      this.washRequest.specialInstructionsComp4 || '-';
    this.item.lastContainedProductComp5Name =
      this.washRequest.lastContainedProductComp5Name || '-';
    this.item.containsNitrogenComp5 = this.washRequest.containsNitrogenComp5;
    this.item.serviceTypeComp5 = this.washRequest.serviceTypeComp5 || '';
    this.item.specialInstructionsComp5 =
      this.washRequest.specialInstructionsComp5 || '-';
    this.item.tractorNumber = this.washRequest.tractorNumber || '-';
    this.item.pumpQty = this.washRequest.pumpQty;
    this.item.fittingsQty = this.washRequest.fittingsQty || '-';
    this.item.hosesQty = this.washRequest.hosesQty || '-';
    this.item.ladingBill = this.washRequest.ladingBill || '-';
    this.item.poNumber = this.washRequest.poNumber || '';
    this.item.poNumberForPrep = this.washRequest.poNumberForPrep || '';
    this.item.phoneNumber = this.washRequest.phoneNumber || '-';
    this.item.washBillToTerminal = this.washRequest.washBillToTerminal || '-';
    this.item.exceptionType = this.washRequest.exceptionType || '-';
    this.item.wasteType = this.washRequest.wasteType || '-';
    this.item.heelAmount = this.washRequest.heelAmount || '-';
    this.item.heelCost = this.washRequest.heelCost || '-';
    this.item.laborHours = this.washRequest.laborHours || '-';
    this.item.laborCost = this.washRequest.laborCost || '-';
    this.item.steamCost = this.washRequest.steamCost || '-';
    this.item.temperature = this.washRequest.temperature || '-';
    this.item.isHeelPreApproved = this.washRequest.isHeelPreApproved;
    this.item.isAluminumTank = this.washRequest.isAluminumTank;
    this.item.servicePlan = this.washRequest.servicePlan;
    this.item.steamHeatData = this.washRequest.steamHeatData;
    this.item.customerComments = this.washRequest.customerComments;
    this.item.flareRequired = this.washRequest.flareRequired;
    this.item.workOrder = this.washRequest.workOrder || '-';
    this.item.workOrderId = this.washRequest.workOrderId;
    this.washItemService.getHistoric(this.item.id).then((data) => {
      this.heelDetails = data;
    });
    this.item.washRequestIdOpenBravo = this.washRequest.washRequestIdOpenBravo;
  }

  closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.scheduleService.overlayCardId = null;
      this.scheduleService.overlayContainer = null;
    }
  }

  stop() {
    this.scheduleService
      .stopCard(this.item.id, this.item)
      .then((washRequest) => {
        if (
          !washRequest ||
          !washRequest.flexInspection ||
          !washRequest.flexInspection.hasFlexInspection
        ) {
          return;
        }
        this.scheduleService.showFlexInspectorFormToast(
          washRequest.flexInspection.workOrderId
        );
      });
  }

  hold() {
    let disclaimer: string;
    let type: DialogType;

    if (this.item.isHeelPreApproved) {
      disclaimer =
        'This request was submitted with the Heel Handling already approved';
      type = DialogType.withWarning;
    }

    this.showDialogConfirmation(
      'Put request on hold?',
      'hand-paper',
      'Yes',
      disclaimer,
      type
    ).then((dialogResult) => {
      if (dialogResult) {
        this.scheduleService.hold(this.item.id);
        this.closeOverlay();
      }
    });
  }

  pause() {
    this.scheduleService.pause(this.item.id);
    this.closeOverlay();
  }

  convertCard(newServiceType: string) {
    const serviceType = this.washListService.dataStore.serviceTypes.find(
      (item) => item.key === newServiceType
    );
    const data = {
      serviceTypeOld: this.data.card.serviceType,
      serviceType,
      washRequestId: this.item.id,
      originalPo: this.item.poNumber,
      businessPartnerId: this.item.customerId,
      linkType: this.data.card.linkType,
    };
    this.dialog.open(WashConvertComponent, { data });
  }

  ngOnInit() {
    this.loadData();
  }

  showDialogConfirmation(
    question,
    icon,
    action,
    text?,
    type = DialogType.default
  ) {
    return this.dialogConfirmationService.openDialog({
      title: question,
      icon,
      action,
      text,
      type,
    });
  }

  getIsHeelPreApprovedMsg() {
    return 'This request was submitted with the Heel Handling already approved.';
  }

  manualReschedule() {
    this.closeOverlay();
    this.dialog.open<
      ManualScheduleOverlayComponent,
      { card: Card; bays: Bay[]; shifts: Shift[] }
    >(ManualScheduleOverlayComponent, {
      data: {
        card: this.card,
        bays: this.scheduleService.dataStore.bays,
        shifts: this.scheduleService.dataStore.shifts,
      },
    });
  }

  get isHeelPreApproved() {
    return this.item.isHeelPreApproved ? 'Yes' : 'No';
  }

  get hasQualaRole() {
    return this.authService.hasQualaWorkerRole();
  }

  get cantStopReason() {
    return this.card.availableActionsStop
      ? 'Finish the cleaning and remove the tank from the bay'
      : 'You can only stop a request that is in progress';
  }

  get canStop() {
    return this.card.availableActionsStop;
  }

  getMessageForExternalSystem() {
    if (!this.hasQualaRole) {
      return 'This request was created in an external system by the Terminal. For more details or updates, please contact the Terminal Manager.';
    }
    return 'This request was created in Etendo and added to the Schedule. Currently, it cannot be edited in OnTrax. For updates to the request information, please proceed to Etendo.';
  }

  async openUrl() {
    this.washListService.openEtendoUrl(
      this.washRequest.washRequestIdOpenBravo,
      this.item.workOrderId,
      this.data.card.createdByEtendo
    );
  }

  getButtonLabel() {
    return this.washListService.getEtendoButtonLabel(
      this.item.workOrderId,
      this.data.card.createdByEtendo
    );
  }
}
