import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Slot, AgendaBay } from '../agenda.model';
import { BlockingReasonMessage, ScheduleService } from '../../schedule.service';

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss'],
})
export class SlotComponent implements OnInit {
  @Input() item: Slot;
  @Input() bay: AgendaBay;
  @Input() isMoveMode: boolean;
  @Input() panelMode: string;
  @Output() slotSelected = new EventEmitter<Slot>();

  isAvailable = true;
  blockingReasonMessage: BlockingReasonMessage = {
    blockingReasonMessage: null,
  };

  get showMoveMode() {
    return this.isMoveMode;
  }

  constructor(private scheduleService: ScheduleService) {}

  ngOnInit() {}

  checkAvailability() {
    if (this.showMoveMode) {
      this.blockingReasonMessage = this.scheduleService.canMoveCardTo(
        this.bay,
        this.item
      );
    }
    if (
      this.showMoveMode &&
      this.blockingReasonMessage.blockingReasonMessage === null
    ) {
      this.isAvailable = true;
      return;
    }
    this.isAvailable = false;
  }

  selectSlot() {
    if (!this.isAvailable || !this.showMoveMode) {
      return;
    }
    this.slotSelected.emit(this.item);
    this.googleAnalyticsTrigger();
  }

  async googleAnalyticsTrigger() {
    const washRequest = await this.scheduleService.getWashRequestByID(
      this.scheduleService.selectedCard.id
    );
    if (!washRequest) {
      return;
    }
    const eventLabel = 'Schedule screen click in choose';
  }
}
