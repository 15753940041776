import { Injectable } from '@angular/core';
import {
  WashRequestStatus,
  WashRequest,
  BusinessPartner,
  AllowedActions,
} from '../../wash-list/wash-list.model';
import { ActionsControl } from './wash-item.model';
import { DatePipe } from '@angular/common';
import { TimeService } from 'src/app/core/time.service';
import { MenuOption } from '../../menu/menu.model';
import { API } from '@aws-amplify/api';
import { AuthService } from 'src/app/core/auth/auth.service';

export interface POValidationData {
  businessPartner: BusinessPartner;
  poNumber: string;
  poNumberForPrep?: string;
}

@Injectable({
  providedIn: 'root',
})
export class WashItemService {
  private defaultHeaders: any;

  constructor(
    public datepipe: DatePipe,
    private timeService: TimeService,
    private authService: AuthService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-ontrax-identity': `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
    };
  }

  getHistoric(washRequestId: string) {
    const path = '/wash-request/historic';
    const httpOptions = { headers: this.defaultHeaders };
    return API.get(
      'PortalAPI',
      `${path}?washRequestId=${washRequestId}`,
      httpOptions
    );
  }

  getWashRequestRevision(washRequestId: string) {
    const path = '/wash-request/revision';
    const httpOptions = { headers: this.defaultHeaders };
    return API.get('PortalAPI', `${path}?id=${washRequestId}`, httpOptions);
  }

  getAllowedActions(
    washRequest: WashRequest,
    isQualaWorker: boolean,
    userBusinessPartner: string,
    currentMenuOption?: string
  ): ActionsControl {
    const {
      status,
      createdByRole,
      pendingHeelApproval,
      customerId,
      expectedCleaningStart,
      completeTime,
      containerPickedUp,
      needMoreInfo,
      createdByBulkUpload,
      holdRemoved,
    } = washRequest;

    const createdByQualaWorker =
      createdByRole === 'CSC' ||
      createdByRole === 'Ticket' ||
      createdByRole === 'Manager' ||
      createdByRole === 'Cleaner';

    let controls;
    // Logged User is a Quala Worker
    if (isQualaWorker) {
      controls = this.getQualaWorkerControls(
        status,
        pendingHeelApproval,
        createdByQualaWorker,
        completeTime,
        containerPickedUp,
        needMoreInfo,
        createdByBulkUpload,
        holdRemoved,
        washRequest.allowedActions
      );
    } else {
      // Current User is a Dispatcher
      controls = this.getDispatcherControls(
        status,
        pendingHeelApproval,
        userBusinessPartner,
        customerId,
        createdByQualaWorker,
        completeTime,
        containerPickedUp,
        createdByBulkUpload,
        currentMenuOption
      );
    }

    // Validate if time allows edition
    if (controls) {
      controls.canBeEdited =
        controls.canBeEdited && this.isInTimeToEdit(expectedCleaningStart);
      controls.canBeAccepted =
        controls.canBeAccepted && this.haveAllRequiredItems(washRequest);
    }

    // Verify if at least one action button can be showed
    const controlKeys = Object.keys(controls || {});
    const showActionBar =
      controlKeys.length > 0 &&
      Object.keys(controls)
        .filter((key) => key !== 'hideActionBar')
        .some((key) => controls[key]);

    controls.hideActionBar = !showActionBar;

    return controls;
  }

  isInTimeToEdit(expectedCleaningStartTime: number) {
    if (
      expectedCleaningStartTime === null ||
      expectedCleaningStartTime === undefined
    ) {
      return true;
    }
    const now = this.timeService.getNowAsUTC();
    if (now >= expectedCleaningStartTime) {
      return false;
    }

    const hours = Math.abs(expectedCleaningStartTime - now) / (60 * 60);
    return hours >= 1;
  }

  applyPOValidation(data: POValidationData) {
    const validFormatRegex = data.businessPartner.contmngPOFormat;
    const validFormatHelp = data.businessPartner.contmngPOFormathelp;

    const hasValidPO = this.applyPoRegex(data.poNumber, validFormatRegex);
    const hasValidPOForPrep = data.poNumberForPrep
      ? this.applyPoRegex(data.poNumberForPrep, validFormatRegex)
      : true;

    let message;
    if (!hasValidPO || !hasValidPOForPrep) {
      const expectedFormatMessage = validFormatHelp
        ? `The expected format is ${validFormatHelp}`
        : 'Only letters, numbers, spaces and hyphens are allowed';
      message = `${expectedFormatMessage}. If the customer will provide the PO number later, enter the word 'pending'`;
    }

    return {
      isValid: hasValidPO && hasValidPOForPrep,
      message,
    };
  }

  private applyPoRegex(poNumber, format) {
    if (!this.isValidGenericPoNumber(poNumber)) {
      return false;
    }

    if (!format) {
      return true;
    }

    if (`${poNumber}`.toUpperCase() === 'PENDING') {
      return true;
    }

    const partnerRegExp = new RegExp(format, 'g');
    return partnerRegExp.test(poNumber);
  }

  private isValidGenericPoNumber(poNumber: string) {
    const poNumberGenericFormatRules = '^[a-zA-Z0-9- ]+$';
    const genericRegExp = new RegExp(poNumberGenericFormatRules, 'g');
    return genericRegExp.test(poNumber);
  }

  private getQualaWorkerControls(
    washItemStatus: string,
    pendingHeelApproval: boolean,
    isCreatedByQualaWorker: boolean,
    completeTime: number,
    containerPickedUp: boolean,
    needMoreInfo: boolean,
    isCreatedByBulkUpload: boolean,
    holdRemoved?: boolean,
    allowedActions?: AllowedActions
  ): ActionsControl {
    switch (washItemStatus) {
      case WashRequestStatus.Draft: {
        return {
          canBeCanceled: isCreatedByQualaWorker,
          canBeSubmitted: isCreatedByQualaWorker,
          canBeEdited: isCreatedByQualaWorker || isCreatedByBulkUpload,
          canBeInPersonApproval: isCreatedByQualaWorker,
          canBeRejected: isCreatedByBulkUpload,
          canBeAccepted: isCreatedByBulkUpload,
        };
      }

      case WashRequestStatus.Submitted: {
        return {
          canBeAccepted: !isCreatedByQualaWorker || holdRemoved,
          canBeRejected: !isCreatedByQualaWorker,
          canBeEdited: true,
          canBeCanceled: isCreatedByQualaWorker,
          showSpecialPrep: true,
        };
      }

      case WashRequestStatus.Updated: {
        return {
          canBeAccepted: true,
          canBeRejected: true,
          canBeEdited: true,
        };
      }

      case WashRequestStatus.ReviewNeeded: {
        return {
          canBeCanceled: isCreatedByQualaWorker,
          canBeSubmitted: isCreatedByQualaWorker,
          canBeAccepted: !isCreatedByQualaWorker,
          canBeRejected: !isCreatedByQualaWorker,
          canBeEdited: true,
        };
      }

      case WashRequestStatus.Started: {
        return {
          canMarkAsApproved: pendingHeelApproval,
          canProvideMoreInfo: needMoreInfo,
          actionMessage: !pendingHeelApproval
            ? 'Wash Status: Entered Bay'
            : undefined,
        };
      }

      case WashRequestStatus.Completed: {
        let message;
        if (completeTime) {
          const formattedTime = this.datepipe.transform(
            this.timestampFromTerminal(completeTime),
            'MM-dd hh:mm a',
            'UTC'
          );
          message = `Done time: ${formattedTime}`;
          if (containerPickedUp) {
            message = `${message} - Container has been picked up`;
          }
        }

        return {
          canBeEdited: false,
          canMarkAsApproved: pendingHeelApproval,
          canProvideMoreInfo: needMoreInfo,
          actionMessage: completeTime ? message : undefined,
        };
      }

      case WashRequestStatus.OnHold: {
        if (allowedActions.canBeRejectedDueToHeel) {
          return {
            canRemoveHold: allowedActions.canRemoveHold,
            canBeRejected: allowedActions.canBeRejected,
            canRequestHeelApproval: allowedActions.canRequestHeelApproval,
            canProvideMoreInfo: allowedActions.canProvideMoreInfo,
            canBeRejectedDueToHeel: allowedActions.canBeRejectedDueToHeel,
          };
        }
        return {
          canRemoveHold: true,
          canBeRejected: true,
          canRequestHeelApproval: !needMoreInfo,
          canProvideMoreInfo: needMoreInfo,
        };
      }

      case WashRequestStatus.Accepted:
      case WashRequestStatus.Scheduled: {
        return {
          showContainerArrived: true,
          canBeRejected: true,
          canBeEdited: true,
        };
      }

      case WashRequestStatus.Paused: {
        return {
          actionMessage: 'Wash Status: Paused',
          canRequestHeelApproval: true,
        };
      }

      case WashRequestStatus.CreditHold: {
        return {
          canRetrySubmit: true,
          canBeCanceled: true,
        };
      }

      case WashRequestStatus.Canceled:
      case WashRequestStatus.Rejected:
      default: {
        return {};
      }
    }
  }

  timestampFromTerminal(timestamp: number) {
    const timestampInSecondUtc =
      this.timeService.getTimestampInSecondsAsUTC(timestamp);
    return timestampInSecondUtc * 1000;
  }

  private getDispatcherControls(
    washItemStatus: string,
    pendingHeelApproval: boolean,
    userBusinessPartner: string,
    createdByBusinessPartner: string,
    isCreatedByQualaWorker: boolean,
    completeTime: number,
    containerPickedUp: boolean,
    isCreatedByBulkUpload: boolean,
    currentMenuOption?: string
  ): ActionsControl {
    switch (washItemStatus) {
      case WashRequestStatus.Draft: {
        const allow =
          (!isCreatedByQualaWorker &&
            userBusinessPartner === createdByBusinessPartner) ||
          isCreatedByBulkUpload;
        return {
          canBeCanceled: allow,
          canBeEdited: allow,
          canBeSubmitted: allow && !isCreatedByBulkUpload,
          canSendToWashList: allow,
        };
      }

      case WashRequestStatus.Created: {
        const allow =
          !isCreatedByQualaWorker &&
          userBusinessPartner === createdByBusinessPartner;
        return {
          canBeCanceled: allow,
          canBeEdited: allow,
          canBeSubmitted: allow,
        };
      }

      case WashRequestStatus.Submitted: {
        if (
          !isCreatedByQualaWorker &&
          userBusinessPartner === createdByBusinessPartner
        ) {
          return {
            canBeCanceled: true,
            canBeEdited: true,
          };
        }

        if (
          isCreatedByQualaWorker &&
          userBusinessPartner === createdByBusinessPartner
        ) {
          return {
            canBeEdited: true,
            canBeRejected: true,
            canBeAccepted: true,
          };
        }
        break;
      }

      case WashRequestStatus.Updated: {
        const allow = userBusinessPartner === createdByBusinessPartner;
        return {
          canBeCanceled: allow,
          canBeEdited: allow,
        };
      }

      case WashRequestStatus.ReviewNeeded: {
        const allow =
          !isCreatedByQualaWorker &&
          userBusinessPartner === createdByBusinessPartner;
        return {
          canBeCanceled: allow,
          canBeEdited: allow,
          canBeSubmitted: allow,
        };
      }

      case WashRequestStatus.OnHold: {
        return {
          canRequestInfo: pendingHeelApproval,
          canDenyHeel: pendingHeelApproval,
          canApproveHeel: pendingHeelApproval,
        };
      }

      case WashRequestStatus.Started:
      case WashRequestStatus.Paused: {
        return {
          canRequestInfo: pendingHeelApproval,
          canDenyHeel: pendingHeelApproval,
          canApproveHeel: pendingHeelApproval,
        };
      }

      case WashRequestStatus.Completed: {
        let message;

        if (completeTime && currentMenuOption === MenuOption.Completed) {
          const formattedTime = this.datepipe.transform(
            this.timestampFromTerminal(completeTime),
            'MM-dd hh:mm a',
            'UTC'
          );
          message = `Completion Time: ${formattedTime}`;
          if (containerPickedUp) {
            message = `${message} - Container has been picked up`;
          }
        }

        return {
          actionMessage: message,
          canRequestInfo: pendingHeelApproval,
          canDenyHeel: pendingHeelApproval,
          canApproveHeel: pendingHeelApproval,
        };
      }

      case WashRequestStatus.Accepted:
      case WashRequestStatus.Scheduled: {
        const allow = userBusinessPartner === createdByBusinessPartner;
        return {
          canBeEdited: allow,
          canBeCanceled: allow && !isCreatedByQualaWorker,
        };
      }

      case WashRequestStatus.CreditHold: {
        return {
          canRetrySubmit: true,
          canBeCanceled: true,
        };
      }

      default: {
        return {};
      }
    }
  }

  haveAllRequiredItems(washRequest: WashRequest): boolean {
    return !!(
      washRequest.terminal &&
      washRequest.arrivalTime &&
      washRequest.needByTime &&
      washRequest.compartmentsQty &&
      washRequest.poNumber &&
      washRequest.washBillToTerminalId &&
      (washRequest.lastContainedProduct1Id ||
        washRequest.lastContainedProductComp1Id) &&
      washRequest.washBillToTerminal &&
      washRequest.servicePlan &&
      washRequest.recommendedServicePlan &&
      washRequest.serviceTypeOntraxId
    );
  }

  updateUserECTPreferences(
    washRequestId: string,
    userId: string,
    ectCustomerNotificationOnChange: boolean
  ) {
    const body = {
      washRequestId: washRequestId,
      userId: userId,
      notifyOnChange: ectCustomerNotificationOnChange,
    };
    const path = '/wash-request/update-ect-user-preferences';
    const httpOptions = { headers: this.defaultHeaders, body: body };
    return API.post('OnTraxAPI', path, httpOptions);
  }
}
