import { Component, OnInit, Inject, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-missed-need-by-time-report',
  templateUrl: './missed-need-by-time-report.component.html',
  styleUrls: ['./missed-need-by-time-report.component.scss'],
})
export class MissedNeedByTimeReportComponent implements OnInit {
  missedNeedByTimeForm: UntypedFormGroup;
  reasonCode: UntypedFormControl;
  additionalComments: UntypedFormControl;
  showNotesDisclaimer: boolean;

  constructor(
    private dialogRef: MatDialogRef<MissedNeedByTimeReportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public reasons: any
  ) {}

  ngOnInit(): void {
    this.createFormControls();
    this.createFormGroup();
  }

  isReasonValid(reason, comments) {
    if (reason === 'Other' && (comments === null || comments < 1)) {
      this.showNotesDisclaimer = true;
      return false;
    }
    this.showNotesDisclaimer = false;
    return true;
  }

  submitForm() {
    if (
      !this.isReasonValid(
        this.missedNeedByTimeForm.get('reasonCode').value,
        this.missedNeedByTimeForm.get('additionalComments').value
      )
    ) {
      return;
    }
    if (this.missedNeedByTimeForm.valid) {
      this.showNotesDisclaimer = false;
      const foundReason = this.reasons.find(
        (reason) =>
          reason.displayName ===
          this.missedNeedByTimeForm.get('reasonCode').value
      );
      this.dialogRef.close({
        displayName: this.missedNeedByTimeForm.get('reasonCode').value,
        additionalComments:
          this.missedNeedByTimeForm.get('additionalComments').value,
        code: foundReason.code,
      });
    }
  }

  createFormGroup() {
    this.missedNeedByTimeForm = new UntypedFormGroup({
      additionalComments: this.additionalComments,
      reasonCode: this.reasonCode,
    });
  }

  createFormControls() {
    this.additionalComments = new UntypedFormControl();
    this.reasonCode = new UntypedFormControl('', Validators.required);
  }

  isOtherReason() {
    return this.reasonCode.value && this.reasonCode.value === 'Other';
  }

  onNoClick(option) {
    this.dialogRef.close(option);
  }

  onReasonClicked(reason: string) {
    this.missedNeedByTimeForm.get('reasonCode').setValue(reason);
  }
}
